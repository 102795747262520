

















































































































































































































































































































































// import { defineComponent } from "vue";
import UserData from "@/types/UserData";
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";
import ComponentViewClash from "@/components/body/leave/dialog-view-clash.vue";
import { saveAs } from "file-saver";
const Data = Vue.extend({
  props: {
    leave: Object,
    restrict: Number,
  },
});

import ComponentRangedDates from "@/components/reusable/DateRangePicker.vue";
import errorResponse from "@/services/errorCode";

@Component({
  components: {
    RangedDates: ComponentRangedDates,
    DialogViewClash: ComponentViewClash,
  },
})
export default class ComponentReviewLeave extends Data {
  public dialog = false;
  public ready$ = false;
  public users: UserData[] = [];
  public errors: string[] = [];
  public form: {
    title: string;
    desc: string;
    comment: string;
  } = {
    desc: "",
    title: "",
    comment: "",
  };

  public leaves: {
    start_date: string;
    end_date: string;
    number_days: number;
  }[] = [];
  counter = 1;
  public emptyRules = (v) => !!v || "Required";

  clearForm() {
    this.dialog = false;
  }

  openMedia() {
    axios
      .get(this.leave.attributes.media, {
        responseType: "blob",
      })
      .then((response) => {
        let contentType = response.headers["content-type"];
        let filename = this.getFileNameFromUrl(this.leave.attributes.media);
        var file = new File([response.data], filename!, {
          type: contentType,
        });
        saveAs(file);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getFileNameFromUrl(url: string) {
    const segments = url.split("/");
    const fileName = segments.pop();
    return fileName;
  }

  private onError(error, scroll: string): void {
    this.errors = errorResponse(error, this.$store);
    const myElement: HTMLElement | null = document.getElementById(scroll);
    if (myElement != null) myElement.scrollIntoView();
  }

  mounted() {
    this.form.title = this.leave.attributes.title;
    this.form.desc = this.leave.attributes.description;
    this.form.comment = this.leave.attributes.comments;
    this.leaves = this.leave.attributes.leaves;
  }
}
