



























































































import Vue from "vue";
import Component from "vue-class-component";
import axios from "axios";
import BranchData from "../../../types/BranchData";
import CountryData from "../../../types/CountryData";
import CompanyManageDialog from "./dialog-manage.vue";
import CompanyDeleteDialog from "./dialog-delete.vue";

@Component({
  components: {
    CompanyManageDialog: CompanyManageDialog,
    CompanyDeleteDialog: CompanyDeleteDialog,
  },
})
export default class PanelBranches extends Vue {
  path = "/branches";
  panelTitle = "Branches";
  singleTitle = "Branch";
  isLoading = true;
  items: Array<BranchData> = [];
  countries: Array<CountryData> = [];
  snackbar: { show: boolean; timeout: number; text: string } = {
    show: false,
    timeout: 2000,
    text: "",
  };
  showSnackbar(text: string) {
    this.snackbar = { show: true, timeout: 2000, text: text };
  }

  public reload() {
    console.log("updated");
    this.isLoading = true;
    this.getItems();
  }

  public tileClick(item: BranchData) {
    this.$emit("triggerBranch", {
      id: item.id,
      name: item.attributes.name,
    });
  }

  getCountries() {
    axios
      .get("/countries")
      .then((response) => {
        this.countries = response.data.data;
        console.log(this.countries);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  private async getItems() {
    await axios
      .get(this.path + "?sort=name&paginate=false")
      .then((response) => {
        this.items = response.data.data;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.isLoading = false;
        console.log(this.items);
      });
  }
  private async;
  mounted() {
    this.$store.dispatch("commitBASE");

    this.getCountries();
    this.getItems();
  }
}
