import "bootstrap/dist/css/bootstrap.css";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUserCircle,
  faEllipsisV,
  faFileDownload,
  faFile,
  faFileAlt,
  faEdit,
  faEye,
  faTimesCircle,
  faCheckCircle,
  faFilter,
  faFilterCircleXmark,
  faExpand,
  faBan,
  faCalendar,
  faClock,
  faTimes,
  faCheck,
  faUser,
  faHourglass,
  faFileImport,
  faFolderTree,
  faHouseLaptop,
  faTrash,
  faDeleteLeft,
  faPlusCircle,
  faQuestionCircle,
  faImage,
  faStar,
  faExclamationTriangle,
  faCalendarDays,
  faUmbrellaBeach,
  faAngleLeft,
} from "@fortawesome/free-solid-svg-icons";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(
  faUserCircle,
  faTrash,
  faEllipsisV,
  faFileDownload,
  faFile,
  faFileAlt,
  faEdit,
  faTimesCircle,
  faCheckCircle,
  faExpand,
  faFilter,
  faFilterCircleXmark,
  faUser,
  faBan,
  faCalendar,
  faCalendarDays,
  faClock,
  faTimes,
  faCheck,
  faHourglass,
  faFileImport,
  faCalendar,
  faFolderTree,
  faHouseLaptop,
  faEye,
  faPlusCircle,
  faDeleteLeft,
  faFolderTree,
  faQuestionCircle,
  faImage,
  faStar,
  faExclamationTriangle,
  faUmbrellaBeach,
  faGoogle,
  faAngleLeft
);

Vue.component("fa-icon", FontAwesomeIcon);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

import "bootstrap/dist/js/bootstrap.js";
import vuetify from "./plugins/vuetify";
