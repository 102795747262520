
































































































































































































































































































































































































// import { defineComponent } from "vue";
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";
import { saveAs } from "file-saver";

const AddData = Vue.extend({
  props: {
    calltype: String,
    mobile: Boolean,
    mode: Object,
    filter: String,
  },
});

@Component
export default class ComponentExportSummary extends AddData {
  dialog = false;
  modalStart = false;
  modalEnd = false;
  width: number | undefined = 500;
  showTables = false;
  expanded: any[] = [];
  tables: {
    title: string;
    subtitle: string;
    showExpand: boolean;
    expanded: [];
    search;
    headers: {
      text: string;
      value: string;
      align?: string;
      sortable?: boolean;
    }[];
    items: unknown[];
  }[] = [
    {
      showExpand: false,
      expanded: [],
      title: "Title",
      subtitle: "Subtitle",
      search: "",
      headers: [
        {
          text: "Dessert (100g serving)",
          align: "start",
          sortable: false,
          value: "name",
        },
      ],
      items: [
        {
          name: "Frozen Yogurt",
        },
      ],
    },
  ];

  // private restrict = 5;
  // private userID = "";

  public errors: string[] = [];

  public type = "";

  public leave = { start_date: "", end_date: "" };

  rules = {
    required: (value) => {
      return !value || value == undefined ? "Required." : true;
    },
  };

  // clickRow(item, event) {
  //   console.log(item, event, this.expanded);

  //   if (event.isExpanded) {
  //     const indexExpanded = this.expanded.findIndex((i) => i === item);
  //     this.expanded.splice(indexExpanded, 1);
  //   } else {
  //     this.expanded.push(item);
  //   }
  // }

  // clickColumn(slotData) {
  //   console.log(slotData);

  //   const indexRow = slotData.index;
  //   const indexExpanded = this.expanded.findIndex((i) => i === slotData.item);
  //   if (indexExpanded > -1) {
  //     this.expanded.splice(indexExpanded, 1);
  //   } else {
  //     this.expanded.push(slotData.item);
  //   }
  // }
  private capitalizeWords(str: string, split: string) {
    return str
      .toLowerCase()
      .split(split)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  createHeaders(object) {
    var headers: {
      text: string;
      value: string;
      align?: string;
      sortable?: boolean;
    }[] = [];
    Object.keys(object).forEach((head) => {
      if (head != "id")
        headers.push({ text: this.capitalizeWords(head, "_"), value: head });
    });
    return headers;
  }

  setTable(data: any[], type: boolean) {
    this.showTables = true;
    this.width = window.innerWidth * 0.9;
    // console.log(data);
    this.tables = [];
    data.forEach((table, index) => {
      console.log(index, table.results.length);

      if (table.results.length > 0) {
        // console.log(table, type);
        if (type) {
          // console.log(headers);

          this.tables.push({
            title: table.title,
            subtitle: table.subtitle,
            items: table.results,
            headers: this.createHeaders(table.results[0]),
            search: "",
            showExpand: type,
            expanded: [],
          });
        } else {
          // console.log(headers);

          this.tables.push({
            title: table.title,
            subtitle: table.subtitle,
            items: table.results,
            headers: this.createHeaders(table.results[0]),
            search: "",
            showExpand: type,
            expanded: [],
          });
        }
      } else {
        this.errors.push(`${table.title} is empty`);
      }
    });
    console.log(this.tables);

    this.showTables = true;
  }
  reset() {
    this.leave = { start_date: "", end_date: "" };
    this.tables = [];
    this.showTables = false;
    this.width = 500;
  }
  exportFile(type: string, exportType: boolean) {
    const data = new FormData();
    data.append("start_date", this.leave.start_date);
    data.append("end_date", this.leave.end_date);
    data.append("export", exportType ? "1" : "0");
    if (this.tables[0]) data.append("filter", this.tables[0].search);
    console.log(data);
    axios({
      url: `export/${type}/summary`, //your url
      method: "POST",
      data: data,
      responseType: exportType ? "blob" : "json", // important
    })
      .then((response) => {
        if (exportType) {
          var file = new File([response.data], `export-${type}.csv`, {
            type: response.data.type,
          });
          this.$store.dispatch("Add_Snackbar", {
            timeout: 6000,
            text: "Successful request, check downloads",
            color: "green darken-2",
          });
          saveAs(file);
        } else {
          // console.log(response, type);
          this.setTable(response.data.data, type == "leave-requests");
          this.$store.dispatch("Add_Snackbar", {
            timeout: 6000,
            text: response.data.message,
            color: "green darken-2",
          });
          this.errors = [];
        }
      })
      .catch((error) => {
        this.$store.dispatch("Add_Snackbar", {
          timeout: 6000,
          text: error.response.data.message,
          color: "red darken-2",
        });
        console.log(error.response);
        if (error.response.data.errors.length > 0)
          this.errors = error.response.data.errors;
        else this.errors = error.response.data.message;
        const myElement: HTMLElement | null =
          document.getElementById("scroll-add-user");
        if (myElement != null) myElement.scrollIntoView();
      });
  }

  // reset() {
  //   (this.$refs.form as HTMLFormElement).reset();
  // }

  closeResponse() {
    this.reset();
    this.dialog = false;
    // this.$emit("hideDialog", response);
  }

  mounted() {
    // console.log("xData", this.calltype);
    this.type = this.calltype;
    // const userID = localStorage.getItem("userID");
    // this.openDialog();s
  }
} //end Dashboard Dialog
