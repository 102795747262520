const errorResponse = (error, store): string[] => {
  console.log(error.response);
  store.dispatch("Add_Snackbar", {
    timeout: 6000,
    text: error.response.data.message,
    color: "red darken-2",
  });
  return error.response.data.errors.length > 0
    ? error.response.data.errors
    : [error.response.data.message];
};

export default errorResponse;
