



















import Vue from "vue";
import axios, { AxiosResponse } from "axios";
import Component from "vue-class-component";

const Props = Vue.extend({
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: String,
  },
});

@Component
export default class ComponentXImage extends Props {
  public url;
  public altName = "";
  public load = false;
  async getImageX() {
    this.load = false;
    await axios
      .get<unknown, AxiosResponse<Blob>>(this.src, {
        responseType: "blob",
      })
      .then(async (response) => {
        console.log(response.data, this.src);
        const result = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(response.data);
        });

        if (typeof result === "string") {
          this.url = result;
          this.load = true;
        }
      })
      .catch((error) => {
        this.url = this.src;
        this.load = true;
        console.log("Error:", this.src, error.response);
      });
  }

  mounted(): void {
    this.getImageX();
    this.altName = !this.alt ? "" : this.alt;
  }
}
