
































































































































// import { defineComponent } from "vue";
import errorResponse from "@/services/errorCode";
import UserData from "@/types/UserData";
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";
import UserMeritsData from "@/types/UserMeritsData";
import { Watch } from "vue-property-decorator";
const Props = Vue.extend({
  props: {
    meritId: {
      type: String,
      required: false,
    },
    autoload: {
      type: Boolean,
      default: false,
    },
  },
});

@Component
export default class ComponentViewMerit extends Props {
  public dialog = false;
  public ready$ = false;
  public errors: string[] = [];
  public loading = true;
  public meritName = "";
  public meritColor = "";
  public meritUser = "";
  public meritAwardedBy = "";
  public meritUpdatedAt = "";
  public meritDescription = "";

  emptyRules = [(v) => !!v || "Required"];

  closeDelete() {
    this.dialog = false;
    this.$emit("rolechange", "Request Canceled");
  }
  getMerit(id: string) {
    axios
      .get("/user-merits/" + id)
      .then((response) => {
        this.loading = false;
        let merit = response.data.data;
        this.meritName = " - " + merit.attributes.merit.name;
        this.meritColor = merit.attributes.merit.color;
        this.meritUser = merit.attributes.user;
        this.meritAwardedBy = merit.attributes.awarded_by;
        this.meritUpdatedAt = merit.attributes.updated_at.slice(0, 10);
        this.meritDescription = merit.attributes.description;
      })
      .catch((error) => {
        this.loading = false;
        this.dialog = false;
        this.onError(error, "scroll-dialogs");
      });
  }
  // loadRoles() {
  //   this.ready$ = false;
  //   axios
  //     .get("/users?paginate=false")
  //     .then((response) => {
  //       console.log("Users", response);
  //       this.users = response.data.data;
  //       this.ready$ = true;
  //     })
  //     .catch((error) => {
  //       console.log(error.response);
  //       this.dialog = false;
  //     });
  // }

  private onError(error, scroll: string): void {
    this.errors = errorResponse(error, this.$store);
    const myElement: HTMLElement | null = document.getElementById(scroll);
    if (myElement != null) myElement.scrollIntoView();
  }

  mounted() {
    console.log("mounted view component");
    this.dialog = this.autoload;
  }
  @Watch("dialog")
  onPropertyChanged(value: boolean, oldValue: boolean) {
    if (value) {
      this.getMerit(this.meritId);
    }
  }
}
