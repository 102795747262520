
// import { defineComponent } from "vue";
import errorResponse from "@/services/errorCode";
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";
import PhoneInput from "@/components/reusable/PhoneInput.vue";
import { CountryCodeData } from "@/types/CountryCodeData";
import baseAPI from "@/services/BaseConfig";
import EmergencyContactComponent from "@/components/body/user/EmergencyContactComponent.vue";
import {
  EmergencyContact,
  EmergencyContactAttributes,
} from "@/types/EmergencyContact";

const Props = Vue.extend({
  props: {
    dialogVisible: Boolean,
  },
});

@Component({
  components: {
    PhoneInput: PhoneInput,
    EmergencyContactComponent: EmergencyContactComponent,
  },
})
export default class ComponentUserRegistration extends Props {
  get Dialog$() {
    return this.dialogVisible;
  }
  public default_panel = 0;
  public panelChange(x: number) {
    this.default_panel = x;
  }
  ready$ = false;
  private restrict = 5;
  private companyID = "";
  private userID = "";
  public get email$(): string {
    return this.$store.getters["userEmail"];
  }
  public get userID$(): string {
    return this.$store.getters["getUserID"];
  }

  public countries: CountryCodeData[] = [];
  public emergencyContacts: EmergencyContactAttributes[] = [
    {
      id: null,
      first_name: "",
      last_name: "",
      email: null,
      phone_number_country_id: null,
      phone_number: null,
      relationship: null,
      phone_type: null,
    } as EmergencyContactAttributes,
    {
      id: null,
      first_name: "",
      last_name: "",
      email: null,
      phone_number_country_id: null,
      phone_number: null,
      relationship: null,
      phone_type: null,
    } as EmergencyContactAttributes,
  ];

  leaveOptions = [
    {
      text: "Eligible",
      value: "1",
    },
    {
      text: "Not Eligible",
      value: "0",
    },
  ];

  date = false;
  startdate = false;
  public formdata: {
    file: {
      avatar: File | undefined;
      form_id_1: File | undefined;
      form_id_2: File | undefined;
    };
    user;
    financial;
    valid: {
      user: boolean;
      financial: boolean;
      file: boolean;
    };
  } = {
    user: {
      first_name: "",
      last_name: "",
      password: "",
      password_confirmation: "",
      phone: "",
      phone_country_code_id: null,
      skype: "",
      whatsapp: "",
      whatsapp_country_code_id: null,
      emergency_contact_1: "",
      emergency_contact_1_name: "",
      emergency_contact_2: "",
      emergency_contact_2_name: "",
      gender: "",
      date_of_birth: "",
      address_line_1: "",
      address_line_2: "",
      city: null,
      state: null,
      postal_code: null,
    },
    financial: {
      local_tax_id: "",
      national_health_insurance_number: "",
      bank_name: "",
      bank_branch: "",
      bank_account_holder: "",
      bank_account_number: "",
      previous_employer: "",
      aba_number: "",
      swift_code: "",
    },
    file: {
      avatar: undefined,
      form_id_1: undefined,
      form_id_2: undefined,
    },
    valid: {
      user: false,
      financial: false,
      file: false,
    },
  };

  public get formValid() {
    return this.formdata.valid.user && this.formdata.valid.financial;
  }
  public errors: string[] = [];
  public date_dob = false;
  public date_start = false;
  public date_vacation = false;

  length!: number;

  updateEmergencyContact(contact: EmergencyContactAttributes, index: number) {
    this.emergencyContacts[index] = contact;
    console.log(this.emergencyContacts);
  }

  capitalize = (s: string) => (s && s[0].toUpperCase() + s.slice(1)) || "";

  rules = {
    required: (value) => {
      return !value || value == undefined ? "Required." : true;
    },
    counter: (value) => value.length <= 20 || "Max 20 characters",
    must9: (value) =>
      value == "" || value.length == 9 || "Must be 9 characters",
    min8: (value) =>
      value == "" || value.length >= 8 || "Must be 8 or more characters",
    email: (value) => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail.";
    },
    equals: (value) => {
      console.log(value, this.formdata.user, "Test");
      return value == this.formdata.user.password || "Passwords must match";
    },
    validDate: (value) => {
      const pattern = /^\d{4}-\d{2}-\d{2}$/;
      if (value == null || value == "") return true;
      //is date
      if (isNaN(Date.parse(value))) return "Must be a valid date";
      //regex date must be in format yyyy-mm-dd
      if (!pattern.test(value)) return "Must be in format yyyy-mm-dd";
    },
  };

  async addUser() {
    if (this.formValid) {
      let form = this.formdata;
      const data = new FormData();
      // if (form.financial.aba_number != "") form.financial.aba_number = parseInt(form.financial.aba_number);
      if (form.financial.aba_number == "") delete form.financial.aba_number;
      // if (form.financial.swift_code == "") form.financial.swift_code = null;
      if (form.financial.swift_code == "") delete form.financial.swift_code;
      // console.log(leaves);
      // var files = {};

      if (form.file.avatar != undefined) {
        data.append("avatar", form.file.avatar);
      }
      if (form.file.form_id_1 != undefined) {
        data.append("form_id_1", form.file.form_id_1);
      }
      if (form.file.form_id_2 != undefined) {
        data.append("form_id_2", form.file.form_id_2);
      }

      for (const key in this.formdata.user) {
        data.append(key, this.formdata.user[key]);
      }

      for (const key in this.formdata.financial) {
        data.append(key, this.formdata.financial[key]);
      }

      data.append("id", this.userID$);

      // for (var i = 0; i < this.emergencyContacts.length; i++) {
      //   data.append(
      //     "emergency_contacts[]",
      //     JSON.stringify(this.emergencyContacts[i])
      //   );
      // }

      for (let i = 0; i < this.emergencyContacts.length; i++) {
        for (let key of Object.keys(this.emergencyContacts[i])) {
          data.append(
            `emergency_contacts[${i}][${key}]`,
            this.emergencyContacts[i][key]
          );
        }
      }

      axios
        .post("/users", data)
        .then((response) => {
          console.log(response.data);
          this.$emit("close", {
            status: true,
            message: response.data.message,
          });
        })
        .catch((error) => {
          this.onError(error, "scroll-dialogs");
        });
    }
    console.log("Add User", this.formdata);
  }

  reset() {
    // console.log("user", this.$refs.userform as HTMLFormElement);
    // console.log("financial", this.$refs);
    // console.log("file", this.$refs.fileform as HTMLFormElement);

    this.formdata = {
      user: {
        first_name: "",
        last_name: "",
        password: "",
        password_confirmation: "",
        phone: "",
        skype: "",
        whatsapp: "",
        emergency_contact_1: "",
        emergency_contact_1_name: "",
        emergency_contact_2: "",
        emergency_contact_2_name: "",
        gender: "",
        date_of_birth: "",
        address_line_1: "",
        address_line_2: "",
      },
      financial: {
        local_tax_id: "",
        national_health_insurance_number: "",
        bank_name: "",
        bank_branch: "",
        bank_account_holder: "",
        bank_account_number: "",
        previous_employer: "",
        aba_number: "",
        swift_code: "",
      },
      file: {
        avatar: undefined,
        form_id_1: undefined,
        form_id_2: undefined,
      },
      valid: {
        user: false,
        financial: false,
        file: false,
      },
    };
    this.emergencyContacts = [
      {
        id: null,
        first_name: "",
        last_name: "",
        email: null,
        phone_number_country_id: null,
        phone_number: null,
        relationship: null,
        phone_type: null,
      } as EmergencyContactAttributes,
      {
        id: null,
        first_name: "",
        last_name: "",
        email: null,
        phone_number_country_id: null,
        phone_number: null,
        relationship: null,
        phone_type: null,
      } as EmergencyContactAttributes,
    ];
  }

  closeResponse(response: { status: boolean; text: string }) {
    this.$emit("close", response);
    this.reset();
  }

  private testform() {
    this.formdata = {
      user: {
        first_name: "Rakesh",
        last_name: "Lalchan",
        password: "",
        password_confirmation: "",
        phone: "18683333333",
        skype: "rlal@skype.com",
        whatsapp: "18683333333",
        emergency_contact_1: "18683332222",
        emergency_contact_1_name: "Person E1",
        emergency_contact_2: "18682223333",
        emergency_contact_2_name: "Person E2",
        gender: "Male",
        date_of_birth: "1994-10-19",
        address_line_1: "Tarence Lane, Big Ave",
        address_line_2: "Poure Main",
      },
      financial: {
        local_tax_id: "1020201",
        national_health_insurance_number: "1232498",
        bank_name: "Kid Land",
        bank_branch: "Big Land",
        bank_account_holder: "Heavy Heay",
        bank_account_number: "12324323",
        previous_employer: "Toild",
        aba_number: "",
        swift_code: "",
      },
      file: {
        avatar: undefined,
        form_id_1: undefined,
        form_id_2: undefined,
      },
      valid: {
        user: false,
        financial: false,
        file: false,
      },
    };
  }

  private onError(error, scroll: string): void {
    this.errors = errorResponse(error, this.$store);
    const myElement: HTMLElement | null = document.getElementById(scroll);
    if (myElement != null) myElement.scrollIntoView();
  }

  private async getCounties() {
    await baseAPI({ central: true })
      .get("/country-codes")
      .then((response) => {
        this.countries = response.data.data;
      });
  }

  mounted() {
    this.getCounties();
    this.$store.dispatch("commitBASE");
  }
} //end Dashboard Dialog
