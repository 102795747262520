import axios from "axios";
import Vue from "vue";
import VueAuthImage from "vue-auth-image";
Vue.use(VueAuthImage);
const Headers = {
  Accept: "application/json",
};

//myBaseTenentAPI
axios.defaults.baseURL =
  process.env.VUE_APP_API_SCHEME + "://" + process.env.VUE_APP_API_URL;

axios.defaults.headers.post = Headers;

interface APIParams {
  central: boolean;
}

const baseAPI = (params?: APIParams) =>
  axios.create({
    baseURL:
      process.env.VUE_APP_API_SCHEME +
      "://" +
      process.env.VUE_APP_API_URL +
      (params?.central ? "/central" : "/api"),
  });
export default baseAPI;
