











































































































































































































































































// import { defineComponent } from "vue";
import UserData from "@/types/UserData";
import LeaveType from "@/types/LeaveType";
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";

const Data = Vue.extend({
  props: {
    leave: Object,
  },
});

import ComponentRangedDates from "@/components/reusable/DateRangePicker.vue";
import errorResponse from "@/services/errorCode";
@Component({ components: { RangedDates: ComponentRangedDates } })
export default class ComponentAddUserLeave extends Data {
  public dialog = false;
  public ready$ = false;
  public users: UserData[] = [];
  public leaveTypes: LeaveType[] = [];
  public errors: string[] = [];
  public form: {
    title: string;
    desc: string;
    leave_type: number | null;
    user: string;
    file: File | null;
  } = {
    desc: "",
    title: "",
    user: "",
    leave_type: null,
    file: null,
  };
  public loadLeaves = true;
  public leaves = [{ start_date: "", end_date: "" }];
  public showFileInput = false;
  private get role$(): boolean {
    return this.$store.getters["getRole"];
  }
  counter = 1;
  public emptyRules = (v) => !!v || "Required";

  public min6 = (value) =>
    value.length >= 6 ? true : "Password should have more than 6 characters.";

  public get isEmptyForm(): boolean {
    return !!this.form.title && !!this.form.desc;
  }

  clearForm() {
    this.loadLeaves = false;
    this.form = { title: "", desc: "", file: null, leave_type: null, user: "" };
    this.leaves = [{ start_date: "", end_date: "" }];
    this.dialog = false;
    this.loadLeaves = true;
  }

  closeDelete() {
    this.clearForm();
    this.$emit("addrequest", { reload: false, text: "Request Canceled" });
  }

  removeFile() {
    this.form.file = null;
  }

  addLeaveDate(
    dates: { start_date: string; end_date: string; days: number },
    index: number
  ) {
    // console.log("add", dates, index);
    this.leaves[index] = dates;
    console.log(this.leaves);
    console.log(index);
    this.showFileInput = dates.days > 1;
    this.counter = this.leaves.length;
  }

  checkRangeEmpty(Leave: { start_date: string; end_date: string }): boolean {
    // console.log(Leave, Leave.start_date.length > 0);
    return Leave.start_date.length > 0;
  }

  addRange() {
    if (this.counter <= 0) {
      this.leaves.push({ start_date: "", end_date: "" });
    } else {
      if (
        Object.keys(this.leaves[this.counter - 1]).length !== 0 &&
        this.checkRangeEmpty(this.leaves[this.leaves.length - 1])
      )
        this.leaves.push({ start_date: "", end_date: "" });
      else {
        this.$emit("addrequest", {
          reload: false,
          text: "Add dates to input above",
        });
      }
    }
    this.counter = this.leaves.length;
  }

  deleteRange(index) {
    this.loadLeaves = false;
    // console.log(index);
    if (index <= 0) {
      this.leaves.splice(0, 1);
      // console.log(this.leaves);
      this.counter = this.leaves.length;
      this.addRange();
      this.loadLeaves = true;
    } else {
      this.leaves.splice(index, 1);
      // console.log(this.leaves);
      this.counter = this.leaves.length;
      this.loadLeaves = true;
    }
  }

  update() {
    console.log(Object.keys(this.leaves[0]).length === 0);

    if (Object.keys(this.leaves[0]).length === 0) {
      this.$emit("addrequest", {
        reload: false,
        text: "Dates cannot be empty",
      });
    } else {
      const data: FormData = new FormData();

      data.append("title", this.form.title);
      data.append("description", this.form.desc);
      data.append("leave_type_id", this.form.leave_type!.toString());
      if (Object.keys(this.leaves[this.counter - 1]).length === 0)
        this.deleteRange(this.counter - 1);
      if (this.form.file) data.append("media", this.form.file);
      this.leaves.forEach((value, index) => {
        data.append(`leaves[${index}][start_date]`, value.start_date);
        data.append(`leaves[${index}][end_date]`, value.end_date);
      });
      data.append("user_id", this.form.user);
      axios
        .post("/leave-requests", data)
        .then((response) => {
          // console.log(response);
          this.$emit("addrequest", {
            reload: response.data.success,
            text: response.data.message,
          });
        })
        .catch((error) => {
          this.onError(error, "scroll-dialogs");
          // console.log(error.response);
        });
    }
  }

  getAllUser() {
    axios
      .get(`/users?paginate=false`)
      .then((response) => {
        this.users = response.data.data;
        this.ready$ = true;
      })
      .catch((error) => {
        this.onError(error, "scroll-dialogs");
      });
  }

  getLeaveTypes() {
    axios
      .get("/leave-types")
      .then((response) => {
        // console.log(response);
        this.leaveTypes = response.data.data;
      })
      .catch((error) => {
        this.onError(error, "scroll-dialogs");
      });
  }

  private onError(error, scroll: string): void {
    console.log(error.response);
    this.errors = errorResponse(error, this.$store);
    const myElement: HTMLElement | null = document.getElementById(scroll);
    if (myElement != null) myElement.scrollIntoView();
  }

  mounted() {
    this.getAllUser();
    this.getLeaveTypes();
  }
}
