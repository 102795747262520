//store/modules/auth.js
//need a endpoint to check the autherization of users before pages
//get self user should be ideal in this situation
//the endpoint access must be handled in state (here)
import axios from "axios";
import router from "@/router";
import UserData from "@/types/UserData";
const state = {
  buildVersion: "",
  basic: { user_uuid: null, token: null, role: null, email: null },
  user: {
    attributes: {
      address_line_1: null,
      address_line_2: null,
      avatar: null,
      bank_account_holder: null,
      bank_account_number: null,
      bank_branch: null,
      branch_id: null,
      created_at: null,
      date_of_birth: null,
      department_id: null,
      email: null,
      emergency_contact_1: null,
      emergency_contact_1_name: null,
      emergency_contact_2: null,
      emergency_contact_2_name: null,
      employee_title_id: null,
      end_date: null,
      first_name: null,
      form_id_1: null,
      form_id_2: null,
      gender: null,
      last_name: null,
      leave_bereavement: null,
      leave_casual: null,
      leave_maternity: null,
      leave_sick: null,
      leave_study: null,
      leave_vacation: null,
      local_tax_id: null,
      national_health_insurance_number: null,
      phone: null,
      previous_employer: null,
      push_service_token: null,
      quote: null,
      reporting_manager_id: null,
      salary: null,
      skype: null,
      start_date: null,
      updated_at: null,
      user_status_id: null,
      whatsapp: null,
      vacation_latest_start_date: null,
      vacation_latest_end_date: null,
    },
    id: null,
    links: {
      self: null,
    },
    meta: null,
    relationships: null,
    type: null,
  },
  url: {
    domain: process.env.VUE_APP_API_URL,
    scheme: process.env.VUE_APP_API_SCHEME,
    api: "/api/v1",
  },
  company: {
    subdomain: "",
    id: "",
    visible: false,
    company_name: "",
    country: "",
  },
  snackbar: { state: false, text: "", timeout: -1, color: "orange darken-1" },
  login: { state: false, errors: [], register: false },
};

const getters = {
  buildVersion: (state) => state.buildVersion,
  isAuthenticated: (state) => !!state.basic.token,
  getToken: (state) => state.basic.token,
  getRestricted: (state) => {
    if (state.basic.role === "admin") return 2;
    else if (state.basic.role === "manager") return 3;
    else if (state.basic.role === "user") return 4;
    else return 5;
  },
  getRole: (state) => state.basic.role,
  // getToken: (state) => state.basic.token,
  getUserID: (state) => state.basic.user_uuid,
  // userFullName: (state) => {
  //   return (
  //     state.user.attributes.first_name + " " + state.user.attributes.last_name
  //   );
  // },
  getBASE: (state) =>
    state.url.scheme + ":// " + state.url.domain + state.url.api,
  userFirstName: (state) => state.user.attributes.first_name,
  userEmail: (state) => state.basic.email,
  getSelfAttributes: (state) => state.user.attributes,
  getSnackbar: (state) => state.snackbar.state,
  getSnackbarData: (state) => {
    return {
      text: state.snackbar.text,
      timeout: state.snackbar.timeout,
      color: state.snackbar.color,
    };
  },
  getLoginState: (state) => state.login.state,
  getShowRegistration: (state) => state.login.register,
  getLoginErrors: (state) => state.login.errors,
  getSelf: (state) => state.user,
  getShowCompany: (state) => state.company.visible,
  getCompany: (state) => state.company,
};

const actions = {
  // async Register({ dispatch }, form) {
  //   await axios.post("register", form);
  //   const UserForm = new FormData();
  //   UserForm.append("email", form.email);
  //   UserForm.append("password", form.password);
  //   await dispatch("Login", UserForm);
  // },

  async ForgotPassword({ commit }, payload: { data: FormData }) {
    return axios
      .post("/central/forgot-password", payload.data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        // console.log(error.response);
        throw error;
      });
  },

  async Login(context, payload: { data: FormData }) {
    // await axios
    // .post("/central/login/", { email: payload.data.get("email") })
    // .then(async (response) => {
    //   if (response.status == 200) {

    //
    // ADD THIS TO THE LOGIN FUNCTION
    //

    // console.log(response);
    // context.commit("setBASE", {
    //   subdomain: response.data.data.attributes.domain,
    //   id: response.data.data.id,
    // });
    // context.commit("commitBASE");
    // context.dispatch("HandleLogin", payload);

    // END HERE
    await axios
      .post("/login", payload.data)
      .then(async (response) => {
        // console.log("LINE 150 Login", response);
        const loginPayload = response.data;
        // const loginPayload = response.data;
        // loginPayload.registration_complete = false;
        context.dispatch("HandleLogin", loginPayload);
      })
      .catch(async (error) => {
        console.log("Error");
        context.commit("Logout");
        if (error.response) {
          console.log(error.response);
          const statusCode = error.response.status;
          if (statusCode == 422) {
            context.commit("setSnackbar", {
              text: error.response.data.message,
              state: true,
              timeout: -1,
              color: "red accent-2",
            });
            context.commit("setLogin", error.response.data.errors);
          } else if (statusCode == 405) {
            try {
              await axios.get("/refresh-token").then((response) => {
                console.log("refresh Token", response);

                context.commit("setUser", response.data);
                localStorage.setItem("user", JSON.stringify(response.data));
                router.push({ name: "Dashboard" });
              });
            } catch (error) {
              console.log(error);
            }
          } else if (statusCode == 500) {
            context.commit("setSnackbar", {
              text: error.response.data.message,
              state: true,
              timeout: -1,
              color: "red accent-2",
            });
          } else
            context.commit("setSnackbar", {
              text: error.response.data.message,
              state: true,
              timeout: -1,
              color: "red accent-2",
            });
        }
      });
    // }
    // });
    // .catch(async (error) => {
    //   console.log("Error");
    //   console.log(error.response);
    //   context.commit("Logout");
    //   if (error.response.data.status == false) {
    //     context.commit("setSnackbar", {
    //       text: error.response.data.message,
    //       state: true,
    //       timeout: -1,
    //       color: "red accent-2",
    //     });
    //   }
    // });
  },
  // async Logins(context, payload) {
  //   await axios
  //     .post("/central/v2/login/", { email: payload.data.get("email") })
  //     .then(async (response) => {
  //       if (response.status == 200) {
  //         console.log(response.data);
  //         context.dispatch("HandleLogin", payload);
  //         context.commit("showCompanyDialog", true);
  //         console.log(response.data.data.id);
  //         console.log(response.data.data.attributes.company);
  //         context.commit("setBASE", {
  //           response: response.data,
  //           subdomain: response.data.data.attributes.domain,
  //           id: response.data.data.id,
  //           company: response.data.data.attributes.company,
  //           country: response.data.data.attributes.country,
  //         });
  //         context.commit("commitBASE");
  //         context.commit("setCompany", {
  //           company: response.data.data.attributes.company,
  //         });
  //         context.commit("setCompanyCountry", {
  //           country: response.data.data.attributes.country,
  //         });
  //       }
  //     })
  //     .catch(async (error) => {
  //       console.log("Error");
  //       console.log(error.response);
  //       context.commit("Logout");
  //       if (error.response.data.status == false) {
  //         context.commit("setSnackbar", {
  //           text: error.response.data.message,
  //           state: true,
  //           timeout: -1,
  //           color: "red accent-2",
  //         });
  //       }
  //     });
  // },
  async LoginUse(context, payload) {
    await axios
      .post("/login", payload.data)
      .then(async (response) => {
        const loginPayload = response.data;
        context.dispatch("HandleLogin", loginPayload);
      })
      .catch(async (error) => {
        console.log("Error");
        context.commit("Logout");
        if (error.response) {
          console.log(error.response);
          const statusCode = error.response.status;
          if (statusCode == 422) {
            context.commit("setSnackbar", {
              text: error.response.data.message,
              state: true,
              timeout: -1,
              color: "red accent-2",
            });
            context.commit("setLogin", error.response.data.errors);
          } else if (statusCode == 405) {
            try {
              await axios.get("/refresh-token").then((response) => {
                console.log("refresh Token", response);
                context.commit("setUser", response.data);
                localStorage.setItem("user", JSON.stringify(response.data));
                router.push({ name: "Dashboard" });
              });
            } catch (error) {
              console.log(error);
            }
          } else if (statusCode == 500) {
            context.commit("setSnackbar", {
              text: error.response.data.message,
              state: true,
              timeout: -1,
              color: "red accent-2",
            });
          } else {
            context.commit("setSnackbar", {
              text: error.response.data.message,
              state: true,
              timeout: -1,
              color: "red accent-2",
            });
          }
        }
      });
  },

  async SanctumOauth() {
    return await axios
      .get("/central/v2/authorized/google", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Cross-Origin-Resource-Policy": "unsafe-none",
        },
      })
      .then(async (response) => {
        return response.data.data.url;
      })
      .catch(async (error) => {
        console.log("Error");
        console.log(error.response);
      });
  },

  async LoginWithGoogle(context: any, payload: any) {
    console.log(payload);
    context.commit("setBASE", {
      subdomain: "",
      id: payload.tenant,
    });
    context.commit("commitBASE");
    context.dispatch("HandleLogin", payload);
  },
  // async HandleCompanyDialog(context: any, payload: any) {
  //   const data: {
  //     success: boolean;
  //   } = payload;
  // },
  async HandleLogin(context: any, payload: any) {
    const data: {
      success: boolean;
      token: string;
      user_id: string;
      first_name: string;
      last_name: string;
      email: string;
      registration_complete: boolean;
      role: string;
    } = payload;

    context.commit("setUser", data);
    context.commit("setToken");
    // console.log("HandleLogin", data.registration_complete);

    if (data.registration_complete == true) {
      context.commit("showRegistrationForm", !data.registration_complete);
      console.log("beforecommit");
      await axios.get("/company").then((response) => {
        console.log("company");
        console.log(response);
        context.commit("setCompany", {
          company: response.data.data.attributes.name,
        });
        context.commit("setCompanyCountry", {
          country: response.data.data.attributes.country,
        });
      });
      await axios
        .get("/user")
        .then((response) => {
          console.log(response);
          // console.log("post");
          // console.log(response.data);
          context.commit("setUserData", response.data.data);
          // const data: {
          //   success: boolean;
          //   token: string;
          //   user_id: string;
          // } = response.data;
          if (response.data.success) {
            // console.log(data);
            console.log("here");
            context.commit("snackbarReset");
            localStorage.setItem("user", JSON.stringify(data));
            console.log("pushing");
            router.push({ name: "Dashboard" });
          }
          // else {
          //   this.showError = true;
          //   this.errorMessage = data.token;
          // }
        })
        .catch((error) => {
          console.log(error.response);
          context.commit("Logout");
          // const statusCode = error.response.status;
          // if (statusCode == 401) {
          // }
        });
      // context.commit("setUser", response.data);
      // localStorage.setItem("user", JSON.stringify(data));
      // router.push({ name: "Dashboard" });
      // this.AutoLogin();
      // this.$router.push({ name: "Dashboard" });
    } else if (data.registration_complete == false) {
      console.log(data);
      context.commit("showRegistrationForm", !data.registration_complete);
    } else {
      return null;
    }
  },

  Logout({ commit }) {
    commit("Logout");
    router.push({ name: "Login" });
  },
  registrationComplete({ commit }, payload) {
    commit("showRegistrationForm", payload.close);
    commit("Logout");
    router.push({ name: "Login" });
  },
  registrationBegin({ commit }, payload) {
    commit("showRegistrationForm", payload);
    // router.push({ name: "Login" });
  },
  build({ commit }) {
    commit("setBuild");
    // router.push({ name: "Login" });
  },

  async AutoLogin({ commit }, payload) {
    // console.log("AutoLogin", payload);
    const company = localStorage.getItem("company")?.toString();
    commit("setBASE", {
      subdomain: payload.subdomain,
      id: company,
    });
    commit("commitBASE");
    commit("setUser", payload.user);
    commit("setToken");

    await axios
      .get("/refresh-token")
      .then((response) => {
        console.log("refresh Token", response);

        commit("setUser", response.data);
        localStorage.setItem("user", JSON.stringify(response.data));
        router.push({ name: "Dashboard" });
      })
      .catch((error) => {
        // commit("Logout");
        console.log(error);
      });
  },

  async TokenReset({ commit }) {
    await axios
      .get("/refresh-token")
      .then((response) => {
        console.log("refresh Token", response);

        commit("setUser", response.data);
        commit("setToken");
        localStorage.setItem("user", JSON.stringify(response.data));
        commit("setSnackbar", {
          text: "Success",
          state: true,
          timeout: 1000,
          color: "Green accent-2",
        });
        router.go(0);
      })
      .catch((error) => {
        console.log(error.response);

        commit("Logout");
        router.go(0);
      });
  },

  commitBASE({ commit }) {
    commit("commitBASE");
    commit("setToken");
  },

  snackbarReset({ commit }) {
    commit("snackbarReset");
  },

  Add_Snackbar(
    { commit },
    snackbar: { text: string; timeout: number; color: string }
  ) {
    commit("setSnackbar", snackbar);
  },
};

const mutations = {
  setUser(
    state,
    user: {
      success: boolean;
      token: string;
      user_id: string;
      first_name: string;
      last_name: string;
      email: string;
      registration_complete: boolean;
      role: string;
    }
  ) {
    state.basic.token = user.token;
    state.basic.user_uuid = user.user_id;
    state.basic.email = user.email;
    state.basic.role = user.role;
  },

  setToken(state) {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${state.basic.token}`;

    // console.log(axios.defaults.headers);
  },

  setUserData(state, user: UserData) {
    state.user = user;
  },

  setBASE(state, payload: { response; subdomain; id; company; country }) {
    localStorage.setItem("subdomain", payload.subdomain); //updating the local storage using the subdomain and id values
    localStorage.setItem("id", payload.id);
    localStorage.setItem("company", payload.company);
    localStorage.setItem("country", payload.country);
    localStorage.setItem("response", payload.response);
    state.company.subdomain = payload.subdomain;
    state.company.id = payload.id;
    console.log(payload.response);
  },
  resetSetBase(state, payload) {
    localStorage.setItem("subdomain", "");
    localStorage.setItem("id", "");
    localStorage.setItem("company", "");
    localStorage.setItem("country", "");
    state.company.subdomain = "";
    state.company.id = "";
    state.company.company_name = "";
    state.company.country = "";
  },
  commitBASE(state) {
    axios.defaults.headers.post["X-Tenant"] = state.company.id;
    axios.defaults.headers.common["X-Tenant"] = state.company.id;
    // console.log(axios.defaults.headers);
    axios.defaults.baseURL =
      state.url.scheme + "://" + state.url.domain + state.url.api;
  },
  setCompany(state, payload: { company }) {
    state.company.company_name = payload.company;
  },
  setCompanyCountry(state, payload: { country }) {
    state.company.country = payload.country;
  },
  setPosts(state, posts) {
    state.posts = posts;
  },

  Logout(state) {
    state.basic = { user_uuid: null, token: null, role: null };
    state.user = {
      attributes: {
        address_line_1: null,
        address_line_2: null,
        avatar: null,
        bank_account_holder: null,
        bank_account_number: null,
        bank_branch: null,
        branch_id: null,
        created_at: null,
        date_of_birth: null,
        department_id: null,
        email: null,
        emergency_contact_1: null,
        emergency_contact_1_name: null,
        emergency_contact_2: null,
        emergency_contact_2_name: null,
        employee_title_id: null,
        end_date: null,
        first_name: null,
        form_id_1: null,
        form_id_2: null,
        gender: null,
        last_name: null,
        leave_bereavement: null,
        leave_casual: null,
        leave_maternity: null,
        leave_sick: null,
        leave_study: null,
        leave_vacation: null,
        local_tax_id: null,
        national_health_insurance_number: null,
        phone: null,
        previous_employer: null,
        push_service_token: null,
        quote: null,
        reporting_manager_id: null,
        salary: null,
        skype: null,
        start_date: null,
        updated_at: null,
        user_status_id: null,
        whatsapp: null,
      },
      id: null,
      links: {
        self: null,
      },
      meta: null,
      relationships: null,
      type: null,
    };
    state.company = { subdomain: "", id: "" };
    axios.defaults.headers.common["X-Tenant"] = "";
    axios.defaults.baseURL = state.url.scheme + "://" + state.url.domain;
  },

  snackbarReset(state) {
    state.snackbar.state = false;
    state.snackbar.text = "";
    state.snackbar.timeout = -1;
    state.snackbar.color = "orange darken-1";
  },

  showRegistrationForm(state, value) {
    state.login.register = value;
  },
  setSnackbar(state, snackbar) {
    state.snackbar.state = true;
    state.snackbar.text = snackbar.text;
    state.snackbar.timeout = snackbar.timeout;
    state.snackbar.color = snackbar.color;
    if (snackbar.timeout != -1)
      setTimeout(() => {
        state.snackbar = {
          state: false,
          text: "",
          timeout: -1,
          color: "orange darken-1",
        };
        console.log(state.snackbar);
      }, snackbar.timeout);
  },

  setLogin(state, errors) {
    state.login.state = true;
    state.login.errors = errors;
  },
  setBuild(state) {
    state.buildVersion = `Version ${process.env.VUE_APP_VERSION}+${process.env.VUE_APP_BUILD}`;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
