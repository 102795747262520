


















































































































// import { defineComponent } from "vue";
import errorResponse from "@/services/errorCode";
import UserData from "@/types/UserData";
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";
import MeritsData from "../../../types/MeritsData";

const Props = Vue.extend({
  props: {
    userid: String,
    merits: Array,
  },
});

@Component
export default class ComponentAddMerit extends Props {
  public dialog = false;
  public ready$ = false;
  public errors: string[] = [];
  public users: UserData[] = [];
  public form: {
    user: UserData | null;
    merit: MeritsData | null;
    desc: string;
  } = {
    user: null,
    merit: null,
    desc: "",
  };

  emptyRules = [(v) => !!v || "Required"];

  fullName(item: UserData) {
    return `${item.attributes.first_name} ${item.attributes.last_name}`;
  }

  meritListTitle(item: MeritsData) {
    return `${item.attributes.name} (${item.attributes.points})`;
  }
  public get isEmptyForm(): boolean {
    return !!this.form.user && !!this.form.merit && !!this.form.desc;
  }
  closeDelete() {
    this.form = { user: null, merit: null, desc: "" };
    this.dialog = false;
    this.$emit("rolechange", "Request Canceled");
  }
  add() {
    const data: FormData = new FormData();
    data.append("merit_id", this.form.merit!.id);
    data.append("user_id", this.form.user!.id);
    data.append("descriptions", this.form.desc);
    axios
      .post("/user-merits", data)
      .then((response) => {
        this.dialog = false;
        // console.log(response.data);
        this.$emit("addmerit", {
          status: true,
          message: response.data.message,
        });
      })
      .catch((error) => {
        this.onError(error, "scroll-dialogs");
      });
  }
  loadRoles() {
    this.ready$ = false;
    axios
      .get("/users?paginate=false")
      .then((response) => {
        console.log("Users", response);
        this.users = response.data.data;
        this.ready$ = true;
      })
      .catch((error) => {
        console.log(error.response);
        this.dialog = false;
      });
  }

  private onError(error, scroll: string): void {
    this.errors = errorResponse(error, this.$store);
    const myElement: HTMLElement | null = document.getElementById(scroll);
    if (myElement != null) myElement.scrollIntoView();
  }

  mounted() {
    this.loadRoles();
  }
}
