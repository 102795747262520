



























































































































































// import { defineComponent } from "vue";
import errorResponse from "@/services/errorCode";
import UserData from "@/types/UserData";
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class ComponentChangePassword extends Vue {
  public dialog = false;
  public ready$ = false;
  errors: string[] = [];
  public users: UserData[] = [];
  public form: {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
  } = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  public emptyRules = (v) => !!v || "Required";

  public min6 = (value) =>
    value.length >= 6 ? true : "Password should have more than 6 characters.";

  public matchingPasswords = () =>
    this.form.newPassword === this.form.confirmPassword
      ? true
      : "Passwords does not match.";
  public get isEmptyForm(): boolean {
    return (
      !!this.form.oldPassword &&
      !!this.form.newPassword &&
      !!this.form.confirmPassword
    );
  }

  clearForm() {
    this.form = { oldPassword: "", newPassword: "", confirmPassword: "" };
    this.dialog = false;
    this.errors = [];
  }

  closeDelete() {
    this.clearForm();
    this.$emit("rolechange", "Request Canceled");
  }

  update() {
    console.log(this.form);
    const data: FormData = new FormData();
    data.append("current_password", this.form.oldPassword);
    data.append("new_password", this.form.newPassword);
    data.append("new_password_confirmation", this.form.confirmPassword);
    axios
      .post("/update-password", data)
      .then((response) => {
        this.clearForm();
        console.log(response.data);
        this.$store.dispatch("Add_Snackbar", {
          timeout: 3000,
          text: response.data.message,
          color: "green darken-2",
        });
      })
      .catch((error) => {
        this.onError(error, "scroll-dialogs");
      });
  }
  private onError(error, scroll: string): void {
    this.errors = errorResponse(error, this.$store);
    const myElement: HTMLElement | null = document.getElementById(scroll);
    if (myElement != null) myElement.scrollIntoView();
  }
}
