






























































































































































































































































































































































































































// import { defineComponent } from "vue";
import Vue from "vue";
import Component from "vue-class-component";
import axios from "axios";

import ComponentJobLetter from "@/components/body/profile/dialog-job-letter.vue";
import ComponentXImage from "@/components/reusable/XImage.vue";
import ComponentChangePassword from "@/components/body/profile/dialog-change-password.vue";
import ComponentPersonalityQuestions from "@/components/body/profile/dialog-personality-questions.vue";
import ComponentEditProfile from "@/components/body/profile/dialog-edit-profile.vue";
import UserAttributes from "@/types/UserAttributes";
import router from "@/router";

@Component({
  components: {
    DialogJob: ComponentJobLetter,
    DialogChangePassword: ComponentChangePassword,
    DialogPersonalityQuestions: ComponentPersonalityQuestions,
    DialogEditProfile: ComponentEditProfile,
    XImage: ComponentXImage,
  },
})
export default class ProfileView extends Vue {
  // private screenWidth = window.innerWidth;
  public mobileView: boolean = window.innerWidth <= 1000;
  public get restrict$(): number {
    return this.$store.getters["getRestricted"];
  }
  public get user$(): UserAttributes {
    return this.$store.getters["getSelfAttributes"];
  }
  public get isLoggedIn$(): boolean {
    return this.$store.getters["isAuthenticated"];
  }
  public get userID$(): string {
    return this.$store.getters["getUserID"];
  }

  profile: UserAttributes = this.user$;
  // fixedReady = false;
  isLoading$ = true;
  loadedWFH$ = false;
  wfhData = {};
  Days = [
    ["sunday", "S"],
    ["monday", "M"],
    ["tuesday", "T"],
    ["wednesday", "W"],
    ["thursday", "T"],
    ["friday", "F"],
    ["saturday", "S"],
  ];

  parseWFH(value: boolean): string {
    return value ? "Office" : "Home";
  }

  enableQuoteEdit = false;
  companyID = "0";
  user_id = "-3";

  sortByTab = 0;
  filterControls = {
    search: { data: "", status: false },
    title: { data: [], status: false },
  };
  private async wfh() {
    await axios
      .get("/wfh")
      .then((response) => {
        this.wfhData = response.data.data.attributes;
        // console.log(response.data.data.attributes);
        this.loadedWFH$ = true;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  private async getuser_by_id() {
    this.isLoading$ = true;
    this.loadedWFH$ = false;
    await axios
      .get("/user")
      .then(async (response) => {
        console.log(response.data.data);
        this.profile = response.data.data.attributes;
        this.isLoading$ = false;
        this.wfh();
      })
      .catch((error) => {
        console.log(error.response);
        if (!error.response.request.withCredentials) {
          // localStorage.removeItem("user");
          // this.$store.dispatch("Logout");
          if (error.response.data == undefined) {
            this.$store.dispatch("Add_Snackbar", {
              timeout: -1,
              text: "Base response: " + error.response,
              color: "red darken-2",
            });
          } else {
            this.$store.dispatch("Add_Snackbar", {
              timeout: -1,
              text: error.response.data.message,
              color: "red darken-2",
            });
          }

          // this.$store.dispatch;
        } else {
          this.$router.push("/");
        }
      }); // getall users
  } //getuser_by_id

  editProfile() {
    console.log("ep");
  }

  dashboard() {
    router.push("/");
  }

  mounted() {
    this.$store.dispatch("commitBASE");
    // const userID = localStorage.getItem("userID");
    this.getuser_by_id();
    // this.openDialog();
  }
} //end Dashboard Dialog
