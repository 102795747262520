
// import { defineComponent } from "vue";
import errorResponse from "@/services/errorCode";
import UserData from "@/types/UserData";
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";
import IconGoogle from "../../icons/IconGoogle.vue";

@Component({
  components: {
    IconGoogle: IconGoogle,
  },
})
export default class ComponentLoginWithGoogle extends Vue {
  beforeDestroy() {
    window.removeEventListener("message", this.onMessage);
  }

  mounted() {
    window.addEventListener("message", this.onMessage, false);
  }

  async onMessage(e: any) {
    if (Object.prototype.hasOwnProperty.call(e.data, "token")) {
      console.log("here");
      console.log(e.data);
      await this.$store.dispatch("LoginWithGoogle", e.data);
      // this.$store.dispatch("auth/saveToken", {
      //   token: e.data.token,
      // });
      // this.$router.push({ name: "home" });
    }
  }

  async LoginWithGoogle() {
    window.addEventListener("message", this.onMessage, false);
    const newWindow = this.openWindow("", "message");
    const result = await this.$store.dispatch("SanctumOauth", "google");
    if (newWindow) newWindow.location.href = result;
  }

  openWindow(url: string, title: string, options = {}) {
    if (typeof url === "object") {
      options = url;
      url = "";
    }
    options = { url, title, width: 600, height: 720, ...options };
    // const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screen.left
    // const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screen.top
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      window.screen.width;
    const height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      window.screen.height;
    // options.left = ((width / 2) - (options.width / 2)) + dualScreenLeft
    // options.top = ((height / 2) - (options.height / 2)) + dualScreenTop
    const optionsStr = Object.keys(options)
      .reduce((acc: string[], key) => {
        acc.push(`${key}=${options[key]}`);
        return acc;
      }, [])
      .join(",");
    const newWindow = window.open(url, title, optionsStr);
    if (window && newWindow) {
      newWindow.focus();
    }
    return newWindow;
  }
}
