































































































































































































































































































































































































































import Vue, { PropType } from "vue";
import Component from "vue-class-component";
import ComponentXImage from "@/components/reusable/XImage.vue";
import AbsentEmployee from "../../../types/AbsentEmployee";

// Define the props by using Vue's canonical way.
const Props = Vue.extend({
  props: {
    ready: Object,
    userID: String,
    matesAbsent: {
      type: Array as PropType<AbsentEmployee[]>, //Each item is expected to be of type 'Absent Employee'
    },
  },
});

@Component({
  components: {
    XImage: ComponentXImage,
  },
})
export default class ComponentNewEmployee extends Props {
  public dialog = false;

  toggleDialog(): void {
    this.dialog = !this.dialog;
  }
}
