


















































































































































































































































































































































// import { defineComponent } from "vue";
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";
import UserData from "@/types/UserData";
import TitleData from "@/types/TitleData";
import BranchData from "@/types/BranchData";
import DepartData from "@/types/DepartData";
import RolesData from "@/types/RolesData";
import LeaveType from "@/types/LeaveType";
import errorResponse from "@/services/errorCode";
import EmploymentType from "../../../types/EmploymentType";

const AddData = Vue.extend({
  props: {
    xData: Object,
    mobile: Boolean,
  },
});

@Component
export default class ComponentUserAdd extends AddData {
  dialog = false;
  ready$ = false;
  private restrict = 5;
  private companyID = "";
  private userID = "";
  branches: BranchData[] = [];
  private titles: TitleData[] = [];
  private departments: DepartData[] = [];
  public managers: UserData[] = [];
  public leaveTypes: LeaveType[] = [];
  public roles: RolesData[] = [];
  public employmentTypes: EmploymentType[] = [];
  public errors: string[] = [];
  filterDepartments: DepartData[] = [];
  filterTitles: TitleData[] = [];

  leaveOptions = [
    {
      text: "Eligible",
      value: "1",
    },
    {
      text: "Not Eligible",
      value: "0",
    },
  ];

  date = false;
  startdate = false;
  // filterManagers: UserData[] = [];
  public form: {
    basic: {
      fName: string;
      lName: string;
      email: string;
      vemail: string;
      branch: BranchData | undefined;
      department: DepartData | undefined;
      title: TitleData | undefined;
      manager: UserData | undefined;
      roles: string;
      salary: string;
      start_date: string;
      employment_type_id: string;
    };
    leave: {
      canTakeSickleave: string;
      canTakeVacation: string;
      vacation_start_date: string;
      types;
      bereavement: string;
      casual: string;
      maternity: string;
      study: string;
      sick: string;
      vacation: string;
    };
    valid: boolean;
  } = {
    basic: {
      fName: "",
      lName: "",
      email: "",
      vemail: "",
      branch: undefined,
      department: undefined,
      title: undefined,
      manager: undefined,
      roles: "",
      salary: "",
      start_date: "",
      employment_type_id: "",
    },
    leave: {
      canTakeSickleave: "",
      canTakeVacation: "",
      vacation_start_date: "",
      types: {},
      bereavement: "",
      casual: "",
      maternity: "",
      study: "",
      sick: "",
      vacation: "",
    },
    valid: false,
  };
  // dialog: boolean;
  length!: number;
  capitalize = (s: string) => (s && s[0].toUpperCase() + s.slice(1)) || "";
  rules = {
    required: (value) => {
      return !value || value == undefined ? "Required." : true;
    },
    counter: (value) => value.length <= 20 || "Max 20 characters",
    email: (value) => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail.";
    },
    equals: (value) => {
      return value === this.form.basic.email || "Emails must match";
    },
    vacationStartDate: (value) => {
      if (this.form.leave.canTakeVacation == "1") return !!value || "Required.";
      else return true;
    },
  };

  getRoles() {
    this.ready$ = false;
    axios
      .get("/roles")
      .then((response) => {
        // console.log("Roles", response.data);
        this.roles = response.data.data;
        this.ready$ = true;
      })
      .catch((error) => {
        console.log(error.response);
        this.ready$ = true;
      });
  }

  getEmploymentTypes() {
    axios
      .get("/employment-types")
      .then(async (response) => {
        this.employmentTypes = response.data.data;

        // console.log("employment types", this.employmentTypes);
      })
      .catch((error) => {
        console.log(error.response);
        this.ready$ = true;
      });
  }
  getLeaveTypes() {
    axios
      .get("/leave-types")
      .then((response) => {
        console.log(response.data.data);
        this.leaveTypes = response.data.data;
      })
      .catch((error) => {
        console.log(error.response);
      });
  }
  branchSelected(value: BranchData) {
    console.log("BranchID", value);

    this.form.basic.department = undefined;
    this.form.basic.title = undefined;

    this.filterTitles = [];
    this.filterDepartments = [];

    this.departments.forEach((department: DepartData) => {
      if (department.attributes.branch_id == value.id)
        this.filterDepartments.push(department);
    });
    // console.log(this.roles);
  } // branch Selected

  departmentSelected(value: DepartData) {
    console.log("DepartID", value);

    this.form.basic.title = undefined;
    this.filterTitles = [];
    console.log(this.titles);

    this.titles.forEach((title: TitleData) => {
      if (title.attributes.department_id == value.id) {
        console.log(title);
        this.filterTitles.push(title);
      }
    });
    // console.log(this.roles);
  } // department selected

  getName(item: UserData): string {
    // console.log(item);
    return item.attributes.first_name + " " + item.attributes.last_name;
  }

  // openDialog(): void {
  //   console.log("Users");
  //   // this.dialog = this.status;
  // }

  async addUser() {
    // this.$refs.form.validate();

    if (this.restrict <= 3 && this.form.valid) {
      let form = this.form;
      const leaves: {
        leave_type_id: number;
        total_days: number;
      }[] = [];
      this.leaveTypes.forEach((type) => {
        leaves.push({
          leave_type_id: parseInt(type.id),
          total_days: parseInt(form.leave.types["leave" + type.id]),
        });
      });
      // console.log(leaves);

      const data = {
        first_name: form.basic.fName,
        last_name: form.basic.lName,
        email: form.basic.email.trim(),
        branch_id: form.basic.branch ? form.basic.branch.id : "",
        branch_name: form.basic.branch ? form.basic.branch.attributes.name : "",
        department_id: form.basic.department ? form.basic.department.id : "",
        department_name: form.basic.department
          ? form.basic.department.attributes.name
          : "",
        employee_title_id: form.basic.title ? form.basic.title.id : "",
        employee_title_name: form.basic.title
          ? form.basic.title.attributes.name
          : "",
        reporting_manager_id: form.basic.manager ? form.basic.manager.id : "",
        reporting_manager_name: form.basic.manager
          ? form.basic.manager.attributes.first_name +
            " " +
            form.basic.manager.attributes.last_name
          : "",
        role: form.basic.roles,
        can_take_vacation: form.leave.canTakeVacation == "1",
        can_take_sick_leave: form.leave.canTakeSickleave == "1",
        vacation_start_date:
          form.leave.canTakeVacation == "1"
            ? form.leave.vacation_start_date
            : "",
        is_default_manager: false,
        salary: parseInt(form.basic.salary.trim()),
        start_date: form.basic.start_date,
        assigned_leave: leaves,
        employment_type_id: form.basic.employment_type_id,
      };
      // console.log(data, JSON.stringify(data));
      axios
        .post("/onboard", data)
        .then((response) => {
          this.errors = [];
          this.dialog = false;
          // console.log(response.data);

          this.$store.dispatch("Add_Snackbar", {
            timeout: 4000,
            text: response.data.message,
            color: "green darken-2",
          });
        })
        .catch((error) => {
          this.onError(error, "scroll-dialogs");
        });
    }
    // console.log("Add User", this.form);
  }

  reset() {
    (this.$refs.form as HTMLFormElement).reset();
  }

  closeResponse(response: boolean) {
    this.reset();
    this.dialog = false;
    this.$emit("hideDialog", response);
  }

  private onError(error, scroll: string): void {
    this.errors = errorResponse(error, this.$store);
    const myElement: HTMLElement | null = document.getElementById(scroll);
    if (myElement != null) myElement.scrollIntoView();
  }

  mounted() {
    // console.log("xData", this.xData);
    this.getEmploymentTypes();
    this.getRoles();
    this.getLeaveTypes();
    this.restrict = this.xData.restrict;
    this.companyID = this.xData.companyID;
    this.userID = this.xData.user_id;
    this.branches = this.xData.branches;
    this.titles = this.xData.titles;
    this.departments = this.xData.departments;
    this.managers = this.xData.managers;
    // const userID = localStorage.getItem("userID");
    // this.openDialog();s
  }
} //end Dashboard Dialog
