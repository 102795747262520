var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-5 emergency-section"},[_c('div',{staticClass:"d-flex"},[_c('h6',{staticClass:"title"},[_vm._v(_vm._s(_vm.name))])]),_c('v-row',[_c('v-col',{attrs:{"col":"4"}},[_c('v-text-field',{attrs:{"label":"First Name","rules":[_vm.rules.required],"value":_vm.componentContact.first_name},on:{"input":function($event){_vm.componentContact.first_name = $event;
          _vm.$emit('updateContact', _vm.componentContact);}}})],1),_c('v-col',{attrs:{"col":"4"}},[_c('v-text-field',{attrs:{"label":"Last Name","rules":[_vm.rules.required],"value":_vm.componentContact.last_name},on:{"input":function($event){_vm.componentContact.last_name = $event;
          _vm.$emit('updateContact', _vm.componentContact);}}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-autocomplete',{attrs:{"value":_vm.componentContact.relationship,"items":_vm.relationships,"label":"Relationship","rules":[_vm.rules.required]},on:{"input":function($event){_vm.componentContact.relationship = $event;
          _vm.$emit('updateContact', _vm.componentContact);}}})],1)],1),_c('PhoneInput',{attrs:{"countries":_vm.countries,"inputCode":_vm.componentContact.phone_number_country_id,"inputNumber":_vm.componentContact.phone_number},on:{"inputCode":function($event){_vm.componentContact.phone_number_country_id = $event;
      _vm.$emit('updateContact', _vm.componentContact);},"inputNumber":function($event){_vm.componentContact.phone_number = $event;
      _vm.$emit('updateContact', _vm.componentContact);}}}),_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"value":_vm.componentContact.phone_type,"items":_vm.phoneTypes,"label":"Phone Type","rules":[_vm.rules.required]},on:{"input":function($event){_vm.componentContact.phone_type = $event;
          _vm.$emit('updateContact', _vm.componentContact);}}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Email (optional)","value":_vm.componentContact.email,"rules":[_vm.rules.email]},on:{"input":function($event){_vm.componentContact.email = $event;
          _vm.$emit('updateContact', _vm.componentContact);}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }