













































































































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import ComponentXImage from "@/components/reusable/XImage.vue";

// Define the props by using Vue's canonical way.
const AddData = Vue.extend({
  props: {
    xData: Object,
  },
});

@Component({
  components: {
    XImage: ComponentXImage,
  },
})
export default class ComponentNewEmployee extends AddData {
  public dialog = false;
  public fullscreen = this.xData.fullscreen;
  public employeeData = this.xData.employeeData;
  public restrict = this.xData.restrict;
  public newEmp = this.xData.newEmp;
  public userID = this.xData.userID;

  toggleDialog(): void {
    this.dialog = !this.dialog;
  }
}
