


































































import Vue from "vue";
import componentHeader from "./components/header/component-header.vue";

import "./services/BaseConfig";
import Component from "vue-class-component";

@Component({
  components: {
    componentHeader,
  },
})
export default class App extends Vue {
  public get snackbar$(): boolean {
    return this.$store.getters["getSnackbar"];
  }
  public get snackbarData$(): { text: string; timeout: number; color: string } {
    return this.$store.getters["getSnackbarData"];
  }

  public snackbarReset() {
    this.$store.dispatch("snackbarReset");
  }
}
