




















































































































































































































































































































































































































// import { defineComponent } from "vue";
import Vue from "vue";
import Component from "vue-class-component";
import axios from "axios";

import ComponentXImage from "@/components/reusable/XImage.vue";
import UserAttributes from "@/types/UserAttributes";
import CompanyData from "@/types/CompanyData";
import UserData from "@/types/UserData";
import errorResponse from "@/services/errorCode";

@Component({
  components: {
    XImage: ComponentXImage,
  },
})
export default class CompanyView extends Vue {
  // private screenWidth = window.innerWidth;
  public mobileView: boolean = window.innerWidth <= 1000;
  public get restrict$(): number {
    return this.$store.getters["getRestricted"];
  }
  public get user$(): UserAttributes {
    return this.$store.getters["getSelfAttributes"];
  }
  public get isLoggedIn$(): boolean {
    return this.$store.getters["isAuthenticated"];
  }
  public get userID$(): string {
    return this.$store.getters["getUserID"];
  }

  profile: UserAttributes = this.user$;
  // fixedReady = false;
  isLoading$ = true;
  financial_menu = false;

  public setFinancialYear(date) {
    this.form.business_start_date = date;
    console.log(this.form);
  }

  preview = false;
  editMode = false;
  submitLoading = false;
  file: File | null = null;
  form: {
    valid: boolean;
    imgSrc: string | ArrayBuffer | null;
    img: string | File | null;
    name: string;
    phone: string;
    address_line_1: string;
    address_line_2: string;
    billing_email: string;
    weekends: number;
    job_letter_signatory: string | null;
    business_start_date: string | null;
    financial_year: Array<string> | null;
  } = {
    valid: false,
    imgSrc: "",
    img: null,
    name: "",
    phone: "",
    address_line_1: "",
    address_line_2: "",
    billing_email: "",
    weekends: 0,
    job_letter_signatory: "",
    business_start_date: null,
    financial_year: null,
  };

  errors: string[] = [];
  companyID = "0";
  user_id = "-3";
  managers: UserData[] = [];

  public emptyRules = (v) => !!v || "Required";

  public min6 = (value) =>
    value.length >= 6 ? true : "Password should have more than 6 characters.";

  public get isEmptyForm(): boolean {
    return !!this.form && !!this.form;
  }

  snackbar: { show: boolean; timeout: number; text: string } = {
    show: false,
    timeout: 2000,
    text: "",
  };

  showSnackbar(text: string) {
    this.snackbar = { show: true, timeout: 2000, text: text };
  }

  getName(item: UserData): string {
    // console.log(item);
    return item.attributes.first_name + " " + item.attributes.last_name;
  }

  onSelectFile(file: File) {
    if (!file) {
      return;
    }
    this.createImage(file);
  }

  createImage(file: File) {
    this.preview = true;
    const reader = new FileReader();
    reader.onload = (e) => {
      this.form.imgSrc = e.target!.result;
      console.log(e.target!.result);
    };
    reader.readAsDataURL(file);
  }

  async submit() {
    console.log(this.form);
    this.submitLoading = true;
    const data = new FormData();
    if (this.file != null) {
      data.append("logo", this.file);
    }

    data.append("phone", this.form.phone);
    data.append("address_line_1", this.form.address_line_1);
    data.append("address_line_2", this.form.address_line_2);
    data.append("billing_email", this.form.billing_email);
    if (this.form.business_start_date != null)
      data.append("business_start_date", this.form.business_start_date);
    if (this.form.job_letter_signatory != null)
      data.append("job_letter_signatory", this.form.job_letter_signatory);

    await axios
      .post("/company", data)
      .then(async (response) => {
        console.log(response.data);
        this.errors = [];
        this.$store.dispatch("Add_Snackbar", {
          timeout: 6000,
          text: response.data.message,
          color: "green darken-2",
        });
        // this.getCompany();
        // this.preview = false;
        // this.editMode = false;
        // this.submitLoading = false;
        location.reload();
      })
      .catch((error) => {
        this.submitLoading = false;
        this.onError(error, "scroll-page");
      }); // getall users
  }

  private async getCompany() {
    await axios.get("/company").then(async (response) => {
      console.log(response.data.data);
      const company: CompanyData = response.data.data;
      this.form = {
        valid: false,
        imgSrc: company.attributes.logo,
        img: "",
        weekends: company.attributes.weekends,
        job_letter_signatory: company.attributes.job_letter_signatory,
        name: company.attributes.name,
        phone: company.attributes.phone,
        address_line_1: company.attributes.address_line_1,
        address_line_2: company.attributes.address_line_2,
        billing_email: company.attributes.billing_email,
        business_start_date: company.attributes.business_start_date,
        financial_year: company.attributes.financial_year,
      };
      console.log(this.form);

      await axios
        .get("/users?filter[roles]=admin&paginate=false")
        .then(async (response) => {
          this.managers = response.data.data;
          this.isLoading$ = false;
        }); // get every manager
    }); // getall users
  } //getuser_by_id

  private onError(error, scroll: string): void {
    this.errors = errorResponse(error, this.$store);
    const myElement: HTMLElement | null = document.getElementById(scroll);
    if (myElement != null) myElement.scrollIntoView();
  }

  mounted() {
    this.$store.dispatch("commitBASE");
    this.isLoading$ = true;
    // const userID = localStorage.getItem("userID");
    this.getCompany();
    // this.openDialog();
  }
} //end Dashboard Dialog
