import { render, staticRenderFns } from "./DashboardView.vue?vue&type=template&id=82df55a6&scoped=true&"
import script from "./DashboardView.vue?vue&type=script&lang=ts&"
export * from "./DashboardView.vue?vue&type=script&lang=ts&"
import style0 from "./DashboardView.vue?vue&type=style&index=0&id=82df55a6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82df55a6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VAvatar,VProgressCircular})
