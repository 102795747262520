

















































































































































































































































































































































































// import { defineComponent } from "vue";
import UserData from "@/types/UserData";
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";
import ComponentViewClash from "@/components/body/leave/dialog-view-clash.vue";
const Data = Vue.extend({
  props: {
    leaveData: { type: Object, required: false },
    restrict: Number,
    leaveId: {
      type: String,
      required: false,
    },
    autoload: {
      type: Boolean,
      default: false,
    },
  },
});

import ComponentRangedDates from "@/components/reusable/DateRangePicker.vue";
import errorResponse from "@/services/errorCode";
import { saveAs } from "file-saver";
import { Watch } from "vue-property-decorator";
import { LeaveAttributes } from "../../../types/LeaveRequestData";

@Component({
  components: {
    RangedDates: ComponentRangedDates,
    DialogViewClash: ComponentViewClash,
  },
})
export default class ComponentReviewLeave extends Data {
  public dialog = false;
  public ready$ = false;
  public loading = true;
  public users: UserData[] = [];
  public errors: string[] = [];
  public componentLeaveID = "";
  public leave: LeaveAttributes = {
    id: 0,
    title: "",
    description: "",
    leave_status: {
      id: 0,
      status: "",
    },
    leave_type: {
      id: 0,
      type: "",
      color: "",
    },
    user: {
      id: "",
      name: "",
    },
    leaves: [],
    clashes: [],
    comments: "",
    created_at: "",
    updated_at: "",
    media: "",
    total_days: 0,
  };
  public form: {
    title: string;
    desc: string;
    comment: string;
  } = {
    desc: "",
    title: "",
    comment: "",
  };

  public leaves: {
    start_date: string;
    end_date: string;
    number_days: number;
  }[] = [];
  counter = 1;
  public emptyRules = (v) => !!v || "Required";

  clearForm() {
    this.dialog = false;
  }

  approval(state: number) {
    console.log(this.leave);
    const data = {
      approve: state,
      comment: this.form.comment,
    };
    // console.log(data);

    axios
      .patch(`/leave-requests/${this.componentLeaveID}/approval`, data)
      .then((response) => {
        // console.log(response);
        this.clearForm();
        this.$emit("reviewleave", {
          reload: response.data.success,
          text: response.data.message,
        });
      })
      .catch((error) => {
        this.onError(error, "scroll-dialogs");
      });
  }

  openMedia() {
    axios
      .get(this.leave.media, {
        responseType: "blob",
      })
      .then((response) => {
        let contentType = response.headers["content-type"];
        let filename = this.getFileNameFromUrl(this.leave.media);
        var file = new File([response.data], filename!, {
          type: contentType,
        });
        saveAs(file);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getLeave(id: string) {
    axios
      .get("/leave-requests/" + id)
      .then((response) => {
        this.loading = false;
        let leaveData = response.data.data;
        this.leave = leaveData.attributes;
        this.leaves = leaveData.attributes.leaves;
      })
      .catch((error) => {
        this.loading = false;
        this.dialog = false;
        this.onError(error, "scroll-dialogs");
      });
  }

  getFileNameFromUrl(url: string) {
    const segments = url.split("/");
    const fileName = segments.pop();
    return fileName;
  }

  private onError(error, scroll: string): void {
    this.errors = errorResponse(error, this.$store);
    const myElement: HTMLElement | null = document.getElementById(scroll);
    if (myElement != null) myElement.scrollIntoView();
  }

  mounted() {
    if (this.leaveData != undefined) {
      this.componentLeaveID = this.leaveData.id;
      this.leave = this.leaveData.attributes;
      this.leaves = this.leaveData.attributes.leaves;
    }
    this.form.title = this.leave.title;
    this.form.desc = this.leave.description;
    this.form.comment = this.leave.comments;
    this.dialog = this.autoload;
  }
  @Watch("dialog")
  onPropertyChanged(value: boolean, oldValue: boolean) {
    if (value) {
      if (this.leaveId != null) {
        this.componentLeaveID = this.leaveId;
        this.getLeave(this.leaveId);
      } else {
        this.loading = false;
      }
    }
  }
}
