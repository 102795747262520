




















































































// import { defineComponent } from "vue";
import Vue from "vue";
import Component from "vue-class-component";

const Props = Vue.extend({
  props: {
    setmode: Number,
    title: String,
    namebtn: String,
    istextbtn: Boolean,
  },
});

@Component
export default class ComponentDeleteConfirm extends Props {
  public dialog = false;
  mode: {
    tooltip: string;
    confirm: string;
    title: string;
    icon: string;
    color: string;
  } = {
    tooltip: "Delete this item",
    confirm: "Delete",
    title: "Are you sure you want to delete this item?",
    icon: "trash",
    color: "red",
  };
  setMode(num: number) {
    switch (num) {
      case 0:
        this.mode = {
          tooltip: "Deactivate this user",
          confirm: "Deactivate",
          title: "deactivate this user",
          icon: "ban",
          color: "red",
        };
        break;

      case 1:
        this.mode = {
          tooltip: "Delete this leave",
          confirm: "Delete",
          title: "delete this leave",
          icon: "trash",
          color: "red",
        };
        break;

      case 2:
        this.mode = {
          tooltip: "Delete this merit",
          confirm: "Delete",
          title: "delete this merit",
          icon: "trash",
          color: "red",
        };
        break;

      case 3:
        this.mode = {
          tooltip: "Delete this holiday",
          confirm: "Delete",
          title: "delete this holiday",
          icon: "trash",
          color: "red",
        };
        break;

      default:
        this.mode = {
          tooltip: "Delete this item",
          confirm: "Delete",
          title: "this item",
          icon: "trash",
          color: "red",
        };
        break;
    }
  }
  closeDelete(response: boolean) {
    this.dialog = false;
    this.$emit("delete", response);
  }

  mounted() {
    console.log(this.title);
    this.setMode(this.setmode);
    if (this.title != undefined) this.mode.title = this.title;
    if (this.namebtn != undefined) this.mode.confirm = this.namebtn;
  }
}
