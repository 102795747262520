






















































































































































































































































































































































































































































































































































































































































































































































































































































// import { defineComponent } from "vue";
import Vue from "vue";
import Component from "vue-class-component";
import axios from "axios";
import UserMeritsData from "@/types/UserMeritsData";
import MeritsData from "@/types/MeritsData";
import UserAttributes from "@/types/UserAttributes";
import { RemainingLeaveBalance } from "@/types/RemainingLeaveBalance";
import ComponentDeleteConfirm from "@/components/reusable/DeleteConfirm.vue";
import ComponentViewMerit from "@/components/body/merits/dialog-view-merit.vue";
import ComponentExport from "@/components/reusable/dialog-export.vue";
import ComponentExportSummary from "@/components/body/export/dialog-export-summary.vue";
import ComponentAddMerit from "@/components/body/merits/dialog-add-merit.vue";
import errorResponse from "@/services/errorCode";
import ComponentEditMerit from "../../components/body/merits/dialog-edit-merit.vue";

@Component({
  components: {
    DialogDelete: ComponentDeleteConfirm,
    DialogViewMerit: ComponentViewMerit,
    DialogExport: ComponentExport,
    DialogExportSummary: ComponentExportSummary,
    DialogAddMerit: ComponentAddMerit,
    DialogEditMerit: ComponentEditMerit,
  },
})
export default class MeritView extends Vue {
  // private screenWidth = window.innerWidth;
  public mobileView: boolean = window.innerWidth <= 1000;

  public get restrict$(): number {
    return this.$store.getters["getRestricted"];
  }
  public get getUserID$(): string {
    return this.$store.getters["getUserID"];
  }
  public get getSelfAttributes$(): UserAttributes {
    return this.$store.getters["getRestricted"];
  }
  private get role$(): boolean {
    return this.$store.getters["getRole"];
  }
  public errors: string[] = [];
  public uuid: string | null = null;
  meritTab = false;
  sortByTab = 0;
  fixedReady = false;
  isLoading$ = true;
  totalDaysTaken = 0;
  leaveType: {
    id: string;
    color: string;
    type: string;
    updated_at: string;
    total_days?: number | undefined;
  }[] = [];
  userLeaveBal: RemainingLeaveBalance[] = [];
  companyID = "0";
  user_id = "";
  blockedOutDates = [];

  currentPage = 1;
  lastPage = 1;
  allMeritCurrentPage = 1;
  allMeritLastPage = 1;

  userOnPaginateClick(page: number) {
    this.currentPage = page;
    this.userMerits("-created_at", this.filterControls.search.data);
  }

  allMeritOnPaginateClick(page: number) {
    this.allMeritCurrentPage = page;
    this.allMerits("-created_at", this.filterControls.search.data);
  }

  //all users **
  public oUserMerit: UserMeritsData[] = [];
  public oAllMerit: UserMeritsData[] = [];
  // userLeave: LeaveRequestData[] = [];
  // allLeave: LeaveRequestData[] = [];

  //userData
  startDate = "";
  canTakeVacation = false; //0:false | 1:true
  canTakeSickLeave = false; //0:false | 1:true

  form = {};

  meritTypes: MeritsData[] = [];
  // range = "";
  // currentRange: { startDate: Date; endDate: Date };
  public getLeaveType = (id) => {
    const v = this.meritTypes.find((v) => v.id == id);
    return v == undefined ? { attributes: { name: "", color: "000000" } } : v;
  };
  fileURL;
  public fileType;
  public fileReady = false;

  filterControls = {
    search: { data: "", status: false },
    title: { data: [], status: false },
  };

  dialogs = {
    addUser: { data: {}, status: false },
    editUser: { data: [], status: false },
    viewProfile: { data: [], status: false },
    attachments: { data: [], status: false },
    confimationDialog: { data: [], status: false },
  };

  showFilters = false;

  email = [
    (value) => !!value || "Required.",
    (value) => (value || "").length <= 20 || "Max 20 characters",
    (value) => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail.";
    },
  ];
  // openDialog(): void {
  //   console.log("Users");
  // }
  reload() {
    console.log("reload");
    this.getuser_by_id();
  }

  private async getuser_by_id() {
    // console.log("user");
    this.userMerits("-created_at", "");
    this.allMerits("-created_at", "");
  } //getuser_by_id

  private async userMerits(sort: string, filterName: string) {
    this.isLoading$ = true;
    await axios
      .get(
        `/user-merits?role=user&sort=${sort}&filter[name]=${filterName}&page=${this.currentPage}`
      )
      .then(async (response) => {
        // console.log(response.data.data, "UsersLo");
        this.oUserMerit = response.data.data;
        this.currentPage = response.data.meta.current_page;
        this.lastPage = response.data.meta.last_page;

        await axios.get("/merits").then(async (response) => {
          console.log(response.data.data);
          this.meritTypes = response.data.data;
          this.isLoading$ = false;
          // this.titles.sort((a, b) => a.Title.localeCompare(b.Title));
          // if(this.restrict$<=3){

          // }
        }); //get_all_leavetype
      })
      .catch((error) => {
        console.log(error.response);
        if (!error.response.request.withCredentials) {
          // localStorage.removeItem("user");
          // this.$store.dispatch("Logout");
          if (error.response.data == undefined) {
            this.$store.dispatch("Add_Snackbar", {
              timeout: -1,
              text: "Base response: " + error.response,
              color: "red darken-2",
            });
          } else {
            this.$store.dispatch("Add_Snackbar", {
              timeout: -1,
              text: error.response.data.message,
              color: "red darken-2",
            });
          }
          localStorage.removeItem("user");
          this.$store.dispatch("Logout");

          // this.$store.dispatch;
        } else {
          this.$store.dispatch("TokenReset");
          // this.$router.push("/");
        }
      }); // get_my_leaverequests users
  }
  private async allMerits(sort: string, filterName: string) {
    if (this.restrict$ <= 3) {
      console.log("here");
      await axios
        .get(
          `/user-merits?role=${this.role$}&sort=${sort}&filter[name]=${filterName}&page=${this.allMeritCurrentPage}`
        )
        .then((response) => {
          console.log(
            "get_all_remaining_leave",
            this.role$,
            response.data.data
          );
          this.oAllMerit = response.data.data;
          this.allMeritCurrentPage = response.data.meta.current_page;
          this.allMeritLastPage = response.data.meta.last_page;
        });
    }
  }

  //Merits Tab
  meritTabSwitch(value: boolean) {
    this.meritTab = value;
    // this.sortBySwitcher(this.sortByTab);
  }

  //Tabs
  sortBySwitcher(x: number) {
    // this.paginator.firstPage();
    this.sortByTab = x;
  }

  deactivate(response: boolean, userMeritId: string) {
    // console.log(response, user_id);
    if (response) {
      axios
        .delete(`/user-merits/${userMeritId}`)
        .then((response) => {
          // console.log(response);
          this.errors = [];
          this.getuser_by_id;
          this.$store.dispatch("Add_Snackbar", {
            timeout: 3500,
            text: response.data.message,
            color: "green darken-2",
          });
        })
        .catch((error) => {
          this.onError(error, "scroll-page");
        });
    } else {
      this.$store.dispatch("Add_Snackbar", {
        timeout: 3500,
        text: "Request Canceled",
        color: "orange darken-2",
      });
    }
  }

  fileExport() {
    console.log("export");
  }
  reviewMerit(leave) {
    console.log("remerit", leave);
  }

  clearSearch() {
    this.filterControls.search.data = "";
    this.searchFilter();
    console.log("cleareearch");
  }

  clearTitles() {
    this.filterControls.title.data = [];
    this.titleFilter();
    console.log("cleartitles");
  }

  searchFilter() {
    this.filterControls.search.status =
      this.filterControls.search.data.length > 0;
  }
  titleFilter() {
    this.filterControls.title.status =
      this.filterControls.title.data.length > 0;
    console.log(this.filterControls.title.data);
  }

  dialogClose(response: boolean, dialogType: string) {
    console.log(response, dialogType, this.dialogs[dialogType]);
    this.dialogs[dialogType].status = response;
  }

  addMerit(response) {
    if (response.status) {
      // this.$router.go(0);
      this.$store.dispatch("Add_Snackbar", {
        timeout: 3500,
        text: response.message,
        color: "green darken-2",
      });
      this.getuser_by_id();
    } else {
      this.$store.dispatch("Add_Snackbar", {
        timeout: 5000,
        text: response.message,
        color: "red darken-2",
      });
    }
    // console.log(response);
  }

  public async applyFilter() {
    if (this.meritTab) {
      this.allMeritCurrentPage = 1;
      this.allMeritLastPage = 1;
      await this.allMerits("", this.filterControls.search.data);
    } else {
      this.currentPage = 1;
      this.lastPage = 1;
      await this.userMerits("", this.filterControls.search.data);
    }
    this.showFilters = false;
  }

  public async clearFilter() {
    this.filterControls.search.data = "";
    if (this.meritTab) {
      await this.allMerits("", this.filterControls.search.data);
    } else {
      await this.userMerits("", this.filterControls.search.data);
    }
    this.showFilters = false;
  }

  private onError(error, scroll: string): void {
    this.errors = errorResponse(error, this.$store);
    const myElement: HTMLElement | null = document.getElementById(scroll);
    if (myElement != null) myElement.scrollIntoView();
  }

  mounted() {
    this.$store.dispatch("commitBASE");
    this.user_id = this.getUserID$;
    this.startDate = this.getSelfAttributes$.start_date;
    this.getuser_by_id();
    let meritID = this.$router.currentRoute.params.id;
    // ID in path
    if (meritID != undefined) {
      //open dialog to load merit
      this.uuid = meritID;
    }
  }
} //end Dashboard Dialog
