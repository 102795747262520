// //store/modules/auth.js
// import axios from "axios";
// import router from "@/router";
// import UserData from "@/types/UserData";
const state = {
  basic: {},
  user: {},
  url: {},
};

const getters = {
  // isAuthenticated: (state) => !!state.basic.token,
};

const actions = {
  //   async Register({ dispatch }, form) {
  //     await axios.post("register", form);
  //     const UserForm = new FormData();
  //     UserForm.append("email", form.email);
  //     UserForm.append("password", form.password);
  //     await dispatch("Login", UserForm);
  //   },
};
const mutations = {
  setUser(state, user) {
    state.basic.token = user.token;
    state.basic.user_uuid = user.user_id;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
