


















































































































































import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";
import PersonalityAnswerData from "@/types/pAnswersData";
import errorResponse from "@/services/errorCode";

const Props = Vue.extend({
  props: {
    userID: String,
  },
});

@Component
export default class ComponentJobLetter extends Props {
  public dialog = false;
  public ready$ = false;
  public personalityAnswers: PersonalityAnswerData[] = [];
  public form = {};
  errors: string[] = [];
  disableEdit = true;
  emptyRules = [(v) => !!v || "Required"];

  public get isEmptyForm(): boolean {
    return !!this.form;
  }

  clearForm() {
    this.form = {};
    this.dialog = false;
    this.disableEdit = true;
    this.getPQ(this.userID);
    this.errors = [];
  }

  closeDelete() {
    this.clearForm();
    this.$emit("rolechange", "Request Canceled");
  }
  getPQ(userID) {
    axios
      .get(`/user-personality-answers/${userID}`)
      .then((response) => {
        // this.clearForm();
        // console.log(response.data);
        this.personalityAnswers = response.data.data;
        this.personalityAnswers.forEach((element) => {
          this.form["pq" + element.id] = element.attributes.answer;
        });
      })
      .catch((error) => {
        // console.log(error.response);
        this.$emit("personalityquestions", {
          status: true,
          message: "Error: " + error.response.data.message,
        });
      });
  }

  add() {
    var answers: { question_id; answer }[] = [];
    this.personalityAnswers.forEach((element) => {
      answers.push({
        question_id: element.attributes.question_id,
        answer: this.form["pq" + element.id],
      });
    });
    const data = { answers: answers };
    console.log(answers);

    // const data: FormData = new FormData();
    // data.append("answers", JSON.stringify({ answers: answers }));
    // console.log(data.get("answers"));
    // data.append("recipient_name", this.form.recipentN);
    // data.append("recipient_organization", this.form.recipentO);
    // data.append("recipient_address", this.form.recipentA);
    axios
      .post(`/user-personality-answers/${this.userID}`, data)
      .then((response) => {
        this.clearForm();
        // console.log(response);
        this.$store.dispatch("Add_Snackbar", {
          timeout: 6000,
          text: response.data.message,
          color: "green darken-2",
        });
      })
      .catch((error) => {
        this.onError(error, "scroll-dialogs");
      });
  }
  private onError(error, scroll: string): void {
    this.errors = errorResponse(error, this.$store);
    const myElement: HTMLElement | null = document.getElementById(scroll);
    if (myElement != null) myElement.scrollIntoView();
  }
  mounted() {
    this.getPQ(this.userID);
  }
}
