































































































import Vue from "vue";
import Component from "vue-class-component";
import axios from "axios";
import DepartData from "../../../types/DepartData";
import CountryData from "../../../types/CountryData";
import CompanyManageDialog from "./dialog-manage.vue";
import CompanyDeleteDialog from "./dialog-delete.vue";
const Props = Vue.extend({
  props: {
    id: String,
    branchName: String,
  },
});
@Component({
  components: {
    CompanyManageDialog: CompanyManageDialog,
    CompanyDeleteDialog: CompanyDeleteDialog,
  },
})
export default class PanelDepartments extends Props {
  path = "/departments";
  panelTitle = "Departments";
  singleTitle = "Department";
  isLoading = true;
  items: Array<DepartData> = [];
  countries: Array<CountryData> = [];
  snackbar: { show: boolean; timeout: number; text: string } = {
    show: false,
    timeout: 2000,
    text: "",
  };
  showSnackbar(text: string) {
    this.snackbar = { show: true, timeout: 2000, text: text };
  }

  public reload() {
    console.log("updated");
    this.isLoading = true;
    this.getItems();
  }

  public back() {
    this.$emit("back");
  }
  public tileClick(item: DepartData) {
    this.$emit("triggerDepartment", {
      id: item.id,
      name: item.attributes.name,
    });
  }

  // getCountries() {
  //   axios
  //     .get("/countries")
  //     .then((response) => {
  //       this.countries = response.data.data;
  //       console.log(this.countries);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  private async getItems() {
    await axios
      .get(this.path + "?sort=name&paginate=false&filter[branch_id]=" + this.id)
      .then((response) => {
        this.items = response.data.data;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.isLoading = false;
        console.log(this.items);
      });
  }
  private async;
  mounted() {
    this.$store.dispatch("commitBASE");
    console.log(this.id);
    // this.getCountries();
    this.getItems();
  }
}
