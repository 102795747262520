
































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































// import { defineComponent } from "vue";
import Vue from "vue";
import Component from "vue-class-component";
import axios from "axios";
import UserData from "@/types/UserData";
import TitleData from "@/types/TitleData";
import BranchData from "@/types/BranchData";
import DepartData from "@/types/DepartData";

import ComponentXImage from "@/components/reusable/XImage.vue";
import ComponentUserAdd from "../../components/body/user/dialog-user-add.vue";
import ComponentUserProfile from "@/components/body/user/dialog-user-profile.vue";
import ComponentDeleteConfirm from "@/components/reusable/DeleteConfirm.vue";
import ComponentExport from "@/components/reusable/dialog-export.vue";
import ComponentChangeRole from "@/components/body/user/dialog-change-role.vue";
import ComponentAttachments from "@/components/body/user/dialog-attachments.vue";
import UserAttributes from "@/types/UserAttributes";
import errorResponse from "@/services/errorCode";

@Component({
  components: {
    "dialog-user-add": ComponentUserAdd,
    DialogUserProfile: ComponentUserProfile,
    DialogDelete: ComponentDeleteConfirm,
    DialogExport: ComponentExport,
    DialogRole: ComponentChangeRole,
    DialogAttachments: ComponentAttachments,
    XImage: ComponentXImage,
  },
})
export default class UserView extends Vue {
  // private screenWidth = window.innerWidth;
  public get mobileView(): boolean {
    return window.innerWidth <= 1000;
  }
  public get restrict$(): number {
    return this.$store.getters["getRestricted"];
    // return 3;
  }
  public get user$(): UserAttributes {
    return this.$store.getters["getSelfAttributes"];
  }
  public get isLoggedIn$(): boolean {
    return this.$store.getters["isAuthenticated"];
  }
  public get userID$(): string {
    return this.$store.getters["getUserID"];
  }
  public errors: string[] = [];
  isLoading$ = true;
  addUserReady = 0;
  companyID = "0";
  user_id = "-3";
  currentPage = 1;
  lastPage = 1;

  sortByTab = 0;
  filterControls = {
    search: { data: "", status: false },
    title: { data: [], status: false },
  };

  dialogs = {
    addUser: { data: {}, status: false },
    editUser: { data: [], status: false },
    viewProfile: { data: {}, status: false },
    attachments: { data: [], status: false },
    confimationDialog: { data: "", status: false },
  };

  snackbar: { show: boolean; timeout: number; text: string } = {
    show: false,
    timeout: 2000,
    text: "",
  };

  showSnackbar(data: { show: boolean; timeout: number; text: string }) {
    this.snackbar = data;
  }

  onPaginateClick(page: number) {
    this.currentPage = page;
    this.getuser_by_id(this.filterControls.search.data);
  }

  public get deleteDialog$(): boolean {
    return this.dialogs.confimationDialog.status;
  }
  // searchControl = "";
  // searchActive = false;
  // titleControl = [];
  // titleActive = false;
  length!: number;
  allUserData: UserData[] = [];
  originalUD: UserData[] = [];
  pSortedUser: {
    Title:
      | {
          id: number;
          attributes: {
            name: string;
          };
        }
      | BranchData
      | TitleData;
    users: UserData[];
  }[] = [];
  private sortedUsers: [
    {
      Title: { id: number; attributes: { name: string } };
      users: UserData[];
    }[],
    { Title: TitleData; users: UserData[] }[],
    { Title: BranchData; users: UserData[] }[]
  ] = [[], [], []];
  private allSortedUsers: [UserData[], UserData[], UserData[]] = [[], [], []];

  private letters: string[] = [];
  public branches: BranchData[] = [];
  public titles: TitleData[] = [];
  public departments: DepartData[] = [];
  public managers: UserData[] = [];
  public roles = [];
  showFilters = false;
  showReload = false;
  email = [
    (value) => !!value || "Required.",
    (value) => (value || "").length <= 20 || "Max 20 characters",
    (value) => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail.";
    },
  ];
  // openDialog(): void {
  //   console.log("Users");
  // }
  private async getUsers(
    sortBy = "",
    filterBy = { type: "name", value: "" },
    role = ""
  ) {
    await axios
      .get(
        `/users?role=${role}&sort=${sortBy}&filter[${filterBy.type}]=${filterBy.value}`
      )
      .then((response) => {
        // console.log("Users", response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error.response.request);
        console.log(error.response.status);
        if (!error.response.request.withCredentials) {
          // localStorage.removeItem("user");
          // this.$store.dispatch("Logout");
          this.$store.dispatch("Add_Snackbar", {
            timeout: -1,
            text: "User was logged out due to expired token",
            color: "red darken-2",
          });
        }
      });
  }

  private async getuser_by_id(name: string) {
    this.isLoading$ = true;
    this.showReload = false;
    // console.log("START");
    // this.restrict = Number(user.roleID);
    // this.companyID = x.data.attributes.;
    // const countryID = x.data.CountryID;

    // console.log(x);

    await axios
      .get(`/users?page=${this.currentPage}&filter[name]=${name}`)
      .then(async (response) => {
        // console.log(response);

        this.allUserData = response.data.data;

        this.originalUD = response.data.data;
        this.length = this.allUserData.length;
        var letters: string[] = [];
        this.currentPage = response.data.meta.current_page;
        this.lastPage = response.data.meta.last_page;

        console.log(response.data);

        // this.allUserData.sort((a, b) => {
        //   return a..attributes.first_name.localeCompare(b.attributes.first_name);
        // });

        //For each user, find first letter
        //this caters for letters in different languages
        this.allUserData.forEach((user) => {
          const letter = user.attributes.first_name.charAt(0);

          //if letter hasn't been added, then add
          if (letters.indexOf(letter) === -1) letters.push(letter);
        });

        //set global letters to letter
        this.letters = letters.sort();

        await axios
          .get("/employee-titles?paginate=false")
          .then(async (response) => {
            console.log("EMPLOYEE TITLES: ", response.data.data);
            this.titles = response.data.data;

            // this.titles.sort((a, b) =>
            //   a.attributes.name.localeCompare(b.attributes.name)
            // );
            await axios.get("/branches").then((response) => {
              // console.log("get_all_branches", response.data);
              this.branches = response.data.data;
              // this.branches.sort((a, b) =>
              //   a.attributes.name.localeCompare(b.attributes.name)
              // );
              this.allSortedUsers = [
                this.SortArrObjs(this.allUserData, "first_name", "last_name"),
                this.SortArrObjs(
                  this.allUserData,
                  "employee_title_name",
                  "first_name"
                ),
                this.SortArrObjs(this.allUserData, "branch_name", "first_name"),
              ];

              console.log(this.allSortedUsers);

              this.forUserSort(
                this.sortAll([...this.allSortedUsers[this.sortByTab]])
              );

              // console.log(this.pSortedUser);

              this.isLoading$ = false;
              this.addUserReady++;
            }); //get_all_branches
          }); // getalltitles
      })
      .catch((error) => {
        // console.log(error.response);
        // console.log(error.response.data);
        this.showReload = true;
        console.log(error.response.request);
        console.log(error.response.status);
        if (!error.response.request.withCredentials) {
          // localStorage.removeItem("user");
          // this.$store.dispatch("Logout");
          if (error.response.data == undefined) {
            this.$store.dispatch("Add_Snackbar", {
              timeout: -1,
              text: "Base response: " + error.response,
              color: "red darken-2",
            });
          } else {
            this.$store.dispatch("Add_Snackbar", {
              timeout: -1,
              text: error.response.data.message,
              color: "red darken-2",
            });
          }

          // this.$store.dispatch;
        } else {
          this.$router.push("/");
        }
      }); // getall users
    // if (this.restrict <= 2) {
    // console.log(this.restrict$);

    if (this.restrict$ <= 2) {
      await axios.get("/departments").then(async (response) => {
        // console.log("departments", response);

        if (response.data.success) {
          this.departments = response.data.data;
          this.addUserReady++;
        }
      }); // get every department

      await axios
        .get("/users?filter[roles]=manager,admin&paginate=false")
        .then(async (response) => {
          // console.log(response);

          if (response.data.success) {
            this.managers = response.data.data;
            this.addUserReady++;
          }
        }); // get every manager
    }
  } //getuser_by_id

  sortBySwitcher(x: number): void {
    this.sortByTab = x;
    // this.paginator.firstPage();
    // this.paginator._changePageSize(10);
    if (this.filterControls.search.status || this.filterControls.title.status) {
      this.clearSearch();
      this.clearTitles();
    }
    this.pSortedUser = this.sortedUsers[x];
  }

  //double sort array of objects
  private SortArrObjs = function (
    arr: UserData[],
    prop1: string,
    prop2: string
  ) {
    let sort1 = [...arr].sort((a, b) => {
      if (a.attributes[prop1] == b.attributes[prop1]) {
        if (a.attributes[prop2] == b.attributes[prop2]) return 0;
        return a.attributes[prop2] < b.attributes[prop2] ? -1 : 1;
      } else return a.attributes[prop1] < b.attributes[prop1] ? -1 : 1;
    });

    return sort1;
  };

  private forUserSort(
    sorted: [
      { Title; users: UserData[] }[],
      { Title; users: UserData[] }[],
      { Title; users: UserData[] }[]
    ]
  ) {
    // this.allSortedUsers= sortedMerits;
    this.sortedUsers = sorted;
    this.pSortedUser = this.sortedUsers[this.sortByTab];
  }

  private sortAll(userData): [
    {
      Title: { id: number; attributes: { name: string } };
      users: UserData[];
    }[],
    {
      Title: TitleData;
      users: UserData[];
    }[],
    { Title: BranchData; users: UserData[] }[]
  ] {
    return [
      this.AZ(userData, this.letters),
      this.sortTitles(userData, this.titles),
      this.sortBranch(userData, this.branches),
    ];
  }

  private AZ(userDataAZ: UserData[], letters: string[]) {
    const x: { Title; users }[] = [];
    // this.LaU = []
    letters.forEach((letter, index) => {
      let users: UserData[] = [];
      userDataAZ.forEach((user) => {
        if (user.attributes.first_name.charAt(0) == letter) users.push(user);
      });
      x.push({ Title: { id: index, attributes: { name: letter } }, users });
      // this.LaU.push({Title:letter,users})
    }); //letter ForEach
    return x;
  }

  private sortTitles(userDataAZ: UserData[], titles: TitleData[]) {
    // this.userDataTitle=[]
    const x: { Title: TitleData; users: UserData[] }[] = [];

    titles.forEach((title) => {
      let users: UserData[] = [];
      userDataAZ.forEach((user) => {
        // console.log(user.attributes.employee_title_id, title.id);

        if (user.attributes.employee_title_id == title.id) {
          users.push(user);
        }
      });
      // this.userDataTitle.push({Title:title,users});
      // console.log(title);
      x.push({ Title: title, users });
    }); //letter ForEach
    // console.log(this.userDataTitle);

    return x;
  }

  private sortBranch(userDataAZ: UserData[], branches: BranchData[]) {
    // this.userDataBranch = []
    const x: { Title; users }[] = [];
    branches.forEach((branch) => {
      let users: UserData[] = [];
      userDataAZ.forEach((user) => {
        // console.log(user.attributes.branch_id, branch.id);

        if (user.attributes.branch_id == branch.id) users.push(user);
      });
      // this.userDataBranch.push({Title:branch,users});
      x.push({ Title: branch, users });
    });
    return x;
  }

  addUser() {
    if (this.restrict$ <= 3) {
      return {
        managers: this.managers,
        branches: this.branches,
        departments: this.departments,
        roles: this.roles,
        titles: this.titles,
        user_id: this.user_id,
        restrict: this.restrict$,
      };
    } else {
      this.showSnackbar({ show: true, timeout: 2000, text: "Unauthorised" });
      return null;
    }
  }

  userEditClicked(id: string) {
    // console.log("userEditClicked", id);
    this.$router.push({ name: "UserEdit", params: { id } });
  }
  viewProfile(user: UserData) {
    // console.log("viewProfile", user);
    if (this.restrict$ <= 3) {
      this.dialogs.viewProfile.data = {
        managers: this.managers,
        branches: this.branches,
        departments: this.departments,
        roles: this.roles,
        titles: this.titles,
        user_id: this.user_id,
        restrict: this.restrict$,
        user,
      };
      this.dialogs.viewProfile.status = true;
    }
  }

  deactivate(response: boolean, user_id: string) {
    // console.log(response, user_id);
    if (response) {
      axios
        .delete(`/users/${user_id}`)
        .then((response) => {
          // console.log(response);
          this.$router.go(0);
          this.showSnackbar({
            show: true,
            timeout: 8000,
            text: response.data.message,
          });
        })
        .catch((error) => {
          this.onError(error, "scroll-page");
        });
    } else {
      this.showSnackbar({
        show: true,
        timeout: 2000,
        text: "Request Canceled",
      });
    }
  }

  activate(user_id: string, x: string) {
    console.log(user_id, x);
  }

  clearSearch(): void {
    this.filterControls.search.data = "";
    this.searchFilter();
    // console.log("cleareearch");
  }
  clearTitles() {
    this.filterControls.title.data = [];
    this.titleFilter();
    // console.log("cleartitles");
  }
  searchFilter() {
    this.filterControls.search.status =
      this.filterControls.search.data.length > 0;
    // console.log(this.filterControls.search.data);
  }
  titleFilter() {
    this.filterControls.title.status =
      this.filterControls.title.data.length > 0;
    // console.log(this.filterControls.title.data);
  }

  Filter() {
    this.getuser_by_id(this.filterControls.search.data);
  }
  FilterClear() {
    this.filterControls.search.data = "";
    this.Filter();
  }

  dialogClose(response: { status; message }, dialogType: string) {
    // console.log(response, dialogType, this.dialogs[dialogType]);
    this.dialogs[dialogType].status = response.status;
    this.showSnackbar({ show: true, timeout: 3000, text: response.message });
  }
  reload() {
    this.getuser_by_id("");
  }
  private onError(error, scroll: string): void {
    this.errors = errorResponse(error, this.$store);
    const myElement: HTMLElement | null = document.getElementById(scroll);
    if (myElement != null) myElement.scrollIntoView();
  }
  mounted() {
    this.$store.dispatch("commitBASE");
    // console.log(axios.defaults.baseURL);
    // console.log(this.userID$);

    this.isLoading$ = true;
    // const userID = localStorage.getItem("userID");
    this.getuser_by_id("");
    // this.openDialog();
  }
} //end Dashboard Dialog
/*
export default defineComponent({
  props: {},
  data() {
    return {
      fixedReady: true,
      isLoading$: false,
      ready: {
        userHighlight: this.fixedReady,
        matesAbsent: this.fixedReady,
        newEmp: this.fixedReady,
        myMerits: this.fixedReady,
        newMerits: this.fixedReady,
        leaveRemaining: this.fixedReady,
        upcomingEvents: this.fixedReady,
      },
      restrict: 4,
      leaveRemaining: "",
      data: {
        userHighlight: [{}, {}, {}],
        matesAbsent: [{}],
        newEmp: { data: "" },
        upcomingEvents: { Name: "", Date: "" },
        myMerits: [],
        merits: [],
      },
    };
  },
  methods: {
    openDialog() {
      console.log("");
    },
  },
});
*/
