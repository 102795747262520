



























// import { defineComponent } from "vue";
import Vue from "vue";
import Component from "vue-class-component";

const Data = Vue.extend({
  props: {
    setDates: Object,
  },
});

@Component
export default class ComponentRangedDates extends Data {
  public dialog = false;
  modal = false;
  dates = ["", ""];
  datesSelected = "";

  rules = {
    required: (value) => {
      console.log("DateRangePicker", value.length);
      return value.length <= 3 ? "Required." : true;
    },
  };
  addDates(dates) {
    if (dates[0] > dates[1]) dates = [dates[1], dates[0]];
    if (dates[1] === undefined) dates[1] = dates[0];
    this.datesSelected = this.dateRangeText(dates);
    this.modal = false;
    let days = this.calculateDays(dates[0], dates[1]);
    this.$emit("daterange", {
      start_date: dates[0],
      end_date: dates[1],
      days: days,
    });
  }
  dateRangeText(v: any) {
    return v.join(" - ");
  }
  closeDelete(response: boolean) {
    this.dialog = false;
    this.$emit("daterange", response);
  }
  calculateDays(startDate: string, endDate: string): number {
    // Convert the date strings to Date objects
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Calculate the difference in milliseconds
    const difference = Math.abs(end.getTime() - start.getTime());

    // Convert milliseconds to days
    const days = Math.ceil(difference / (1000 * 60 * 60 * 24)) + 1;

    return days;
  }
  mounted() {
    this.dates = ["", ""];
    this.datesSelected = "";
    this.datesSelected = this.dateRangeText([
      this.setDates.start_date.slice(0, 10),
      this.setDates.end_date.slice(0, 10),
    ]);
  }
}
