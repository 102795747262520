





























































































import Vue, { PropType } from "vue";
import Component from "vue-class-component";
import PhoneInput from "@/components/reusable/PhoneInput.vue";
import { CountryCodeData } from "@/types/CountryCodeData";
import {
  EmergencyContact,
  EmergencyContactAttributes,
} from "@/types/EmergencyContact";
import { component } from "vue/types/umd";

const Data = Vue.extend({
  props: {
    countries: {
      type: Array as PropType<CountryCodeData[]>,
      required: true,
    },
    contact: {
      type: Object as PropType<EmergencyContactAttributes>,
      required: true,
    },
    name: {
      type: String,
      default: "Emergency Contact",
    },
  },
});

@Component({
  components: {
    PhoneInput: PhoneInput,
  },
})
export default class ContactComponent extends Data {
  public relationships: string[] = [
    "Spouse",
    "Parent",
    "Sibling",
    "Friend",
    "Other",
  ];

  public phoneTypes: string[] = ["Home", "Work", "Mobile", "Other"];
  rules = {
    required: (value: string | null) => {
      return value == "" || value == null ? "Required." : true;
    },
    email: (value: string) => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) ? "Invalid e-mail.".toUpperCase : true;
    },
  };

  codes(item: CountryCodeData) {
    return `${item.name} ${item.phone_code}`;
  }

  public componentContact: EmergencyContactAttributes = this.contact;
}
