























































































// import { defineComponent } from "vue";
import errorResponse from "@/services/errorCode";
import axios from "axios";
import Vue, { PropType } from "vue";
import Component from "vue-class-component";
const Props = Vue.extend({
  props: {
    name: String,
    title: String,
    id: String,
    path: String,
  },
});

@Component
export default class CompanyDeleteDialog extends Props {
  public dialog = false;
  public ready$ = false;
  public errors: string[] = [];

  public closeDelete() {
    this.dialog = false;
  }

  public async deleteItem() {
    axios
      .delete(this.path + "/" + this.id)
      .then((response) => {
        // console.log(response.data);
        this.dialog = false;
        this.$emit("reload");
      })
      .catch((error) => {
        this.onError(error, "scroll-dialogs");
      });
  }

  onError(error, scroll: string): void {
    this.errors = errorResponse(error, this.$store);
    const myElement: HTMLElement | null = document.getElementById(scroll);
    if (myElement != null) myElement.scrollIntoView();
  }

  mounted() {
    console.log("MANGE MOUNTED---------");
    this.$store.dispatch("commitBASE");

    this.ready$ = true;
  }
}
