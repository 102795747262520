
















































































































































// import { defineComponent } from "vue";
import errorResponse from "@/services/errorCode";
import UserData from "@/types/UserData";
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";

const Data = Vue.extend({
  props: {
    collection: String,
    user_id: String,
  },
});

@Component
export default class ComponentAttachmentAdd extends Data {
  public dialog = false;
  public ready$ = false;
  public users: UserData[] = [];
  public errors: string[] = [];
  selectedData: unknown[] = [];

  title = "";
  form_valid = false;
  form: { file: undefined | File } = {
    file: undefined,
  };

  public emptyRules = (v) => !!v || "Required";

  close() {
    this.$store.dispatch("Add_Snackbar", {
      timeout: 3000,
      text: "Request Canceled",
      color: "orange darken-2",
    });
    this.clearForm();
  }
  clearForm() {
    this.form = {
      file: undefined,
    };
    this.errors = [];
    this.dialog = false;
  }
  AddAttachment() {
    const data = new FormData();
    if (this.form.file != undefined) {
      data.append("file", this.form.file);
      data.append("collection_name", this.collection);

      axios
        .post(`/users/${this.user_id}/media`, data)
        .then((response) => {
          console.log(response.data);
          this.$emit("addattachment", {
            reload: true,
            text: response.data.message,
          });
          this.$store.dispatch("Add_Snackbar", {
            timeout: 3000,
            text: response.data.message,
            color: "green darken-2",
          });
          this.clearForm();
        })
        .catch((error) => {
          this.onError(error, "scroll-dialogs");
        });
    } else this.errors = ["Attachment is required"];
  }

  updateForm(collection: string) {
    this.title = collection;
    this.form = {
      file: undefined,
    };
  }

  private onError(error, scroll: string): void {
    this.errors = errorResponse(error, this.$store);
    const myElement: HTMLElement | null = document.getElementById(scroll);
    if (myElement != null) myElement.scrollIntoView();
  }

  mounted() {
    this.updateForm(this.collection);
  }
}
