












































































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import axios from "axios";
import ComponentXImage from "@/components/reusable/XImage.vue";

// Define the props by using Vue's canonical way.
const AddData = Vue.extend({
  props: {
    xData: Object,
  },
});

@Component({
  components: {
    XImage: ComponentXImage,
  },
})
export default class ComponentViewClash extends AddData {
  public dialog = false;
  public fullscreen = this.xData.fullscreen;
  public clashes = this.xData.clashes;

  toggleDialog(): void {
    this.dialog = !this.dialog;
  }
}
