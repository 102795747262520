






















































































































































// import { defineComponent } from "vue";
import errorResponse from "@/services/errorCode";
import UserData from "@/types/UserData";
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";
import AuthenticationService from "../../../services/AuthenticationService";
import TenantData from "../../../types/TenantData";
import ComponentTenant from "./dialog-select-tenant.vue";

@Component({
  components: {
    DialogSelectCompany: ComponentTenant,
  },
})
export default class ComponentChangePassword extends Vue {
  public dialog = false;
  public ready$ = false;
  public users: UserData[] = [];
  public errors: string[] = [];
  public form: {
    email: string;
    tenant: string;
  } = {
    email: "",
    tenant: "",
  };
  showTenants = false;
  public dialogLoading = false;
  public tenants: TenantData[] = [];

  public emptyRules = (v) => !!v || "Required";

  public min6 = (value) =>
    value.length >= 6 ? true : "Password should have more than 6 characters.";

  public get isEmptyForm(): boolean {
    return !!this.form.email || !!this.form.tenant;
  }

  clearForm() {
    this.form = { email: "", tenant: "" };
    this.dialog = false;
  }

  closeDelete() {
    this.clearForm();
    this.$emit("forgotpassword", { status: true, message: "Request Canceled" });
  }

  closeCompanyDialog(val: boolean) {
    this.showTenants = !val;
  }
  async GetTenants() {
    this.showTenants = true;
    this.dialogLoading = true;
    await AuthenticationService.centralLogin(this.form.email)
      .then((response) => {
        if (response.data.success) {
          this.tenants = response.data.data;

          this.dialogLoading = false;
        }
      })
      .catch((error) => {
        this.onError(error, "dialog-scroll");
        this.$emit("forgotpassword", {
          status: true,
          message: "Error: " + error.response.data.message,
        });
      });
    this.dialogLoading = false;
  }

  async sendRequest(tenant: TenantData) {
    this.showTenants = false;
    const data = {
      email: this.form.email,
      tenant: tenant.id,
    };
    await AuthenticationService.forgotPassword(data)
      .then((response) => {
        console.log(response);
        this.clearForm();
        this.$emit("forgotpassword", {
          status: true,
          message: response.data.message,
        });
      })
      .catch((error) => {
        this.onError(error, "dialog-scroll");
        this.$emit("forgotpassword", {
          status: true,
          message: "Error: " + error.response.data.message,
        });
      });
  }

  private onError(error, scroll: string): void {
    this.errors = errorResponse(error, this.$store);
    const myElement: HTMLElement | null = document.getElementById(scroll);
    if (myElement != null) myElement.scrollIntoView();
  }
}
