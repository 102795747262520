





































































































































































































































































































































































































// import { defineComponent } from "vue";
import Vue from "vue";
import Component from "vue-class-component";
import axios from "axios";
import UserAttributes from "@/types/UserAttributes";
import ComponentXImage from "@/components/reusable/XImage.vue";
import ComponentAbsentUser from "@/components/body/dashboard/dialog-absent-user.vue";
import ComponentNewEmployee from "@/components/body/dashboard/dialog-new-employee.vue";
import ComponentNewMerits from "@/components/body/dashboard/dialog-new-merits.vue";
import AbsentEmployee from "@/types/AbsentEmployee";
import UserMeritsData from "@/types/UserMeritsData";
// import "../../services/GetService";

@Component({
  components: {
    XImage: ComponentXImage,
    DialogAbsentUser: ComponentAbsentUser,
    DialogNewEmployee: ComponentNewEmployee,
    DialogNewMerits: ComponentNewMerits,
  },
})
export default class DashboardView extends Vue {
  public get isLoggedIn$(): boolean {
    return this.$store.getters["isAuthenticated"];
  }
  public get buildVersion$(): boolean {
    return this.$store.getters["buildVersion"];
  }
  public get userID$(): string {
    return this.$store.getters["getUserID"];
  }
  public get restrict$(): number {
    return this.$store.getters["getRestricted"];
  }
  public get getBase$(): string {
    return this.$store.getters["getBASE"];
  }

  public dialog = false;
  fixedReady = false;
  isLoading$ = true;
  ready = {
    userHighlight: this.fixedReady,
    matesAbsent: this.fixedReady,
    newEmp: this.fixedReady,
    myMerits: this.fixedReady,
    newMerits: this.fixedReady,
    leaveRemaining: this.fixedReady,
    upcomingEvents: this.fixedReady,
  };
  userHighlight = ["", "", ""];
  data = {
    matesAbsent: [],
    newEmp: { message: "", data: [] },
    upcomingEvents: { Name: "", Date: "", Image: "" },
    myMerits: [],
    newMerits: [],
    leaveRemaining: "",
  };
  absentEmployees: AbsentEmployee[] = [];
  newMeritsData: UserMeritsData[] = [];

  openDialog(): void {
    console.log("Testerx");
  }

  private async loadDashboard() {
    // console.log(Object.entries(this.data));

    //Get User Basic Data
    await this.getbyuser_wfh();

    //load dashboard cards
    axios.get("/dashboard").then((response) => {
      if (response.data.success) {
        const data = response.data.data;
        // console.log("Dashboard", data);
        if (data.next_holiday != null) {
          this.data.upcomingEvents = {
            Name: data.next_holiday.attributes.name,
            Date: data.next_holiday.attributes.date,
            Image: data.next_holiday.attributes.image,
          };
          // console.log(x);
          this.ready.upcomingEvents = true;
        }

        this.data.matesAbsent = data.absent_employees;
        this.absentEmployees = data.absent_employees;
        this.ready.matesAbsent = true;

        this.data.myMerits = data.new_user_merits_this_week;
        this.ready.myMerits = true;

        this.data.newMerits = data.new_merits_this_week;
        this.newMeritsData = data.new_merits_this_week;
        this.ready.newMerits = true;

        this.data.leaveRemaining = data.remaining_leave;
        this.ready.leaveRemaining = true;

        this.data.newEmp = {
          message:
            data.new_employees >= 1
              ? "Employees new this week"
              : "No new Employees this week",
          data: data.new_employees,
        };
        this.ready.newEmp = true;
      }
      this.isLoading$ = false;
    });
  }

  private async getbyuser_wfh(): Promise<void> {
    return (
      axios
        .get("/user")
        .then(async (response) => {
          // console.log(response.data.data);
          const self: UserAttributes = response.data.data.attributes;
          axios
            .get("/wfh")
            .then((response) => {
              const wfh: {
                friday: boolean;
                monday: boolean;
                name: string;
                saturday: boolean;
                sunday: boolean;
                thursday: boolean;
                tuesday: boolean;
                wednesday: boolean;
              } = response.data.data.attributes;
              const xDate = new Date().toLocaleString("default", {
                weekday: "long",
              });

              const isWorking =
                wfh[xDate.toLowerCase()] == true
                  ? "You are working in office today."
                  : "You are working from home today.";

              this.userHighlight = [
                self.avatar,
                "Hi " + self.first_name,
                isWorking,
              ];
              // console.log(self, this.userHighlight);

              this.ready.userHighlight = true;
              return;
            })
            //wfh catch
            .catch((error) => {
              if (error.response) {
                console.log(error.response.data);
                console.log(error.response.request);
                console.log(error.response.status);
              }
              return;
            }); //get_wfh_end
        })
        // user catch
        .catch((error) => {
          console.log(error.response);
          if (!error.response.request.withCredentials) {
            // localStorage.removeItem("user");
            // this.$store.dispatch("Logout");
            if (error.response.data == undefined) {
              this.$store.dispatch("Add_Snackbar", {
                timeout: -1,
                text: "Base response: " + error.response,
                color: "red darken-2",
              });
            } else {
              this.$store.dispatch("Add_Snackbar", {
                timeout: -1,
                text: error.response.data.message,
                color: "red darken-2",
              });
            }
            localStorage.removeItem("user");
            this.$store.dispatch("Logout");

            // this.$store.dispatch;
          } else {
            this.$store.dispatch("TokenReset");
            // this.$router.push("/");
          }
        })
    ); // getall users
  } //getbyuser_wfh

  mounted() {
    console.log(this.buildVersion$);
    this.$store.dispatch("commitBASE");
    // axios.defaults.baseURL = this.getBase$;
    this.$store.dispatch("build");
    this.isLoading$ = true;
    this.loadDashboard();
  }
} //end Dashboard Dialog
