




















































































































































































































































































































































































































// import { defineComponent } from "vue";
import Vue from "vue";
import Component from "vue-class-component";
import axios from "axios";
import UserData from "@/types/UserData";
import TitleData from "@/types/TitleData";
import BranchData from "@/types/BranchData";
import DepartData from "@/types/DepartData";
import UserAttributes, { MeritDataType } from "@/types/UserAttributes";
import PersonalityAnswerData from "@/types/pAnswersData";
import errorResponse from "@/services/errorCode";
import ComponentXImage from "@/components/reusable/XImage.vue";
import LeaveType from "@/types/LeaveType";
// Define the props by using Vue's canonical way.
const AddData = Vue.extend({
  props: {
    xData: Object,
  },
});

@Component({
  components: {
    XImage: ComponentXImage,
  },
})
export default class ComponentUserProfile extends AddData {
  public dialog = false;
  public errors: string[] = [];
  public fullscreen = this.xData.fullscreen;
  public restrict = this.xData.restrict;
  private companyID = this.xData.companyID;
  public userID = this.xData.user_id;
  public profile: UserAttributes = this.xData.user.attributes;
  branches: BranchData[] = this.xData.branches;
  private titles: TitleData[] = this.xData.titles;
  private departments: DepartData[] = this.xData.departments;
  public managers: UserData[] = this.xData.managers;
  public QnA: PersonalityAnswerData[] = [];
  public roles = this.xData.roles;
  public leaves: LeaveType[] = [];

  filterDepartments: DepartData[] = [];
  filterTitles: TitleData[] = [];

  public get restrict$(): number {
    return this.$store.getters["getRestricted"];
  }
  leaveOptions = [
    {
      text: "Eligible",
      value: "1",
    },
    {
      text: "Not Eligible",
      value: "0",
    },
  ];

  date = false;
  // filterManagers: UserData[] = [];

  length!: number;
  getName(item: UserData): string {
    // console.log(item);
    return item.attributes.first_name + " " + item.attributes.last_name;
  }

  // openDialog(): void {
  //   console.log("Users");
  //   // this.dialog = this.status;
  // }

  closeResponse() {
    this.dialog = false;
  }

  public meritTotal(merits: MeritDataType[]): number {
    var x = 0;
    merits.forEach((merit) => (x += merit.points));
    return x;
  }
  async getQnA(userID: string) {
    await axios
      .get("user-personality-answers/" + userID)
      .then((response) => {
        // console.log(response);
        this.QnA = response.data.data;
      })
      .catch((error) => {
        this.onError(error, "scroll-dialogs");
      });
  }

  private async getLeaveRemaining(userID) {
    // console.log(userID);
    await axios
      .get(`leave-remaining/${userID}`)
      .then((response) => {
        this.leaves = response.data.leave_remaining;
        // console.log("leaves", this.leaves);
      })
      .catch((error) => {
        // console.log("leave", error.response);
        this.onError(error, "scroll-dialogs");
      });
  }
  private onError(error, scroll: string): void {
    this.errors = errorResponse(error, this.$store);
    const myElement: HTMLElement | null = document.getElementById(scroll);
    if (myElement != null) myElement.scrollIntoView();
  }
  mounted() {
    // console.log("Profile", this.xData);
    this.getQnA(this.xData.user.id);
    this.getLeaveRemaining(this.xData.user.id);
    // this.openDialog();s
  }
} //end Dashboard Dialog

/*
export default defineComponent({
  props: {},
  data() {
    return {
      fixedReady: true,
      isLoading$: false,
      ready: {
        userHighlight: this.fixedReady,
        matesAbsent: this.fixedReady,
        newEmp: this.fixedReady,
        myMerits: this.fixedReady,
        newMerits: this.fixedReady,
        leaveRemaining: this.fixedReady,
        upcomingEvents: this.fixedReady,
      },
      restrict: 4,
      leaveRemaining: "",
      data: {
        userHighlight: [{}, {}, {}],
        matesAbsent: [{}],
        newEmp: { data: "" },
        upcomingEvents: { Name: "", Date: "" },
        myMerits: [],
        merits: [],
      },
    };
  },
  methods: {
    openDialog() {
      console.log("");
    },
  },
});
*/
