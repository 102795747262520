


































































































































































































































































































































import Vue from "vue";
import axios from "axios";
import Component from "vue-class-component";
import ComponentImportTool from "./importTool.vue";
// import ComponentBranches from "@/components/body/admin/dialog-branches.vue";
import ComponentHolidays from "@/components/body/admin/dialog-holidays.vue";
// import ComponentWFH from "@/components/body/admin/dialog-wfh.vue";
@Component({
  components: {
    // DialogBranches: ComponentBranches,
    DialogHolidays: ComponentHolidays,
    // DialogWFH: ComponentWFH,
    DialogImport: ComponentImportTool,
  },
})
export default class ComponentHeader extends Vue {
  get isLoggedIn$(): boolean {
    return this.$store.getters["isAuthenticated"];
  }
  get getuserFirstName$(): boolean {
    return this.$store.getters["userFirstName"];
  }
  get company$() {
    return this.$store.getters["getCompany"];
  }
  get role$(): number {
    return this.$store.getters["getRestricted"];
  }
  // isLoggedIn$ = true;
  isLoading$ = false;

  branch() {
    console.log("branches");
  }

  holidays() {
    console.log("holidays");
  }

  wfh() {
    console.log("wfh");
  }

  Logout() {
    // this.SetLogged(false);
    localStorage.removeItem("user");
    this.$store.dispatch("Logout");
    // this.$router.push({ name: "Login" });
    // this.router.navigate(["/login"]);gk7tu6ry
    this.$store.commit("resetSetBase", "");
    this.$store.commit("showCompanyDialog", false);
    window.location.reload();
  }

  tokenReset() {
    axios
      .get("/refresh-token")
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  mounted() {
    // this.$store.dispatch("commitBASE");
    // this.tokenReset();
    // console.log(this.$store.getters["getToken"]);
    // console.log(this.isLoggedIn$, this.getuserFirstName$);
    // this.Logout();
  }
}
