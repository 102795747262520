var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":_vm.width,"scrollable":"","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on_dialog = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on_tool = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","block":"","left":""}},'v-btn',{ attrs: attrs },false),Object.assign({}, on_tool, on_dialog)),[_c('span',{staticClass:"ml-2 font-weight-bold"},[_vm._v(_vm._s(_vm.mode.title))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.mode.tooltip))])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"rounded":"0"}},[_c('v-toolbar',{attrs:{"dark":"","color":"#f15a29"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.closeResponse()}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Summary Export")])],1),_c('v-card-text',[_c('v-row',{staticClass:"mt-3",attrs:{"id":"scroll-add-user"}},_vm._l((_vm.errors),function(error,index){return _c('v-alert',{key:index,attrs:{"dismissible":"","dense":"","color":"red lighten-2","dark":"","type":"error"}},[_vm._v(" "+_vm._s(error)+" ")])}),1),(!_vm.showTables)?_c('v-row',{staticClass:"mt-3 mb-3"},[_c('v-dialog',{ref:"dialog",attrs:{"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Select Start Date","prepend-icon":"mdi-calendar","readonly":"","rules":[_vm.rules.required]},model:{value:(_vm.leave.start_date),callback:function ($$v) {_vm.$set(_vm.leave, "start_date", $$v)},expression:"leave.start_date"}},'v-text-field',attrs,false),on))]}}],null,false,3822416823),model:{value:(_vm.modalStart),callback:function ($$v) {_vm.modalStart=$$v},expression:"modalStart"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.leave.start_date),callback:function ($$v) {_vm.$set(_vm.leave, "start_date", $$v)},expression:"leave.start_date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalStart = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalStart = false}}},[_vm._v(" OK ")])],1)],1),_c('v-dialog',{ref:"dialog",attrs:{"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Select End Date","prepend-icon":"mdi-calendar","readonly":"","rules":[_vm.rules.required]},model:{value:(_vm.leave.end_date),callback:function ($$v) {_vm.$set(_vm.leave, "end_date", $$v)},expression:"leave.end_date"}},'v-text-field',attrs,false),on))]}}],null,false,626515479),model:{value:(_vm.modalEnd),callback:function ($$v) {_vm.modalEnd=$$v},expression:"modalEnd"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.leave.end_date),callback:function ($$v) {_vm.$set(_vm.leave, "end_date", $$v)},expression:"leave.end_date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalEnd = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalEnd = false}}},[_vm._v(" OK ")])],1)],1)],1):_vm._e(),(_vm.showTables)?_c('v-row',_vm._l((_vm.tables),function(table,index){return _c('v-card',{key:index,staticClass:"mb-3"},[_c('v-card-title',[_c('v-row',[_c('span',{staticClass:"orange--text text--darken-1 font-weight-medium text-h6"},[_vm._v(_vm._s(table.title))]),_c('v-spacer'),_c('span',{staticClass:"grey--text text--darken-1 font-weight-bold text-caption"},[_vm._v(_vm._s(table.subtitle))])],1),_c('v-row',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(table.search),callback:function ($$v) {_vm.$set(table, "search", $$v)},expression:"table.search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":table.headers,"items":table.items,"item-key":"email","search":table.search,"show-expand":table.showExpand,"expanded":table.expanded,"no-data-text":"No Data Available"},on:{"update:expanded":function($event){return _vm.$set(table, "expanded", $event)}},scopedSlots:_vm._u([(table.title == 'Leave Per User')?{key:"item.leave_requests",fn:function(ref){
var item = ref.item;
return [(item.leave_requests.length > 0)?_c('span',[_vm._v(_vm._s(item.leave_requests.length == 1 ? "1 request" : item.leave_requests.length + " requests"))]):_c('span',[_vm._v("NA")])]}}:null,(table.title == 'Current Leave Status')?{key:"item.current_leave_status",fn:function(ref){
var item = ref.item;
return [(item.current_leave_status.length > 0)?_c('span',[_vm._v("Available")]):_c('span',[_vm._v("NA")])]}}:null,(table.title == 'Current Leave Status')?{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item.current_leave_status.length > 0)?_c('td',{attrs:{"colspan":headers.length}},[_c('v-card',{staticClass:"ma-2"},[_c('v-card-title',{staticClass:"orange--text text--darken-1 font-weight-medium text-h6"},[_vm._v(" "+_vm._s(("Status Breakdown - " + (item.first_name) + " " + (item.last_name)))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.createHeaders(item.current_leave_status[0]),"items":item.current_leave_status,"disable-pagination":"","dense":"","hide-default-footer":""}})],1)],1):_c('td',{attrs:{"colspan":headers.length}},[_vm._v(" No Additional Information Available ")])]}}:(table.title == 'Leave Per User')?{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item.leave_requests.length > 0)?_c('td',{attrs:{"colspan":headers.length}},[_c('v-card',[_c('v-card-title',{staticClass:"orange--text text--darken-1 font-weight-medium text-h6"},[_vm._v(" "+_vm._s(("Leave Request Information - " + (item.first_name) + " " + (item.last_name)))+" ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.createHeaders(item.leave_requests[0]),"items":item.leave_requests,"item-key":"id","show-expand":"","no-data-text":"No Data Available"},scopedSlots:_vm._u([{key:"item.days_breakdown",fn:function(ref){
var item = ref.item;
return [(item.days_breakdown.length > 0)?_c('span',[_vm._v(_vm._s(item.days_breakdown.length == 1 ? "1 request" : item.days_breakdown.length + " requests"))]):_c('span',[_vm._v("NA")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item.days_breakdown.length > 0)?_c('td',{attrs:{"colspan":headers.length}},[_c('v-card',[_c('v-card-title',{staticClass:"grey--text text--darken-1 font-weight-bold text-subtitle-2"},[_vm._v(" "+_vm._s(("Days Breakdown for leave request \"" + (item.title) + "\""))+" ")]),_c('v-card-text',_vm._l((item.days_breakdown),function(leave,index){return _c('v-row',{key:index,staticClass:"elevation-1"},[_c('v-row',[_c('v-col',{staticClass:"font-weight-bold",attrs:{"cols":"1"}},[_vm._v(_vm._s(("#" + (index + 1))))]),_c('v-col',[_vm._v(" "+_vm._s(("Start Date: " + (leave.start_date)))+" ")]),_c('v-col',[_vm._v(_vm._s(("End Date: " + (leave.end_date))))]),_c('v-col',[_vm._v(" "+_vm._s(("Number of Days: " + (leave.number_days)))+" ")])],1)],1)}),1)],1)],1):_vm._e()]}}],null,true)})],1)],1)],1):_c('td',{attrs:{"colspan":headers.length}},[_vm._v(" No Additional Leave Information Available ")])]}}:null],null,true)})],1)}),1):_vm._e(),_c('v-card-actions',{staticClass:"mt-1"},[(!_vm.showTables)?_c('v-btn',{staticClass:"font-weight-medium",attrs:{"dark":"","color":"orange darken-1"},on:{"click":function($event){return _vm.exportFile(_vm.type, false)}}},[_vm._v("View Summary ")]):_vm._e(),(_vm.showTables)?_c('v-btn',{staticClass:"font-weight-medium",attrs:{"dark":"","color":"orange darken-1"},on:{"click":function($event){return _vm.reset()}}},[_vm._v("Reset ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{staticClass:"font-weight-medium",attrs:{"dark":"","color":"orange darken-1"},on:{"click":function($event){return _vm.exportFile(_vm.type, true)}}},[_vm._v("Download")])],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"font-weight-medium",attrs:{"dark":"","color":"error"},on:{"click":function($event){return _vm.closeResponse()}}},[_vm._v("Cancel")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }