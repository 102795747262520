
































































































































































































































































// import { defineComponent } from "vue";
import Vue from "vue";
import Component from "vue-class-component";
import axios from "axios";

import ComponentXImage from "@/components/reusable/XImage.vue";
import UserAttributes from "@/types/UserAttributes";
import CompanyData from "@/types/CompanyData";
import UserData from "@/types/UserData";
import errorResponse from "@/services/errorCode";
import BranchData from "../../types/BranchData";
import DepartData from "../../types/DepartData";
import EmployeeData from "@/types/EmployeeData";
import CountryData from "@/types/CountryData";

import PanelBranches from "@/components/body/company/panel-branches.vue";
import PanelDepartments from "../../components/body/company/panel-departments.vue";
import PanelEmployeeTitles from "../../components/body/company/panel-employee-titles.vue";

@Component({
  components: {
    PanelBranches: PanelBranches,
    PanelDepartments: PanelDepartments,
    PanelEmployeeTitles: PanelEmployeeTitles,
  },
})
export default class CompanyStructure extends Vue {
  public mobileView: boolean = window.innerWidth <= 1000;
  isLoading = false;
  showBranches = true;
  showDepartments = false;
  showEmployeeTitles = false;
  selectedBranch = null;
  selectedBranchName = null;
  selectedDepartment = null;
  selectedDepartmentName = null;
  snackbar: { show: boolean; timeout: number; text: string } = {
    show: false,
    timeout: 2000,
    text: "",
  };

  showSnackbar(text: string) {
    this.snackbar = { show: true, timeout: 2000, text: text };
  }

  public toBranches() {
    this.showBranches = true;
    this.showDepartments = false;
    this.showEmployeeTitles = false;
  }

  public toDepartments() {
    this.showDepartments = true;
    this.showBranches = false;
    this.showEmployeeTitles = false;
  }

  public selectBranch(event) {
    console.log(event);
    this.selectedBranch = event.id;
    this.selectedBranchName = event.name;
    this.showBranches = false;
    this.showEmployeeTitles = false;
    this.showDepartments = true;
  }

  public selectDepartment(event) {
    console.log(event);
    this.selectedDepartment = event.id;
    this.selectedDepartmentName = event.name;

    this.showBranches = false;
    this.showDepartments = false;
    this.showEmployeeTitles = true;
  }

  private onError(error, scroll: string): void {
    // this.errors = errorResponse(error, this.$store);
    const myElement: HTMLElement | null = document.getElementById(scroll);
    if (myElement != null) myElement.scrollIntoView();
  }

  mounted() {
    this.$store.dispatch("commitBASE");
  }
}
