import baseAPI from "./BaseConfig";

export default {
  centralLogin(email: string) {
    return baseAPI({ central: true }).post("/v2/login", { email: email });
  },

  authenticate(data) {
    return baseAPI().post("/v2/login", data);
  },

  logout() {
    return baseAPI().post("/v1/logout");
  },

  forgotPassword(data) {
    return baseAPI().post("/v1/forgot-password", data);
  },
};
