














































































































































































// import { defineComponent } from "vue";
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";
import { saveAs } from "file-saver";

const AddData = Vue.extend({
  props: {
    calltype: String,
    mobile: Boolean,
    textBtn: Boolean,
  },
});

@Component
export default class ComponentExport extends AddData {
  dialog = false;
  // private restrict = 5;
  // private userID = "";

  public errors = [];

  public type = "";
  // public form: {
  //   sort: string;
  //   valid: boolean;
  // } = {
  //   sort: "",
  //   valid: false,
  // };
  // capitalize = (s: string) => (s && s[0].toUpperCase() + s.slice(1)) || "";
  rules = {
    required: (value) => {
      return !value || value == undefined ? "Required." : true;
    },
  };

  // openDialog(): void {
  //   console.log("Users");
  //   // this.dialog = this.status;
  // }

  exportFile(type: string) {
    axios({
      url: `export/${type}`, //your url
      method: "GET",
      responseType: "blob", // important
    })
      .then((response) => {
        var file = new File([response.data], `export-${type}.csv`, {
          type: response.data.type,
        });

        saveAs(file);
        // this.clearForm();
        console.log(response);
        this.$store.dispatch("Add_Snackbar", {
          timeout: 6000,
          text: "Successful request, check downloads",
          color: "green darken-2",
        });
      })
      .catch((error) => {
        this.$store.dispatch("Add_Snackbar", {
          timeout: 6000,
          text: error.response.data.message,
          color: "red darken-2",
        });
        console.log(error.response);
        if (error.response.data.errors.length > 0)
          this.errors = error.response.data.errors;
        else this.errors = error.response.data.message;
        const myElement: HTMLElement | null =
          document.getElementById("scroll-add-user");
        if (myElement != null) myElement.scrollIntoView();
      });
  }

  // reset() {
  //   (this.$refs.form as HTMLFormElement).reset();
  // }

  closeResponse(response: boolean) {
    // this.reset();
    this.dialog = false;
    this.$emit("hideDialog", response);
  }

  mounted() {
    console.log("xData", this.calltype);
    this.type = this.calltype;
    // const userID = localStorage.getItem("userID");
    // this.openDialog();s
  }
} //end Dashboard Dialog
