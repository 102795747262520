













































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































// import { defineComponent } from "vue";
import moment from "moment";
import Vue from "vue";
import Component from "vue-class-component";
import axios from "axios";
import LeaveRequestData from "@/types/LeaveRequestData";
import LeaveType from "@/types/LeaveType";
import UserData from "@/types/UserData";
import { LeaveDataType } from "@/types/UserAttributes";
import { RemainingLeaveBalance } from "@/types/RemainingLeaveBalance";

import ComponentAddUserLeave from "@/components/body/leave/dialog-add-user-leave.vue";
import ComponentAddLeave from "@/components/body/leave/dialog-add-leave.vue";
import ComponentEditLeave from "@/components/body/leave/dialog-edit-leave.vue";
import ComponentReviewLeave from "@/components/body/leave/dialog-review-leave.vue";
import ComponentReviewMyLeave from "@/components/body/leave/dialog-review-my-leave.vue";
import ComponentDeleteConfirm from "@/components/reusable/DeleteConfirm.vue";
import errorResponse from "@/services/errorCode";
import ComponentExportSummary from "@/components/body/export/dialog-export-summary.vue";
import ComponentExport from "@/components/reusable/dialog-export.vue";
import user from "@/store/modules/user";
@Component({
  components: {
    LeaveUserAdd: ComponentAddUserLeave,
    LeaveAdd: ComponentAddLeave,
    LeaveEdit: ComponentEditLeave,
    LeaveReview: ComponentReviewLeave,
    MyLeaveReview: ComponentReviewMyLeave,
    DialogDelete: ComponentDeleteConfirm,
    DialogExportSummary: ComponentExportSummary,
    DialogExport: ComponentExport,
  },
})
export default class LeaveView extends Vue {
  // private screenWidth = window.innerWidth;
  public mobileView: boolean = window.innerWidth <= 1000;

  public get restrict$(): number {
    return this.$store.getters["getRestricted"];
  }

  private get role$(): boolean {
    return this.$store.getters["getRole"];
  }

  private get user$(): UserData {
    return this.$store.getters["getSelf"];
  }
  public uuid: string | null = null;
  leaveTab = false;
  sortByTab = 0;
  fixedReady = false;
  isLoading$ = true;
  totalDaysTaken = 0;
  leaveType: {
    id: string;
    color: string;
    type: string;
    updated_at: string;
    total_days?: number | undefined;
  }[] = [];
  userLeaveBal: RemainingLeaveBalance[] = [];
  leaveTypeWait = true;
  companyID = "0";
  user_id = "-3";
  blockedOutDates = [];
  public errors: string[] = [];
  //all users **
  public userLeave: LeaveRequestData[] = [];
  public allLeave: LeaveRequestData[] = [];

  //userData
  startDate = "";
  vacationLatestStartDate = "";
  vacationLatestEndDate = "";
  canTakeVacation = false; //0:false | 1:true
  canTakeSickLeave = false; //0:false | 1:true
  form = {};

  leaveTypes: LeaveType[] = [];
  // range = "";
  // currentRange: { startDate: Date; endDate: Date };
  public getLeaveType = (id) => {
    const v = this.leaveTypes.find((v) => v.id == id);
    return v == undefined ? { attributes: { type: "", color: "000000" } } : v;
  };
  fileURL;
  public fileType;
  public fileReady = false;

  filterControls = {
    search: { data: "", status: false },
    title: { data: [], status: false },
  };

  dialogs = {
    addUser: { data: {}, status: false },
    editUser: { data: [], status: false },
    viewProfile: { data: [], status: false },
    attachments: { data: [], status: false },
    confimationDialog: { data: [], status: false },
  };
  // searchControl = "";
  // searchActive = false;
  // titleControl = [];
  // titleActive = false;
  showFilters = false;

  email = [
    (value) => !!value || "Required.",
    (value) => (value || "").length <= 20 || "Max 20 characters",
    (value) => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail.";
    },
  ];
  // openDialog(): void {
  //   console.log("Users");
  // }

  currentPage = 1;
  lastPage = 1;
  allLeaveCurrentPage = 1;
  allLeaveLastPage = 1;

  myLeaveOnPaginateClick(page: number) {
    this.currentPage = page;
    this.getMyLeaveData(this.filterControls.search.data);
  }

  allLeaveOnPaginateClick(page: number) {
    this.allLeaveCurrentPage = page;
    this.getAllLeaveData(this.filterControls.search.data);
  }

  formattedVacationLatestStartDate() {
    if (this.vacationLatestStartDate != "") {
      let _date = moment(this.vacationLatestStartDate);
      return _date.format("DD-MMM-YYYY");
    } else {
      return "-";
    }
  }

  formattedVacationLatestEndDate() {
    if (this.vacationLatestEndDate != "") {
      let _date = moment(this.vacationLatestEndDate);
      return _date.format("DD-MMM-YYYY");
    } else {
      return "-";
    }
  }

  private async getUserByID() {
    this.isLoading$ = true;
    this.leaveTypeWait = true;
    this.allLeave = [];
    this.userLeave = [];
    await axios
      .get("/user")
      .then(async (response) => {
        // console.log(response);
        const x: { data: UserData; success: boolean; included: [] } =
          response.data;
        if (x.success) {
          const user = x.data;
          // this.companyID = x.data.attributes;
          this.user_id = user.id;
          this.startDate = user.attributes.start_date;
          this.vacationLatestStartDate =
            user.attributes.vacation_latest_start_date ?? "";
          this.vacationLatestEndDate =
            user.attributes.vacation_latest_end_date ?? "";
          // this.restrict = Number(x.data.roleID);
          // const countryID = x.data.CountryID;
          this.userLeave = [];
          this.allLeave = [];
        }

        await this.getAllLeaveData("");
        await this.getMyLeaveData("");
      })
      .catch((error) => {
        console.log(error.response);
        if (!error.response.request.withCredentials) {
          // localStorage.removeItem("user");
          // this.$store.dispatch("Logout");
          if (error.response.data == undefined) {
            this.$store.dispatch("Add_Snackbar", {
              timeout: -1,
              text: "Base response: " + error.response,
              color: "red darken-2",
            });
          } else {
            this.$store.dispatch("Add_Snackbar", {
              timeout: -1,
              text: error.response.data.message,
              color: "red darken-2",
            });
          }
          localStorage.removeItem("user");
          this.$store.dispatch("Logout");

          // this.$store.dispatch;
        } else {
          this.$store.dispatch("TokenReset");
          // this.$router.push("/");
        }
      }); //get user by id
  }
  private async getAllLeaveData(filtername: string) {
    this.isLoading$ = true;
    if (this.restrict$ <= 3) {
      await axios
        .get(
          `/leave-requests?role=${this.role$}&filter[name]=${filtername}&sort=-updated_at&page=${this.allLeaveCurrentPage}`
        )
        .then((response) => {
          this.allLeaveCurrentPage = response.data.meta.current_page;
          this.allLeaveLastPage = response.data.meta.last_page;
          this.allLeave = response.data.data;
        })
        .finally(() => {
          this.isLoading$ = false;
        });
    }
  }
  private async getMyLeaveData(filtername: string) {
    this.isLoading$ = true;
    await axios
      .get(
        `/leave-requests?role=user&sort=-updated_at&filter[name]=${filtername}&page=${this.currentPage}`
      )
      .then(async (response) => {
        // console.log(response.data.data, "UsersLo");
        this.currentPage = response.data.meta.current_page;
        this.lastPage = response.data.meta.last_page;
        this.userLeave = response.data.data;

        await axios.get("/leave-types").then(async (response) => {
          console.log(response.data.data);
          this.leaveTypes = response.data.data;

          await axios.get("/leave-remaining").then((response) => {
            console.log("remaining_leave", response.data);

            // console.log(this.allSortedUsers);

            // var leaveBal: [];
            this.leaveTypeWait = true;
            if (response.data.success) {
              console.log("success", response.data.leave_remaining);
              this.userLeaveBal = response.data.leave_remaining;
              this.leaveTypeWait = false;
              console.log("balance: ", this.userLeaveBal);
            }

            this.leaveTypeWait = false;

            this.isLoading$ = false;
          });
        }); //get_all_leavetype
      });
  }

  getLeaveTypes(types: LeaveDataType[], name: string) {
    return types[types.findIndex((type) => type.leave_type === name)];
  }

  //Leave Tab
  leaveTabSwitch(value: boolean) {
    this.leaveTab = value;
  }

  private sortLeaveData(
    data: LeaveRequestData[]
  ): [LeaveRequestData[], LeaveRequestData[], LeaveRequestData[]] {
    const sortedByDate: LeaveRequestData[] = [];
    sortedByDate.push(...data);

    let sortedByType: LeaveRequestData[] = [];
    sortedByType.push(...data);
    sortedByType.sort((a, b) =>
      a.attributes.leave_type.id
        .toString()
        .localeCompare(b.attributes.leave_type.id.toString())
    );

    let sortedByName: LeaveRequestData[] = [];
    sortedByName.push(...data);
    sortedByName.sort((a, b) =>
      a.attributes.title.localeCompare(b.attributes.title)
    );

    return [sortedByDate, sortedByName, sortedByType];
  }

  sortBySwitcher(x: number) {
    this.sortByTab = x;
  }

  leaveDialog(leave: LeaveRequestData | unknown, active: boolean) {
    console.log("leaveDialog", leave, active);
  }

  deactivate(response: boolean, user_id: string) {
    // console.log(response, user_id);
    if (response) {
      axios
        .delete(`/leave-requests/${user_id}`)
        .then((response) => {
          // console.log(response);
          this.getUserByID();
          this.$store.dispatch("Add_Snackbar", {
            timeout: 3000,
            text: response.data.message,
            color: "green darken-2",
          });
        })
        .catch((error) => {
          // console.log(error.response);
          this.onError(error, "scroll-page");
        });
    } else {
      this.$store.dispatch("Add_Snackbar", {
        timeout: 3000,
        text: "Request Canceled",
        color: "orange darken-2",
      });
    }
  }

  clearSearch() {
    this.filterControls.search.data = "";
    this.searchFilter();
    console.log("cleareearch");
  }

  clearTitles() {
    this.filterControls.title.data = [];
    this.titleFilter();
    console.log("cleartitles");
  }

  searchFilter() {
    this.filterControls.search.status =
      this.filterControls.search.data.length > 0;
  }

  titleFilter() {
    this.filterControls.title.status =
      this.filterControls.title.data.length > 0;
    console.log(this.filterControls.title.data);
  }

  dialogClose(response: boolean, dialogType: string) {
    // console.log(response, dialogType, this.dialogs[dialogType]);
    this.dialogs[dialogType].status = response;
  }

  addRequest(response) {
    if (response.reload) {
      this.$store.dispatch("Add_Snackbar", {
        timeout: 6000,
        text: response.text,
        color: "green darken-2",
      });
      this.getUserByID();
    } else
      this.$store.dispatch("Add_Snackbar", {
        timeout: 3000,
        text: response.text,
        color: "orange darken-2",
      });
  }

  public async applyFilter() {
    if (this.leaveTab) {
      this.allLeaveCurrentPage = 1;
      this.allLeaveLastPage = 1;
      await this.getAllLeaveData(this.filterControls.search.data);
    } else {
      this.currentPage = 1;
      this.lastPage = 1;
      await this.getMyLeaveData(this.filterControls.search.data);
    }
    this.showFilters = false;
  }

  public async clearFilter() {
    this.filterControls.search.data = "";
    if (this.leaveTab) {
      await this.getAllLeaveData(this.filterControls.search.data);
    } else {
      await this.getMyLeaveData(this.filterControls.search.data);
    }
    this.showFilters = false;
  }

  private onError(error, scroll: string): void {
    this.errors = errorResponse(error, this.$store);
    const myElement: HTMLElement | null = document.getElementById(scroll);
    if (myElement != null) myElement.scrollIntoView();
  }

  mounted() {
    this.$store.dispatch("commitBASE");
    this.getUserByID();
    let leaveID = this.$router.currentRoute.params.id;
    // ID in path
    if (leaveID != undefined) {
      //open dialog to load leave
      this.uuid = leaveID;
    }
  }
} //end Dashboard Dialog
