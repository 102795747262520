import { render, staticRenderFns } from "./IconGoogle.vue?vue&type=template&id=df5b90de&scoped=true&"
import script from "./IconGoogle.vue?vue&type=script&lang=ts&"
export * from "./IconGoogle.vue?vue&type=script&lang=ts&"
import style0 from "./IconGoogle.vue?vue&type=style&index=0&id=df5b90de&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df5b90de",
  null
  
)

export default component.exports