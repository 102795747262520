










































































































































































































































































































































































































































































































// import { defineComponent } from "vue";
import UserData from "@/types/UserData";
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";
import BranchData from "@/types/BranchData";
import HolidayData from "@/types/HolidayData";

import ComponentAddHoliday from "@/components/body/admin/dialog-add-holiday.vue";
import ComponentDeleteConfirm from "@/components/reusable/DeleteConfirm.vue";
import errorResponse from "@/services/errorCode";

@Component({
  components: {
    DialogHolidayAdd: ComponentAddHoliday,
    DialogDelete: ComponentDeleteConfirm,
  },
})
export default class ComponentHolidays extends Vue {
  public dialog = false;
  public ready$ = false;
  public users: UserData[] = [];
  public errors: string[] = [];
  branches: BranchData[] = [];
  holidays: HolidayData[] = [];

  close() {
    this.dialog = false;
    this.$emit("rolechange", "Request Canceled");
  }

  getBranches(sort: string) {
    this.ready$ = false;
    axios
      .get(`/branches?sort=${sort}`)
      .then((response) => {
        this.branches = response.data.data;
        this.getHolidays();
      })
      .catch((error) => {
        this.ready$ = true;
        this.onError(error, "scroll-dialogs");
      });
  }

  getHolidays() {
    this.ready$ = false;
    axios
      .get("/holidays")
      .then((response) => {
        this.holidays = response.data.data;
        // console.log(this.holidays);
        this.ready$ = true;
      })
      .catch((error) => {
        this.ready$ = true;
        this.onError(error, "scroll-dialogs");
      });
  }

  addHoliday(response) {
    console.log("response");
    console.log(response);
    this.$store.dispatch("Add_Snackbar", {
      timeout: 3000,
      text: response.message,
      color: "green darken-2",
    });
    if (response.status) {
      this.getBranches("name");
    }
  }
  editHoliday(holiday: HolidayData) {
    console.log("edit", holiday);
  }

  deactivate(response: boolean, user_id: string) {
    // console.log(response, user_id);
    if (response) {
      axios
        .delete(`/holidays/${user_id}`)
        .then((response) => {
          // console.log(response);
          this.getHolidays();

          this.$store.dispatch("Add_Snackbar", {
            timeout: 3000,
            text: response.data.message,
            color: "green darken-2",
          });
        })
        .catch((error) => {
          this.onError(error, "scroll-dialogs");
        });
    } else {
      this.$store.dispatch("Add_Snackbar", {
        timeout: 3000,
        text: "Request Canceled",
        color: "orange darken-2",
      });
    }
  }

  private onError(error, scroll: string): void {
    this.errors = errorResponse(error, this.$store);
    const myElement: HTMLElement | null = document.getElementById(scroll);
    if (myElement != null) myElement.scrollIntoView();
  }

  mounted() {
    this.getBranches("name");
  }
}
