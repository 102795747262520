





































































































































































































































































































































































































































































































/* eslint-disable */
import Vue, { PropType } from "vue";
import Component from "vue-class-component";
import ComponentXImage from "@/components/reusable/XImage.vue";
import UserMeritsData from "@/types/UserMeritsData";
import ComponentViewMerit from "@/components/body/merits/dialog-view-merit.vue";

// Define the props by using Vue's canonical way.
const Props = Vue.extend({
  props: {
    restrict: Number,
    ready: Object,
    newMerits: {
      type: Array as PropType<UserMeritsData[]>,
    },
  },
});

@Component({
  components: {
    XImage: ComponentXImage,
    DialogView: ComponentViewMerit,
  },
})
export default class ComponentNewMerits extends Props {
  public dialog = false;

  toggleDialog(): void {
    this.dialog = !this.dialog;
  }
}
