







































import Vue, { PropType } from "vue";
import Component from "vue-class-component";
import TenantData from "@/types/TenantData";
const Props = Vue.extend({
  props: {
    restrict: Number,
    ready: Object,
    loading: Boolean,
    dialogVisible: Boolean,
    tenantData: {
      type: Array as PropType<TenantData[]>,
    },
    autoload: {
      type: Boolean,
      default: false,
    },
  },
});

@Component
export default class ComponentTenant extends Props {
  ready$ = false;
  public dialog = this.dialogVisible;

  callback(tenant: TenantData) {
    this.$store.commit("setBASE", {
      subdomain: tenant.attributes.domain,
      id: tenant.id,
      company: tenant.attributes,
    });
    this.$store.commit("setCompany", tenant.attributes.company);
    this.$store.commit("setCompanyCountry", tenant.attributes.country);
    this.$store.commit("commitBASE");
    this.$emit("callback", tenant);
  }
  closeCompanyDialog(): void {
    //const vueX = this.vuexState;
    this.$store.commit("setBASE", {
      subdomain: "",
      id: "",
    });
    this.$store.commit("commitBASE");
    this.$emit("closeCompanyDialog", true);
    // if (vueX) {
    //   localStorage.setItem("vuex", vueX);
    // }
    // this.$store.commit("showCompanyDialog", false);
    // window.location.reload();
  }
}
