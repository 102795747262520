









































































































// import { defineComponent } from "vue";
import router from "@/router";
import errorResponse from "@/services/errorCode";
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";

// Define the props by using Vue's canonical way.
const Props = Vue.extend({
  props: {
    quote: String,
  },
});

@Component
export default class ComponentImportTool extends Props {
  dialog = false;
  public errors: string[] = [];
  public form_valid = false;
  form: { file: File | undefined; quote: string } = {
    file: undefined,
    quote: "",
  };

  public rules = {
    required: (value) => !!value || "Required.",
  };
  public get userID$(): string {
    return this.$store.getters["getUserID"];
  }

  close(text: string, color: string, timeout: number) {
    this.form.file = undefined;
    this.dialog = false;
    this.$store.dispatch("Add_Snackbar", {
      timeout: timeout,
      text: text,
      color: color + " darken-2",
    });
  }
  removeAvatar() {
    this.form.file
      ? (this.form.file = undefined)
      : axios
          .get("profile/remove-avatar")
          .then((response) => {
            this.close(response.data.message, "green", 3000);
            this.form.file = undefined;
            router.go(0);
          })
          .catch((error) => {
            console.log(error.response);
            this.onError(error, "scroll-dialogs");
          });
  }
  submit() {
    const file = this.form.file;
    // console.log(file, this.userID$);
    console.log(this.form, this.userID$);
    if (file != undefined || this.form.quote != null) {
      console.log("submit", file);
      const data = new FormData();
      if (file) data.append("avatar", file);
      data.append("quote", this.form.quote);
      axios
        .post("/profile", data)
        .then((response) => {
          // console.log(response.data);
          this.close(response.data.message, "green", 3000);
          this.form.file = undefined;
          router.go(0);
        })
        .catch((error) => {
          this.onError(error, "scroll-dialogs");
        });
    } else {
      console.log("Empty", file);
      this.$store.dispatch("Add_Snackbar", {
        timeout: 6000,
        text: "No changes has been made",
        color: "red darken-2",
      });
    }
  }
  private onError(error, scroll: string): void {
    this.errors = errorResponse(error, this.$store);
    const myElement: HTMLElement | null = document.getElementById(scroll);
    if (myElement != null) myElement.scrollIntoView();
  }

  mounted() {
    this.form.quote = this.quote;
  }
}
