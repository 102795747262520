























































































































































































































































































































































































































































































































































































































































































































































































































































































// import { defineComponent } from "vue";
import Vue from "vue";
import Component from "vue-class-component";
import axios from "axios";
import UserData from "@/types/UserData";
import TitleData from "@/types/TitleData";
import BranchData from "@/types/BranchData";
import DepartData from "@/types/DepartData";
import UserAttributes, { LeaveDataType } from "@/types/UserAttributes";
import LeaveType from "@/types/LeaveType";
import router from "@/router";
import errorResponse from "@/services/errorCode";
import ComponentXImage from "@/components/reusable/XImage.vue";
import EmploymentType from "@/types/EmploymentType";
import PhoneInput from "@/components/reusable/PhoneInput.vue";
import { CountryCodeData } from "@/types/CountryCodeData";
import baseAPI from "@/services/BaseConfig";
import EmergencyContactComponent from "@/components/body/user/EmergencyContactComponent.vue";
import {
  EmergencyContact,
  EmergencyContactAttributes,
} from "@/types/EmergencyContact";

@Component({
  components: {
    XImage: ComponentXImage,
    PhoneInput: PhoneInput,
    EmergencyContactComponent: EmergencyContactComponent,
  },
})
export default class ComponentUserEdit extends Vue {
  public isLoading$ = true;
  public mobile: boolean = window.innerWidth > 1000;
  public progress_value = 0;
  public default_panel = 0;
  public currencies = ["TTD", "USD", "JMD", "GYD", "CAD", "GBP", "EUR"];

  public panelChange(x: number) {
    this.default_panel = x;
  }

  public countryCodes: CountryCodeData[] = [];

  private _selected_user: UserData = {
    attributes: {
      aba_number: "",
      address_line_1: "",
      address_line_2: "",
      city: null,
      state: null,
      postal_code: null,
      avatar: "",
      bank_account_holder: "",
      bank_account_number: "",
      bank_branch: "",
      bank_name: "",
      branch: "",
      branch_id: "",
      created_at: "",
      date_of_birth: "",
      department_id: "",
      email: "",
      emergency_contact_1: "",
      emergency_contact_1_name: "",
      emergency_contact_2: "",
      emergency_contact_2_name: "",
      employee_title: "",
      employee_title_id: "",
      employment_type_id: null,
      end_date: "",
      first_name: "",
      form_id_1: "",
      form_id_2: "",
      gender: "",
      last_name: "",
      leave: [],
      merits: [],
      local_tax_id: "",
      national_health_insurance_number: "",
      phone: "",
      phone_country_code_id: null,
      previous_employer: "",
      push_service_token: "",
      quote: "",
      reporting_manager_id: "",
      salary: null,
      currency: null,
      skype: "",
      start_date: "",
      swift_code: "",
      updated_at: "",
      user_status_id: "",
      whatsapp: "",
      whatsapp_country_code_id: null,
      can_take_sick_leave: false,
      can_take_vacation: false,
      merit_limit: null,
    },
    id: "",
    links: {
      self: "",
    },
    meta: {},
    relationships: {},
    type: "",
  };

  public noLimitCheck(e) {
    if (e == true) {
      this.formdata.employment.merit_limit = null;
    } else {
      this.formdata.employment.merit_limit = 0;
    }
  }

  private set selected_user(value: UserData) {
    this._selected_user = value;
  }
  private get selected_user(): UserData {
    return this._selected_user;
  }

  public get selected_user_attributes(): UserAttributes {
    // console.log(this.selected_user);
    return this._selected_user.attributes;
  }

  public get restrict$(): number {
    return this.$store.getters["getRestricted"];
  }

  public get user$(): UserAttributes {
    return this.$store.getters["getSelfAttributes"];
  }
  public get isLoggedIn$(): boolean {
    return this.$store.getters["isAuthenticated"];
  }
  public get userID$(): string {
    return this.$store.getters["getUserID"];
  }

  public get restriction$(): number {
    return this.$store.getters["getRestricted"];
  }

  // fixedReady = false;

  public formdata: {
    file: {
      avatar: File | string;
      form_id_1: File | string;
      form_id_2: File | string;
    };
    user;
    employment;
    financial;
    leave;
    valid: {
      user: boolean;
      employment: boolean;
      financial: boolean;
      leave: boolean;
      file: boolean;
    };
  } = {
    user: {
      email: "",
      first_name: "",
      last_name: "",
      phone: "",
      whatsapp: "",
      skype: "",
      emergency_contact_1: "",
      emergency_contact_1_name: "",
      emergency_contact_2: "",
      emergency_contact_2_name: "",
      gender: "",
      date_of_birth: "",
      address_line_1: "",
      address_line_2: "",
    },
    employment: {
      start_date: "",
      branch_id: "",
      department_id: "",
      employee_title_id: "",
      reporting_manager_id: "",
      previous_employer: "",
      is_manager: "",
      is_default_manager: "",
      employment_type_id: null,
      merit_limit: null,
    },
    financial: {
      salary: "",
      local_tax_id: "",
      national_health_insurance_number: "",
      bank_name: "",
      bank_branch: "",
      bank_account_holder: "",
      bank_account_number: "",
      aba_number: null,
      swift_code: null,
      currency: null,
    },
    leave: {
      can_take_sick_leave: "",
      can_take_vacation: "",
      vacation_start_date: "",
      // leave_bereavement: 0,
      // leave_casual: 0,
      // leave_maternity: 0,
      // leave_study: 0,
      // leave_sick: 0,
      // leave_vacation: 0,
      // eslint-disable-next-line @typescript-eslint/no-array-constructor
      leave: {},
    },
    file: {
      avatar: "",
      form_id_1: "",
      form_id_2: "",
    },
    valid: {
      user: false,
      employment: false,
      financial: false,
      leave: false,
      file: false,
    },
  };
  public get formValid() {
    return (
      this.formdata.valid.employment &&
      this.formdata.valid.user &&
      this.formdata.valid.financial &&
      this.formdata.valid.leave
    );
  }
  public errors: string[] = [];
  public date_dob = false;
  public date_start = false;
  public date_vacation = false;
  public leaveOptions = [
    {
      text: "Eligible",
      value: "1",
    },
    {
      text: "Not Eligible",
      value: "0",
    },
  ];
  // it is an array
  // not all values will show
  // leave id must be stored to match the total
  // edit user must show all types regardless of what is provided
  // how will we know what to add?
  // how will I know all leave types?
  // Can we add leave types? if yes this funcationality must be modular
  // I can but the endpoints will have to be seperate
  // one, leave is in user, this provides what is available
  // two, leave by user, this might need a active endpoint.
  public rules = {
    required: (value) => !!value || "Required.",
    counter: (value) => value.length <= 20 || "Max 20 characters",
    email: (value) => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail.".toUpperCase;
    },
    equals: (value) => {
      return value === this.formdata.user.email || "Emails must match";
    },
    vacationStartDate: (value) => {
      if (this.formdata.leave.can_take_vacation == "1")
        return !!value || "Required.";
      else return true;
    },
    abaNumber: (value) => {
      if (value == null || value == "") return true;
      if (value < 0) return "Must be a positive number";
      if (value.length != 9) return "Must be 9 digits";
    },
    swiftCode: (value) => {
      if (value == null || value == "") return true;
      if (value.length != 8) return "Must be 8 characters";
    },
    validDate: (value) => {
      const pattern = /^\d{4}-\d{2}-\d{2}$/;
      if (value == null || value == "") return true;
      //is date
      if (isNaN(Date.parse(value))) return "Must be a valid date";
      //regex date must be in format yyyy-mm-dd
      if (!pattern.test(value)) return "Must be in format yyyy-mm-dd";
      return true;
    },
  };

  public branches: BranchData[] = [];
  public titles: TitleData[] = [];
  public departments: DepartData[] = [];
  public managers: UserData[] = [];
  public UserLeave: {
    attributes: {
      color: string;
      type: string;
      updated_at: string;
    };
    total_days?: number | string;
    id: string;
  }[] = [];
  public roles = "";
  public emergencyContacts: EmergencyContactAttributes[] = [];

  filterDepartments: { loading: boolean; data: DepartData[] } = {
    loading: true,
    data: [],
  };

  filterManagers: { loading: boolean; data: UserData[] } = {
    loading: true,
    data: [],
  };

  filterTitles: { loading: boolean; data: TitleData[] } = {
    loading: true,
    data: [],
  };

  employmentTypes: { loading: boolean; data: EmploymentType[] } = {
    loading: true,
    data: [],
  };

  updateEmergencyContact(contact: EmergencyContactAttributes, index: number) {
    this.emergencyContacts[index] = contact;
    console.log(this.emergencyContacts);
  }

  parseDate(date: string) {
    if (!date) return "";

    const [year, month, day] = date.split("-");
    return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}`;
  }
  private setUserForm(value: UserAttributes, leaves) {
    // console.log(value);
    console.log("setUserForm", value);
    this.formdata.user = {
      email: value.email,
      first_name: value.first_name,
      last_name: value.last_name,
      phone_country_code_id: value.phone_country_code_id,
      phone: value.phone,
      whatsapp: value.whatsapp,
      whatsapp_country_code_id: value.whatsapp_country_code_id,
      skype: value.skype,
      emergency_contact_1: value.emergency_contact_1,
      emergency_contact_1_name: value.emergency_contact_1_name,
      emergency_contact_2: value.emergency_contact_2,
      emergency_contact_2_name: value.emergency_contact_2_name,
      gender:
        value.gender == null
          ? null
          : value.gender.charAt(0).toUpperCase() + value.gender.slice(1),
      date_of_birth:
        value.date_of_birth == null ? null : value.date_of_birth.slice(0, 10),
      address_line_1: value.address_line_1,
      address_line_2: value.address_line_2,
      city: value.city,
      state: value.state,
      postal_code: value.postal_code,
    };

    this.formdata.financial = {
      aba_number: value.aba_number,
      swift_code: value.swift_code,
      local_tax_id: value.local_tax_id,
      national_health_insurance_number: value.national_health_insurance_number,
      bank_name: value.bank_name,
      bank_branch: value.bank_branch,
      bank_account_holder: value.bank_account_holder,
      bank_account_number: value.bank_account_number,
    };

    if (this.restrict$ < 3) {
      this.formdata.leave = {
        can_take_sick_leave: value.can_take_sick_leave ? "yes" : "no",
        can_take_vacation: value.can_take_vacation ? "yes" : "no",
        // vacation_start_date: new Date().toISOString().slice(0, 10),
        vacation_start_date: value.vacation_latest_start_date,
        leave: leaves,
      };
      this.formdata.employment = {
        start_date: value.start_date.slice(0, 10),
        // end_date: value.end_date != null ? value.end_date.slice(0, 10) : "",
        branch_id: value.branch_id,
        department_id: value.department_id,
        employee_title_id: value.employee_title_id,
        reporting_manager_id: value.reporting_manager_id,
        previous_employer: value.previous_employer,
        is_manager: false,
        is_default_manager: false,
        employment_type_id: value.employment_type_id,
        merit_limit: value.merit_limit,
        no_limit: value.merit_limit == null ? true : false,
      };
      this.formdata.financial.salary = value.salary;
      this.formdata.financial.currency = value.currency;
    }

    this.formdata.file = {
      avatar: value.avatar,
      form_id_1: value.form_id_1,
      form_id_2: value.form_id_2,
      // resume: "",
    };

    this.formdata.valid = {
      user: false,
      employment: false,
      financial: false,
      leave: false,
      file: false,
    };

    console.log("completed");
    // console.log("setUserForm", this.formdata);
  }
  private setLeaveTypes(uLeave) {
    var val = {};
    uLeave.forEach((leave) => {
      val[`assigned_leave[${leave.id}]`] = {
        leave_type_id: leave.id,
        total_days: leave.total_days,
      };
      // console.log(val["leave_type" + leave.id]);
    });
    // console.log("setLeaveTypes");
    // console.log(uLeave);

    // console.log(JSON.stringify(val));
    return val;
  }
  private getLeaveTotal(types: LeaveDataType[], leave) {
    // console.log(types, leave.attributes.type);

    const index = types.findIndex(
      (type) => type.leave_type === leave.attributes.type
    );
    return index > -1
      ? { ...leave, total_days: types[index].total_days }
      : { ...leave, total_days: 0 };
  }
  private getUpdatedLeave() {
    const updatedLeave: any[] = [];
    Object.values(this.formdata.leave.leave).forEach((element) => {
      updatedLeave.push(element);
    });
    return updatedLeave;
  }
  private async getUserByID(uuid: string) {
    // console.log(uuid);
    var tempUser;
    this.progress_value = 0;
    await axios
      .get("/users/" + uuid)
      .then(async (response) => {
        this.progress_value = 20;
        console.log(response.data.data);
        this.selected_user = response.data.data;
        tempUser = this.selected_user;
        // console.log("selected user", this.selected_user);
        await axios.get("/leave-types").then(async (response) => {
          response.data.data.forEach((typeOfLeave: LeaveType) => {
            this.UserLeave.push(
              this.getLeaveTotal(
                this.selected_user.attributes.leave,
                typeOfLeave
              )
            );
          });
          this.setUserForm(
            this.selected_user.attributes,
            this.setLeaveTypes(this.UserLeave)
          );
          // console.log(this.formdata.leave.leave);
          // console.log(this.UserLeave);
          console.log("titles");
          await axios
            .get("/employee-titles?paginate=false")
            .then(async (response) => {
              console.log(response.data.data, "TITLES");
              this.progress_value = 50;
              this.titles = response.data.data;
              this.filterTitles = {
                data: this.titles,
                loading: false,
              };
              this.titles.sort((a, b) =>
                a.attributes.name.localeCompare(b.attributes.name)
              );

              await axios
                .get("/branches?paginate=false")
                .then(async (response) => {
                  // console.log("get_all_branches", response.data);
                  this.progress_value = 70;
                  this.branches = response.data.data;
                  this.branches.sort((a, b) =>
                    a.attributes.name.localeCompare(b.attributes.name)
                  );

                  // console.log(this.allSortedUsers);
                  await axios
                    .get("/departments?paginate=false")
                    .then(async (response) => {
                      // console.log("Departments", response.data.data);
                      this.progress_value = 90;
                      this.departments = response.data.data;
                      this.filterDepartments = {
                        data: this.departments,
                        loading: false,
                      };
                      await axios
                        .get(
                          "/users?filter[roles]=manager,admin&paginate=false"
                        )
                        .then(async (response) => {
                          // console.log("Departments", response.data.data);
                          this.progress_value = 95;
                          this.filterManagers = {
                            data: response.data.data,
                            loading: false,
                          };
                          console.log(this.filterManagers.data);
                        });
                      await axios
                        .get("/employment-types")
                        .then(async (response) => {
                          this.progress_value = 99;
                          this.employmentTypes = {
                            data: response.data.data,
                            loading: false,
                          };

                          console.log("employment types", this.employmentTypes);
                        });
                      this.branchSelected(
                        this.selected_user.attributes.branch_id
                      );
                      this.departmentSelected(
                        this.selected_user.attributes.department_id
                      );
                      this.formdata.employment.department_id =
                        tempUser.attributes.department_id;
                      this.formdata.employment.employee_title_id =
                        tempUser.attributes.employee_title_id;
                      this.progress_value = 100;
                      this.isLoading$ = false;
                    }); // get every department
                }); //get_all_branches
            }); // getalltitles
        });

        // console.log(this.selected_user);
        // console.log(this.selected_user_attributes);

        // console.log(this.selected_user.attributes);
      })
      .catch((error) => {
        console.log(error);
        console.log(error.response);
      }); // getall users
  }
  private async getUserEmergencyContacts(uuid: string) {
    await axios.get(`/user/emergency-contacts/${uuid}`).then((response) => {
      if (response.data.success == true) {
        console.log(response.data.data);
        response.data.data.forEach((element: EmergencyContact) => {
          this.emergencyContacts.push(element.attributes);
        });
      }
    });
  }

  branchSelected(value: string) {
    // console.log("BranchID", value);

    this.formdata.employment.department_id = "";
    this.formdata.employment.employee_title_id = "";
    this.filterTitles.loading = true;
    this.filterDepartments.loading = true;
    this.filterTitles.data = [];
    this.filterDepartments.data = [];

    this.departments.forEach((department) => {
      // console.log(title.attributes.depertment_id, value);
      if (department.attributes.branch_id == value)
        this.filterDepartments.data.push(department);
    });
    this.filterTitles.loading = false;
    this.filterDepartments.loading = false;
    console.table(this.filterDepartments.data);
  } // branch Selected

  departmentSelected(value) {
    this.formdata.employment.employee_title_id = "";
    this.filterTitles.loading = true;
    this.filterTitles.data = [];
    this.titles.forEach((title) => {
      if (title.attributes.department_id == value)
        this.filterTitles.data.push(title);
    });

    this.filterTitles.loading = false;
  } // department selected

  private myEventHandler() {
    // your code for handling resize...
    this.mobile = window.innerWidth > 1000;
  }

  public async updateUser() {
    console.log("Update User");
    console.log(this.emergencyContacts);
    let data = {
      ...this.formdata.user,
      ...this.formdata.financial,
      emergency_contacts: this.emergencyContacts,
      // ...this.formdata.leave.leave,
      // ...this.formdata.file,
    };
    if (this.restrict$ < 3) {
      data = {
        ...this.formdata.user,
        ...this.formdata.financial,
        ...this.formdata.employment,
        emergency_contacts: this.emergencyContacts,
      };
      data.vacation_start_date = this.formdata.leave.vacation_start_date;
      data.can_take_sick_leave =
        this.formdata.leave.can_take_sick_leave == "yes";
      data.can_take_vacation = this.formdata.leave.can_take_vacation == "yes";
      data.assigned_leave = this.getUpdatedLeave();
    }
    console.log(data);
    this.getUpdatedLeave();
    await axios
      .put(`users/${this.$route.params.id}`, data)
      .then((response) => {
        console.log(response);
        this.errors = [];
        this.$store.dispatch("Add_Snackbar", {
          timeout: 4000,
          text: response.data.message,
          color: "green darken-2",
        });

        router.push("/users");
      })
      .catch((error) => {
        this.onError(error, "scroll-page");
      });
    // }
  }

  private async getCountries() {
    await baseAPI({ central: true })
      .get("/country-codes")
      .then((response) => {
        this.countryCodes = response.data.data;
      });
  }

  created() {
    window.addEventListener("resize", this.myEventHandler);
  }

  private onError(error, scroll: string): void {
    this.errors = errorResponse(error, this.$store);
    const myElement: HTMLElement | null = document.getElementById(scroll);
    if (myElement != null) myElement.scrollIntoView();
  }

  mounted() {
    this.$store.dispatch("commitBASE");
    this.isLoading$ = true;

    this.getCountries();
    this.getUserByID(this.$route.params.id);
    this.getUserEmergencyContacts(this.$route.params.id);
  }
} //end Dashboard Dialog
