var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"500","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on_dialog = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","block":"","left":""}},'v-btn',{ attrs: attrs },false),on_dialog),[_c('fa-icon',{staticClass:"dropdown-icon",attrs:{"icon":"file"}}),_vm._v(" Review ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 text-white orange accent-4 mb-3",staticStyle:{"word-break":"break-word"}},[_vm._v("Review Leave")]),_c('v-card-text',[_c('v-row',{staticClass:"mt-3",attrs:{"id":"scroll-dialogs"}},_vm._l((_vm.errors),function(error,index){return _c('v-alert',{key:index,attrs:{"dismissible":"","dense":"","color":"red darken-1","dark":"","type":"error"}},[_vm._v(" "+_vm._s(error)+" ")])}),1),(_vm.leave.attributes.clashes.length > 0)?_c('v-row',[_c('div',{staticClass:"d-flex flex-row justify-content-end"},[_c('DialogViewClash',{attrs:{"xData":{
                fullscreen: true,
                clashes: _vm.leave.attributes.clashes,
              }}})],1)]):_vm._e(),_c('v-row',[_c('h2',{staticClass:"align-center"},[_vm._v(" "+_vm._s(_vm.leave.attributes.title)+" "),_c('hr')])]),_c('v-row',{staticClass:"sub-title"},[_c('h6',[_c('span',[_vm._v("Requested By: ")]),_c('span',[_vm._v(_vm._s(_vm.leave.attributes.user.name))])]),_c('h6',[_c('span',[_vm._v("Description: ")]),_c('span',[_vm._v(_vm._s(_vm.leave.attributes.description))])])]),_c('v-row',{staticClass:"leave-details"},[_c('div',{staticClass:"leave-status"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"btn btn-pending",style:({
                    color:
                      _vm.leave.attributes.leave_status.id == 2
                        ? '#67ad5b'
                        : _vm.leave.attributes.leave_status.id == 3
                        ? '#e25241'
                        : '#707070',
                  })},'button',attrs,false),on),[(_vm.leave.attributes.leave_status.id == 1)?_c('fa-icon',{staticClass:"fas",attrs:{"icon":"clock"}}):(_vm.leave.attributes.leave_status.id == 2)?_c('fa-icon',{staticClass:"fas btn-approved",attrs:{"icon":"check"}}):(_vm.leave.attributes.leave_status.id == 3)?_c('fa-icon',{staticClass:"fas btn-denied",attrs:{"icon":"times"}}):(_vm.leave.attributes.leave_status.id == 4)?_c('fa-icon',{staticClass:"fas",attrs:{"icon":"hourglass"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.leave.attributes.leave_status.status)+" ")],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.leave.attributes.leave_status.status))])])],1),_c('div',{staticClass:"leave-type"},[_c('button',{staticClass:"btn",style:({
                color: '#' + _vm.leave.attributes.leave_type.color,
              })},[_c('img',{attrs:{"src":_vm.leave.attributes.leave_type.type,"alt":""}}),_c('span',[_vm._v(" Leave: "+_vm._s(_vm.leave.attributes.leave_type.type))])])])]),_c('v-row',{staticClass:"leave-area row shadow mb-3 pt-2 pb-2 pl-2 pr-2"},[_c('h4',{staticClass:"leave-title mb-2"},[_vm._v(" Requested "+_vm._s(_vm.leave.attributes.total_days)+" Dates "),_c('hr')]),_vm._l((_vm.leaves),function(leave,index){return _c('div',{key:index,staticClass:"leave"},[_c('h6',[_vm._v(" "+_vm._s(leave.start_date.slice(0, 10))+" - "+_vm._s(leave.end_date.slice(0, 10))+" ")]),_c('h6',[_vm._v(_vm._s(leave.number_days)+" Days")])])})],2),_c('v-row',{staticClass:"mt-6 mb-6"},[_c('v-textarea',{attrs:{"rows":"2","rules":[_vm.emptyRules],"label":"Comment","disabled":true},model:{value:(_vm.form.comment),callback:function ($$v) {_vm.$set(_vm.form, "comment", $$v)},expression:"form.comment"}})],1),(_vm.leave.attributes.media != '')?_c('v-row',[_c('h4',{staticClass:"leave-title mb-2"}),_vm._v(" Attached Media "),_c('hr'),_c('div',{staticClass:"d-flex align-items-center justify-content-between mt-3"},[_c('div',[_vm._v("File: "+_vm._s(_vm.getFileNameFromUrl(_vm.leave.attributes.media)))]),_c('v-btn',{staticClass:"font-weight-bold ml-3",style:({
                color: '#' + _vm.leave.attributes.leave_type.color,
              }),attrs:{"outlined":"","small":""},on:{"click":_vm.openMedia}},[_c('fa-icon',{staticClass:"mr-1",attrs:{"icon":"file-download"}}),_vm._v(" Download ")],1)],1)]):_vm._e()],1),_c('v-card-actions',[_c('v-row',[_c('v-col',{attrs:{"cols":"3","md":"4"}}),_c('v-col',{attrs:{"cols":"3","md":"4"}}),_c('v-col',{attrs:{"cols":"6","md":"4"}},[_c('v-btn',{staticClass:"font-weight-bold w-100 w-md-auto float-right",attrs:{"dark":"","color":"orange darken-3"},on:{"click":_vm.clearForm}},[_vm._v("Cancel")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }