












































































































































































































































// import { defineComponent } from "vue";
import UserData from "@/types/UserData";
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";

import CompomentAttachmentAdd from "./dialog-attachment-add.vue";
import ComponentDeleteConfirm from "@/components/reusable/DeleteConfirm.vue";
import AttachmentData from "@/types/AttachmentData";

import { saveAs } from "file-saver";
import errorResponse from "@/services/errorCode";
const Props = Vue.extend({
  props: {
    user_id: String,
  },
});
@Component({
  components: {
    DialogAttachmentAdd: CompomentAttachmentAdd,
    DialogDelete: ComponentDeleteConfirm,
  },
})
export default class ComponentAttachments extends Props {
  public dialog = false;
  public ready$ = false;
  public users: UserData[] = [];
  public errors: string[] = [];
  public collections: { collection: string; media: AttachmentData[] }[] = [];

  close() {
    this.dialog = false;
    this.$emit("rolechange", "Request Canceled");
  }
  capitalize = (s: string) => (s && s[0].toUpperCase() + s.slice(1)) || "";
  truncate = (s: string, number?: number) => {
    number = !number ? 20 : number;
    return s.length > number
      ? `${s.slice(0, number - 6)}...${s.slice(s.length - 3, s.length)}`
      : s;
  };
  convertSize = (s: number) => {
    return s > 1048576
      ? `${(s / 1048576).toFixed(2)}mb`
      : s > 1024
      ? `${(s / 1024).toFixed(2)}kb`
      : s;
  };
  getAttachments(user_id: string) {
    this.ready$ = false;
    console.log("UserID", user_id);

    axios
      .get("/collections")
      .then((response) => {
        this.errors = [];
        this.collections = [];
        // console.log(response.data);

        const collectionList: string[] = response.data.data;
        collectionList.forEach((collection) => {
          axios
            .get(`/users/${user_id}/media?collection=${collection}`)
            .then((response) => {
              console.log(collection, response.data);
              this.collections.push({
                collection: collection,
                media: response.data.data,
              });
              this.errors = [];
            })
            .catch((error) => {
              this.$store.dispatch("Add_Snackbar", {
                timeout: 6000,
                text: error.response.data.message,
                color: "red darken-2",
              });

              if (error.response.data.errors.length > 0)
                this.errors = error.response.data.errors;
              else this.errors = error.response.data.message;
              const myElement: HTMLElement | null =
                document.getElementById("scroll-add-user");
              if (myElement != null) myElement.scrollIntoView();
              console.log(error.response);
            });
        }); //collectionList For Each
        this.ready$ = true;
      })
      .catch((error) => {
        this.$store.dispatch("Add_Snackbar", {
          timeout: 6000,
          text: error.response.data.message,
          color: "red darken-2",
        });

        if (error.response.data.errors.length > 0)
          this.errors = error.response.data.errors;
        else this.errors = error.response.data.message;
        const myElement: HTMLElement | null =
          document.getElementById("scroll-add-user");
        if (myElement != null) myElement.scrollIntoView();
        console.log(error.response);
      });
  }

  addAttachment(response) {
    // console.log("response");
    // console.log(response);
    this.$store.dispatch("Add_Snackbar", {
      timeout: response.reload ? 3000 : 6000,
      text: response.text,
      color: response.reload ? "green darked-2" : "red darken-2",
    });

    if (response.reload) {
      console.log(response);
      this.getAttachments(this.user_id);
    }
  }
  editBranch() {
    console.log("edit");
  }

  deactivate(response: boolean, attachment: AttachmentData) {
    console.log(
      `Delete: ${response}`,
      attachment,
      `/users/${this.user_id}/attachments/${attachment.id}`
    );
    if (response) {
      axios
        .delete(`/users/${this.user_id}/attachments/${attachment.id}`)
        .then((response) => {
          console.log(response);
          this.errors = [];
          this.getAttachments(this.user_id);
          this.$store.dispatch("Add_Snackbar", {
            timeout: 3000,
            text: response.data.message,
            color: "green darken-2",
          });
        })
        .catch((error) => {
          this.onError(error, "scroll-dialogs");
        });
    } else {
      this.$store.dispatch("Add_Snackbar", {
        timeout: 6000,
        text: "Request Canceled",
        color: "orange darken-2",
      });
    }
  }

  download(file: AttachmentData) {
    // console.log(file);
    axios({
      url: file.attributes.url, //your url
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      var data = new File(
        [response.data],
        `${file.attributes.name}.${file.attributes.extension}`,
        { type: response.data.type }
      );
      // return data;
      // console.log(data);
      saveAs(data);
    });
  }

  private onError(error, scroll: string): void {
    this.errors = errorResponse(error, this.$store);
    const myElement: HTMLElement | null = document.getElementById(scroll);
    if (myElement != null) myElement.scrollIntoView();
  }

  mounted() {
    this.getAttachments(this.user_id);
  }
}
