var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","width":"550"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var props = ref.props;
return [(_vm.restrict <= 3)?_c('button',_vm._b({staticClass:"plate plate-btn button3",on:{"click":_vm.toggleDialog}},'button',{ props: props },false),[_c('div',[_c('p',{staticClass:"plate-title"},[_vm._v("New merits awarded this week")]),_c('p',{staticClass:"plate-data"},[_vm._v(" "+_vm._s(_vm.newMerits.length)+" ")]),_c('div',{staticClass:"triangle"})])]):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"rounded":"0"}},[_c('v-toolbar',{attrs:{"dark":"","color":"#f15a29"}},[_c('v-toolbar-title',[_vm._v("Merits Awarded this Week")])],1),_c('v-card-text',[_c('div',{staticClass:"div col-12"},[(_vm.newMerits.length == 0)?_c('p',{staticStyle:{"font-size":"26px","font-weight":"bold","color":"#707070","text-align":"center"}},[_vm._v(" No Merits ")]):_vm._e(),(_vm.newMerits.length > 0)?_c('ul',{staticClass:"list-group pl-0"},_vm._l((_vm.newMerits),function(merit){return _c('li',{key:merit.id,staticClass:"list-group-item shadow-sm d-flex flex-md-row flex-column justify-content-between align-items-center mb-3 pl-4 pr-4 pt-2 pb-2",style:({
              'border-left': 'solid 6px #' + merit.attributes.merit.color,
              'border-top': 'solid 1px #' + merit.attributes.merit.color,
            })},[_c('div',{staticClass:"float-left"},[_c('h5',{staticClass:"list-group-item-heading mb-1"},[_c('span',[_vm._v("Title: ")]),_vm._v(_vm._s(merit.attributes.description)+" ")]),_c('h5',{staticClass:"list-group-item-heading"},[_c('span',[_vm._v("Name: ")]),_vm._v(_vm._s(merit.attributes.user)+" ")]),_c('div',{staticClass:"list-group-g"},[_c('h6',{staticClass:"list-group-item-text mb-0"},[_vm._v(" Date Submitted: "+_vm._s(merit.attributes.created_at.slice(0, 10))+" ")])])]),_c('div',{staticClass:"float-right"},[_c('v-tooltip',{attrs:{"bottom":"","color":"warning"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('fa-icon',_vm._g(_vm._b({staticClass:"star",attrs:{"icon":"star","color":'#' + merit.attributes.merit.color}},'fa-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(merit.attributes.merit.name))])])],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"btn btn-options",attrs:{"role":"button","id":"dropdownMenuLink"}},'button',attrs,false),on),[_c('fa-icon',{staticClass:"fa-menu-btn fas",attrs:{"icon":"ellipsis-v"}})],1)]}}],null,true)},[_c('v-list',[_c('DialogView',{attrs:{"merit-id":merit.id}})],1)],1)],1)}),0):_vm._e()])]),_c('v-card-actions',{staticClass:"pa-3"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.toggleDialog}},[_vm._v(" close ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }