




















































































































































































































































// import { defineComponent } from "vue";
import errorResponse from "@/services/errorCode";
import CountryData from "@/types/CountryData";
import HolidayData from "@/types/HolidayData";
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";

const Props = Vue.extend({
  props: {
    mode: Boolean,
    holiday: Object,
    branches: Array,
  },
});

@Component
export default class ComponentAddHoliday extends Props {
  public dialog = false;
  public ready$ = false;
  public errors: string[] = [];
  countries: CountryData[] = [];
  public form: {
    name: string;
    country: string;
    branch: string;
    adhoc: string;
    date: string;
    image: string | File;
    valid: boolean;
  } = {
    name: "",
    country: "",
    branch: "",
    adhoc: "",
    date: "",
    image: "",
    valid: false,
  };

  emptyRules = [(v) => !!v || "Required"];

  public get isEmptyForm(): boolean {
    return !!this.form.name && !!this.form.country && !!this.form.date;
  }

  datepicker = {
    date: "",
    menu2: false,
  };
  get computedDateFormatted() {
    return this.formatDate(this.datepicker.date);
  }
  formatDate(date) {
    if (!date) return null;

    const [year, month, day] = date.split("-");
    return `${year}/${month}/${day}`;
  }
  parseDate(date) {
    if (!date) return null;

    const [month, day, year] = date.split("/");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  }

  closeDelete() {
    this.form = {
      name: "",
      country: "",
      branch: "",
      adhoc: "",
      date: "",
      image: "",
      valid: false,
    };
    this.dialog = false;
    this.$store.dispatch("Add_Snackbar", {
      timeout: 3000,
      text: "Request Canceled",
      color: "orange darken-2",
    });
  }
  editClose() {
    this.dialog = false;
    this.$store.dispatch("Add_Snackbar", {
      timeout: 3000,
      text: "Request Canceled",
      color: "orange darken-2",
    });
  }
  add() {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      const data: FormData = new FormData();
      console.log(this.datepicker.date);
      const [year, month, day] = this.datepicker.date.split("-");
      console.log(year);

      data.append("name", this.form.name);
      data.append("month", parseInt(month).toString());
      data.append("day", parseInt(day).toString());
      data.append("is_adhoc_holiday", this.form.adhoc ? "1" : "0");
      data.append("same_date_annually", this.form.adhoc ? "0" : "1");
      data.append("country_id", this.form.country);
      data.append("branch_id", this.form.branch);
      data.append("image", this.form.image);

      axios
        .post("/holidays", data)
        .then((response) => {
          // console.log(response.data);
          this.dialog = false;
          this.$emit("addholiday", {
            status: true,
            message: response.data.message,
          });
        })
        .catch((error) => {
          this.onError(error, "scroll-dialogs");
        });
    } else {
      this.$store.dispatch("Add_Snackbar", {
        timeout: 3000,
        text: "Please ensure all fields are filled",
        color: "orange darken-2",
      });
    }
  }
  edit() {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      // const data: FormData = new FormData();
      const [year, month, day] = this.datepicker.date.split("-");
      console.log(year);

      const data = {
        name: this.form.name,
        month: parseInt(month).toString(),
        day: parseInt(day).toString(),
        is_adhoc_holiday: this.form.adhoc ? "1" : "0",
        same_date_annually: this.form.adhoc ? "0" : "1",
        country_id: this.form.country,
        branch_id: this.form.branch.toString(),
      };
      // console.log(this.datepicker.date);

      // data.append("name", this.form.name);
      // data.append("month", parseInt(month).toString());
      // data.append("day", parseInt(day).toString());
      // data.append("is_adhoc_holiday", this.form.adhoc ? "1" : "0");
      // data.append("same_date_annually", this.form.adhoc ? "0" : "1");
      // data.append("country_id", this.form.country);
      // data.append("branch_id", this.form.branch.toString());
      // console.log(this.form);

      axios
        .put(`/holidays/${this.holiday.id}`, data)
        .then((response) => {
          this.closeDelete();
          // console.log(response.data);
          this.$store.dispatch("Add_Snackbar", {
            timeout: 6000,
            text: response.data.message,
            color: "green darken-2",
          });
        })
        .catch((error) => {
          this.onError(error, "scroll-dialogs");
        });
    } else {
      this.$store.dispatch("Add_Snackbar", {
        timeout: 6000,
        text: "Please ensure all fields are filled",
        color: "red darken-2",
      });
    }
  }

  getCounties() {
    this.ready$ = false;
    axios
      .get("/countries")
      .then((response) => {
        this.countries = response.data.data;
        console.log(this.countries);
        this.ready$ = true;
      })
      .catch((error) => {
        this.ready$ = true;
        this.onError(error, "scroll-dialogs");
      });
  }
  onError(error, scroll: string): void {
    this.errors = errorResponse(error, this.$store);
    const myElement: HTMLElement | null = document.getElementById(scroll);
    if (myElement != null) myElement.scrollIntoView();
  }

  mounted() {
    this.getCounties();
    if (this.mode) {
      const h: HolidayData = this.holiday;
      console.log(h);

      this.form = {
        name: h.attributes.name,
        country: h.attributes.country,
        branch: h.attributes.branch.id,
        adhoc: h.attributes.is_adhoc_holiday ? "1" : "0",
        date: h.attributes.date,
        image: h.attributes.image,
        valid: false,
      };
      this.datepicker.date = h.attributes.date;
    }
  }
}
