








































import Vue, { PropType } from "vue";
import Component from "vue-class-component";
import { CountryCodeData } from "../../types/CountryCodeData";

const Data = Vue.extend({
  props: {
    countries: {
      type: Array as PropType<CountryCodeData[]>,
      required: true,
    },
    inputCode: Number,
    inputNumber: String,
    name: {
      type: String,
      default: "Phone Number",
    },
  },
});

@Component
export default class PhoneInput extends Data {
  rules = {
    required: (value) => {
      return value == "" || value == null ? "Required." : true;
    },
  };

  codes(item: CountryCodeData) {
    return `${item.name} ${item.phone_code}`;
  }
}
