var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"500","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on_dialog = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on_tool = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"font-weight-bold",attrs:{"dark":"","color":"orange darken-3"}},'v-btn',{ attrs: attrs },false),Object.assign({}, on_tool, on_dialog)),[_vm._v("New "+_vm._s(_vm.title))])]}}],null,true)},[_c('span',[_vm._v("Add a new "),_c('span',{staticClass:"text-lowercase"},[_vm._v(_vm._s(" " + _vm.title))])])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"orange darken-3 white--text font-weight-bold"},[_vm._v(" New "+_vm._s(_vm.title)+" ")]),_c('v-card-text',[_c('v-row',{staticClass:"mt-3",attrs:{"id":"scroll-dialogs"}},_vm._l((_vm.errors),function(error,index){return _c('v-alert',{key:index,attrs:{"dismissible":"","dense":"","color":"red darken-1","dark":"","type":"error"}},[_vm._v(" "+_vm._s(error)+" ")])}),1),_c('v-form',{ref:"form",attrs:{"action":"POST"},on:{"submit":function($event){$event.preventDefault();return _vm.AddAttachment.apply(null, arguments)}},model:{value:(_vm.form_valid),callback:function ($$v) {_vm.form_valid=$$v},expression:"form_valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"label":"Upload Attachement","truncate-length":"15","rules":[
                function (value) { return !value ||
                  value.size < 2147483648 ||
                  'File size should be less than 2 GB!'; } ]},model:{value:(_vm.form.file),callback:function ($$v) {_vm.$set(_vm.form, "file", $$v)},expression:"form.file"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"font-weight-bold",attrs:{"dark":"","color":"error"},on:{"click":function($event){return _vm.close()}}},[_vm._v("Cancel")]),_c('v-spacer'),_c('v-btn',{staticClass:"font-weight-bold",attrs:{"disabled":!_vm.form_valid,"dark":"","color":"orange darken-3"},on:{"click":function($event){return _vm.AddAttachment()}}},[_vm._v("Submit")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }