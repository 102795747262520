




























































































































































// import { defineComponent } from "vue";
import UserData from "@/types/UserData";
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";
import { saveAs } from "file-saver";
import errorResponse from "@/services/errorCode";

@Component
export default class ComponentJobLetter extends Vue {
  public dialog = false;
  public ready$ = false;
  errors: string[] = [];
  public users: UserData[] = [];
  public form: { recipentA: string; recipentO: string; recipentN: string } = {
    recipentA: "",
    recipentO: "",
    recipentN: "",
  };

  emptyRules = [(v) => !!v || "Required"];

  public get isEmptyForm(): boolean {
    return (
      !!this.form.recipentA && !!this.form.recipentO && !!this.form.recipentN
    );
  }

  clearForm() {
    this.form = { recipentA: "", recipentO: "", recipentN: "" };
    this.dialog = false;
  }

  closeDelete() {
    this.clearForm();
    this.$emit("rolechange", "Request Canceled");
  }

  add() {
    // console.log(this.form);
    const data: FormData = new FormData();
    data.append("recipient_name", this.form.recipentN);
    data.append("recipient_organization", this.form.recipentO);
    data.append("recipient_address", this.form.recipentA);

    axios
      .post("/job-letter", data, {
        responseType: "blob",
      })
      .then(async (response) => {
        if (response.status !== 200) {
          // error handling
          const error = JSON.parse(await response.data.text());
        } else {
          let filename = response.headers["content-disposition"]
            .split('filename="')[1]
            .split(".")[0]
            .trim();
          let extension = response.headers["content-disposition"]
            .split(".")[1]
            .split(";")[0]
            .trim()
            .replace('"', "");

          // console.log(response.headers);
          // console.log(filename, extension, `${filename}.${extension}`);
          var file = new File([response.data], `${filename}.${extension}`, {
            type: "application/pdf",
          });

          // console.log(file);
          saveAs(file);
          this.clearForm();
          console.log(response.data);
          this.$store.dispatch("Add_Snackbar", {
            timeout: 6000,
            text: "Successful request, check downloads",
            color: "green darken-2",
          });
        }
      })
      .catch(async (error) => {
        const err = JSON.parse(await error.response.data.text());
        this.onError(err.message, "scroll-dialogs");
      });
  }
  private onError(error, scroll: string): void {
    // this.errors = errorResponse(error, this.$store);
    this.$store.dispatch("Add_Snackbar", {
      timeout: 6000,
      text: error,
      color: "red darken-2",
    });
    const myElement: HTMLElement | null = document.getElementById(scroll);
    if (myElement != null) myElement.scrollIntoView();
  }
}
