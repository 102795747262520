


















































































































































// import { defineComponent } from "vue";
import errorResponse from "@/services/errorCode";
import axios from "axios";
import Vue, { PropType } from "vue";
import Component from "vue-class-component";
const Props = Vue.extend({
  props: {
    edit: { type: Boolean, default: false },
    title: { type: String, required: true },
    path: { type: String, required: true },
    id: { type: String, required: false },
    name: { type: String, required: false },
    countryId: { type: Number, required: false },
    countries: { type: Array, required: false, default: () => [] },
    branchId: { type: String, required: false },
    departmentId: { type: String, required: false },
  },
});

@Component
export default class CompanyManageDialog extends Props {
  public dialog = false;
  public ready$ = false;
  public errors: string[] = [];
  hasCountries = false;
  public form: {
    name: string;
    country: string | null;
    valid: boolean;
  } = {
    name: "",
    country: null,
    valid: false,
  };

  emptyRules = [(v) => !!v || "Required"];

  public get isEmptyForm(): boolean {
    return !!this.form.name && !!this.form.country;
  }

  closeDelete() {
    this.form = {
      name: "",
      country: null,
      valid: false,
    };
    this.dialog = false;
    this.$store.dispatch("Add_Snackbar", {
      timeout: 3000,
      text: "Request Canceled",
      color: "orange darken-2",
    });
  }
  editClose() {
    this.dialog = false;
    this.$store.dispatch("Add_Snackbar", {
      timeout: 3000,
      text: "Request Canceled",
      color: "orange darken-2",
    });
  }
  public async add() {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      const data: FormData = new FormData();

      data.append("name", this.form.name);
      if (this.form.country != null)
        data.append("country_id", this.form.country);
      if (this.branchId != null) data.append("branch_id", this.branchId);
      if (this.departmentId != null)
        data.append("department_id", this.departmentId);
      axios
        .post(this.path, data)
        .then((response) => {
          // console.log(response.data);
          this.dialog = false;
          this.$emit("reload");
        })
        .catch((error) => {
          this.onError(error, "scroll-dialogs");
        });
    } else {
      this.$store.dispatch("Add_Snackbar", {
        timeout: 3000,
        text: "Please ensure all fields are filled",
        color: "orange darken-2",
      });
    }
  }
  public async update() {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      const data: FormData = new FormData();
      data.append("_method", "PUT");
      data.append("name", this.form.name);
      if (this.form.country != null)
        data.append("country_id", this.form.country);
      if (this.branchId != null) data.append("branch_id", this.branchId);
      if (this.departmentId != null)
        data.append("department_id", this.departmentId);

      axios
        .post(this.path + "/" + this.id, data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          // console.log(response.data);
          this.dialog = false;
          this.$emit("reload");
        })
        .catch((error) => {
          this.onError(error, "scroll-dialogs");
        });
    } else {
      this.$store.dispatch("Add_Snackbar", {
        timeout: 3000,
        text: "Please ensure all fields are filled",
        color: "orange darken-2",
      });
    }
  }

  onError(error, scroll: string): void {
    this.errors = errorResponse(error, this.$store);
    const myElement: HTMLElement | null = document.getElementById(scroll);
    if (myElement != null) myElement.scrollIntoView();
  }

  mounted() {
    // console.log("MANGE MOUNTED---------");
    this.$store.dispatch("commitBASE");
    this.hasCountries = this.countries.length > 0;
    if (this.edit) {
      if (this.name) {
        this.form.name = this.name;
      }
      if (this.countryId) {
        this.form.country = this.countryId.toString();
      }
    }
    this.ready$ = true;
  }
}
