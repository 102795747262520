





























































// import { defineComponent } from "vue";
import errorResponse from "@/services/errorCode";
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";

// Define the props by using Vue's canonical way.
const AddData = Vue.extend({
  props: {
    xData: Object,
  },
});

@Component
export default class ComponentImportTool extends AddData {
  dialog = false;
  public errors: string[] = [];
  form: { file: File | undefined } = { file: undefined };

  public get userID$(): string {
    return this.$store.getters["getUserID"];
  }

  close(text: string, color: string, timeout: number) {
    this.form.file = undefined;
    this.dialog = false;
    this.$store.dispatch("Add_Snackbar", {
      timeout: timeout,
      text: text,
      color: color + " darken-2",
    });
  }

  submit() {
    const file = this.form.file;
    // console.log(file, this.userID$);

    if (file != undefined) {
      // console.log(file, this.userID$);
      if (file.type == "text/csv") {
        console.log("submit");
        const data = new FormData();
        data.append("file", file);
        data.append("collection_name", "import");
        axios
          .post(`/users/${this.userID$}/media`, data)
          .then((response) => {
            // console.log(response.data);
            this.close(response.data.message, "green", 3000);
          })
          .catch((error) => {
            this.onError(error, "scroll-dialogs");
          });
      }
      // this.close();
    } else {
      console.log("Empty", file);
      this.$store.dispatch("Add_Snackbar", {
        timeout: 6000,
        text: "File is required",
        color: "red darken-2",
      });
    }
  }
  private onError(error, scroll: string): void {
    this.errors = errorResponse(error, this.$store);
    const myElement: HTMLElement | null = document.getElementById(scroll);
    if (myElement != null) myElement.scrollIntoView();
  }
}
