









































































































































































































// import { defineComponent } from "vue";
import UserData from "@/types/UserData";
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";

const Data = Vue.extend({
  props: {
    leave: Object,
  },
});

import ComponentRangedDates from "@/components/reusable/DateRangePicker.vue";
import errorResponse from "@/services/errorCode";
@Component({ components: { RangedDates: ComponentRangedDates } })
export default class ComponentEditLeave extends Data {
  public dialog = false;
  public ready$ = false;
  public users: UserData[] = [];
  public errors: string[] = [];
  public form: {
    title: string;
    desc: string;
  } = {
    desc: "",
    title: "",
  };
  public loadLeaves = true;
  public leaves = [{ start_date: "", end_date: "" }];
  counter = 1;
  public emptyRules = (v) => !!v || "Required";

  public min6 = (value) =>
    value.length >= 6 ? true : "Password should have more than 6 characters.";

  public get isEmptyForm(): boolean {
    return !!this.form.title && !!this.form.desc;
  }

  clearForm() {
    this.dialog = false;
  }

  closeDelete() {
    this.clearForm();
    this.$emit("addrequest", { reload: false, text: "Request Canceled" });
  }

  addLeaveDate(dates: { start_date: string; end_date: string }, index: number) {
    // console.log("add", dates, index);
    this.leaves[index] = dates;
    console.log(this.leaves);
    this.counter = this.leaves.length;
  }
  addRange() {
    // console.log(this.counter);
    // console.log(Object.keys(this.leaves[this.counter - 1]).length);
    if (this.counter <= 0) {
      this.leaves.push({ start_date: "", end_date: "" });
    } else {
      if (
        Object.keys(this.leaves[this.counter - 1]).length !== 0 &&
        this.checkRangeEmpty(this.leaves[this.leaves.length - 1])
      )
        this.leaves.push({ start_date: "", end_date: "" });
      else {
        this.$emit("addrequest", {
          reload: false,
          text: "Add dates to input above",
        });
      }
    }
    this.counter = this.leaves.length;
  }
  checkRangeEmpty(Leave: { start_date: string; end_date: string }): boolean {
    console.log(Leave, Leave.start_date.length > 0);
    return Leave.start_date.length > 0;
  }

  deleteRange(index) {
    this.loadLeaves = false;
    // console.log(index);
    if (index <= 0) {
      this.leaves.splice(0, 1);
      // console.log(this.leaves);
      this.counter = this.leaves.length;
      this.addRange();
      this.loadLeaves = true;
    } else {
      this.leaves.splice(index, 1);
      // console.log(this.leaves);
      this.counter = this.leaves.length;
      this.loadLeaves = true;
    }
  }
  update() {
    if (this.leaves[0].start_date === "") {
      this.$emit("addrequest", {
        reload: false,
        text: "Dates cannot be empty",
      });
    } else {
      // const data: FormData = new FormData();
      var data = {
        title: this.form.title,
        description: this.form.desc,
        leave_type_id: this.leave.attributes.leave_type.id,
        leaves: this.leaves,
      };
      console.log(data);

      // data.append("title", this.form.title);
      // data.append("description", this.form.desc);
      // data.append("leave_type_id", this.leave.attributes.leave_type.id);
      // console.log(JSON.stringify(this.leaves));
      // if (Object.keys(this.leaves[this.counter - 1]).length === 0)
      //   this.deleteRange(this.counter - 1);
      // console.log(JSON.stringify(this.leaves));
      // data.append("leaves", JSON.stringify(this.leaves));
      axios
        .put(`/leave-requests/${this.leave.id}`, data)
        .then((response) => {
          console.log(response);
          this.$emit("addrequest", {
            reload: response.data.success,
            text: response.data.message,
          });
          this.$emit("reload");
        })
        .catch((error) => {
          this.onError(error, "scroll-dialogs");
        });
    }
  }

  private onError(error, scroll: string): void {
    this.errors = errorResponse(error, this.$store);
    const myElement: HTMLElement | null = document.getElementById(scroll);
    if (myElement != null) myElement.scrollIntoView();
  }

  mounted() {
    console.log(this.leave);

    this.form.title = this.leave.attributes.title;
    this.form.desc = this.leave.attributes.description;
    this.leaves = this.leave.attributes.leaves;
  }
}
