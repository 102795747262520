






























































































































// import { defineComponent } from "@vue/runtime-core";
import Vue from "vue";
import Component from "vue-class-component";
// import { mapActions } from "vuex";
// import type UserData from "../../types/UserData";
// import store from "@/store";
import ComponentForgotPassword from "@/components/body/login/dialog-forgot-password.vue";
import ComponentUserRegistration from "@/components/body/login/dialog-user-registration.vue";
import ComponentLoginWithGoogle from "@/components/body/login/login-with-google-button.vue";
import ComponentTenant from "@/components/body/login/dialog-select-tenant.vue";
import AuthenticationService from "@/services/AuthenticationService";
import TenantData from "../../types/TenantData";
@Component({
  components: {
    DialogForgotPassword: ComponentForgotPassword,
    DialogRegistration: ComponentUserRegistration,
    LoginWithGoogle: ComponentLoginWithGoogle,
    DialogSelectCompany: ComponentTenant,
  },
})
export default class LoginView extends Vue {
  // private LogIn = mapActions(["LogIn"]);

  loading$ = false;
  public subdomain = "";
  public form: {
    email_id: string;
    password: string;
  } = { email_id: "", password: "" };
  public showPassword = false;
  tenants: [] = [];
  vuexKeyData = "";
  showTenants = false;
  selectedTenant?: TenantData | null = null;
  public dialogLoading = false;

  getVueState() {
    const localStorageData = localStorage.getItem("vuex");
    if (localStorageData) {
      this.vuexKeyData = JSON.parse(localStorageData);
    }
  }
  // private companyName = "";
  // private userData = "";

  snackbar: { show: boolean; timeout: number; text: string } = {
    show: false,
    timeout: 2000,
    text: "",
  };

  showSnackbar(data: { show: boolean; timeout: number; text: string }) {
    this.snackbar = data;
  }

  // Declared as computed property getter
  get loginError(): boolean {
    return false;
  }
  public get buildVersion$(): boolean {
    return this.$store.getters["buildVersion"];
  }

  get isLoggedIn$(): boolean {
    return this.$store.getters["isAuthenticated"];
  }

  get LoginState$(): boolean {
    return this.$store.getters["getLoginState"];
  }
  get LoginErrors$(): [] {
    return this.$store.getters["getLoginErrors"];
  }
  get showRegistration$(): boolean {
    return this.$store.getters["getShowRegistration"];
  }
  get companySubdomain() {
    return this.$store.state.company.subdomain;
  }
  // get showTenants(): boolean {
  //   return this.$store.getters["getShowCompany"];
  // }
  get companyName$() {
    return this.$store.getters["getCompany"];
  }
  get companyCountry$() {
    return this.$store.getters["getCompanyCountry"];
  }
  regclose(response) {
    console.log("Close Reg", response);

    this.$store.dispatch("registrationComplete", {
      user: "",
      subdomain: this.subdomain,
      close: false,
    });

    // other code
  }

  toggleShow() {
    this.showPassword = !this.showPassword;
  }

  AutoLogin() {
    const user: string | null = localStorage.getItem("user");

    if (!user) return;

    const userInfo: {
      success: boolean;
      token: string;
      user_id: string;
    } = JSON.parse(user);

    this.$store.dispatch("AutoLogin", {
      user: userInfo,
      subdomain: this.subdomain,
    });
  }
  async GetTenants() {
    this.dialogLoading = true;
    const data = new FormData();
    data.append("email", this.form.email_id);
    data.append("password", this.form.password);
    this.showTenants = true;
    await AuthenticationService.centralLogin(this.form.email_id).then(
      (response) => {
        console.log("Central Login", response);
        if (response.data.success) {
          this.tenants = response.data.data;
          // this.$store.dispatch("Logins", { data });
          this.dialogLoading = false;
        } else {
          this.dialogLoading = false;
          // this.$store.dispatch("Logins", { data });
        }
      }
    );

    //STILL DO THE BELOW AFTER CALLING CENTRAL
    // this.$store.dispatch("Logins", { data });
    // this.loading$ = false;
  }

  handleLoginClick() {
    const data = new FormData();
    data.append("email", this.form.email_id);
    data.append("password", this.form.password);
    // console.log(response);
    // this.$store.commit("setBASE", {
    //   subdomain: this.selectedTenant?.attributes.domain,
    //   id: this.selectedTenant?.id,
    //   company: this.selectedTenant?.attributes,
    // });
    // this.$store.commit("setCompany", this.selectedTenant?.attributes.company);
    // this.$store.commit(
    //   "setCompanyCountry",
    //   this.selectedTenant?.attributes.country
    // );
    // this.$store.commit("commitBASE");
    this.$store.dispatch("HandleLogin", data);

    this.$store.dispatch("LoginUse", { data });
  }
  closeCompanyDialog(val: boolean) {
    this.showTenants = !val;
  }

  selectTenantCallback(tenant: TenantData) {
    console.log(tenant);
    this.selectedTenant = tenant;
    this.handleLoginClick();
  }

  mounted() {
    this.getVueState();
    const sub = localStorage.getItem("subdomain")?.toString();
    this.subdomain = !sub ? "" : sub;
    // console.log(this.showRegistration$);
    this.$store.dispatch("build");
    this.AutoLogin();
  }
}
