







































































































// import { defineComponent } from "vue";
import errorResponse from "@/services/errorCode";
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";

const Props = Vue.extend({
  props: {
    user: Object,
  },
});

@Component
export default class ComponentChangeRole extends Props {
  public dialog = false;
  public ready$ = false;
  public roles = [];
  public selectrole = "";
  public errors: string[] = [];
  closeDelete() {
    this.dialog = false;
    this.$emit("rolechange", "Request Canceled");
  }
  update() {
    console.log(this.user.id, this.selectrole);
    const data: FormData = new FormData();
    data.append("role", this.selectrole);
    data.append("id", this.user.id);
    axios
      .post("/assign-role", data)
      .then((response) => {
        this.dialog = false;
        // console.log(response.data);
        this.$emit("rolechange", response.data.message);
      })
      .catch((error) => {
        this.onError(error, "scroll-dialogs");
      });
  }
  loadRoles() {
    this.ready$ = false;
    axios
      .get("/roles")
      .then((response) => {
        // console.log(response);
        this.roles = response.data.data;
        this.ready$ = true;
      })
      .catch((error) => {
        console.log(error.response);
        this.dialog = false;
      });
  }

  private onError(error, scroll: string): void {
    this.errors = errorResponse(error, this.$store);
    const myElement: HTMLElement | null = document.getElementById(scroll);
    if (myElement != null) myElement.scrollIntoView();
  }

  mounted() {
    this.loadRoles();
  }
}
