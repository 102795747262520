import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "./modules/auth";
import user from "./modules/user";
// import dashboard from "./modules/dashboard";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    authentication: auth,
    user: user,
    // dashboard: dashboard,
  },
  plugins: [createPersistedState()],
});
