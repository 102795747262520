var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([(!_vm.autoload)?{key:"activator",fn:function(ref){
var on_dialog = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on_tool = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn btn-md",attrs:{"text":true,"block":true,"dark":!true,"left":""}},'v-btn',{ attrs: attrs },false),Object.assign({}, on_tool, on_dialog)),[_c('fa-icon',{staticClass:"dropdown-icon",attrs:{"icon":"file"}}),_vm._v(" View Merit ")],1)]}}],null,true)},[_c('span',[_vm._v("View user merit")])])]}}:null],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 text-white orange accent-4 mb-3",staticStyle:{"word-break":"break-word"}},[_vm._v("View Merit "+_vm._s(_vm.meritName))]),(_vm.loading)?_c('div',{staticStyle:{"display":"grid","justify-content":"center"}},[_c('v-row',[_c('v-progress-circular',{attrs:{"indeterminate":"","size":120,"width":10,"color":"orange"}})],1)],1):_c('v-card-text',[_c('v-row',{staticClass:"mt-3",attrs:{"id":"scroll-dialogs"}},_vm._l((_vm.errors),function(error,index){return _c('v-alert',{key:index,attrs:{"dismissible":"","dense":"","color":"red darken-1","dark":"","type":"error"}},[_vm._v(" "+_vm._s(error)+" ")])}),1),_c('div',{staticClass:"d-flex justify-content-center mt-5"},[_c('v-tooltip',{attrs:{"bottom":"","color":"warning"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('fa-icon',_vm._g(_vm._b({staticClass:"star mb-5",attrs:{"icon":"star","color":'#' + _vm.meritColor}},'fa-icon',attrs,false),on))]}}])},[_c('span',[_vm._v(_vm._s(_vm.meritName))])])],1),_c('v-container',{staticClass:"text-center"},[_c('h5',[_vm._v("Awarded To")]),_c('h4',[_c('strong',[_vm._v(_vm._s(_vm.meritUser))])]),_c('h5',{staticClass:"list-group-item-heading mb-1"},[_vm._v(" Awarded By: "+_vm._s(_vm.meritAwardedBy)+" ")]),_c('h5',{staticClass:"list-group-item-heading mb-1"},[_vm._v(" Awarded On: "+_vm._s(_vm.meritUpdatedAt)+" ")]),_c('h5',{staticClass:"list-group-item-heading mb-1 mt-5"},[_vm._v("Description")]),_c('h5',[_c('strong',[_vm._v(_vm._s(_vm.meritDescription))])])])],1),_c('v-card-actions',[_c('v-btn',{staticClass:"font-weight-medium",attrs:{"dark":"","color":"error"},on:{"click":function($event){return _vm.closeDelete()}}},[_vm._v("Cancel")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }