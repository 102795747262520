import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "../store";
//Views
import DashboardView from "../views/Dashboard/DashboardView.vue";
import UserView from "../views/Users/UserView.vue";
import UserEdit from "../views/Users/UserEdit.vue";
import LoginView from "../views/Login/LoginView.vue";
import ProfileView from "../views/Profile/ProfileView.vue";
import LeaveView from "../views/Leave/LeaveView.vue";
import MeritsView from "../views/Merits/MeritsView.vue";
import SingleMerit from "../views/Merits/MeritsSingle.vue";
import CompanyView from "../views/Company/CompanyView.vue";
import CompanyStructure from "../views/Company/CompanyStructure.vue";
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Dashboard",
    component: DashboardView,
    meta: { title: "Qbicle - Dashboard", requiresAuth: true },
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
    meta: { title: "Qbicle - Login", guest: true },
  },
  {
    path: "/users",
    name: "User",
    component: UserView,
    meta: { title: "Qbicle - Users", requiresAuth: true },
  },
  {
    path: "/users/edit/:id",
    name: "UserEdit",
    component: UserEdit,
    meta: { title: "Qbicle - User/Edit", requiresAuth: true },
  },
  {
    path: "/users/more/:id",
    name: "UserProfileMore",
    component: UserEdit,
    meta: { title: "Qbicle - User/Full Profile", requiresAuth: true },
  },
  {
    path: "/leave-requests",
    name: "Leave Requests",
    component: LeaveView,
    meta: { title: "Qbicle - Leave", requiresAuth: true },
  },
  {
    path: "/leave-requests/:id",
    name: "SingleLeaveRequest",
    component: LeaveView,
    meta: { title: "Qbicle - Merit", requiresAuth: true },
  },
  {
    path: "/profile",
    name: "Profile",
    component: ProfileView,
    meta: { title: "Qbicle - Profile", requiresAuth: true },
  },
  {
    path: "/company",
    name: "Company",
    component: CompanyView,
    meta: { title: "Qbicle - Company", requiresAuth: true },
  },
  {
    path: "/company-structure",
    name: "Company Structure",
    component: CompanyStructure,
    meta: { title: "Qbicle - Company Structure", requiresAuth: true },
  },
  {
    path: "/merits",
    name: "Merit",
    component: MeritsView,
    meta: { title: "Qbicle - Merits", requiresAuth: true },
  },
  {
    path: "/merits/:id",
    name: "SingleMerit",
    component: MeritsView,
    meta: { title: "Qbicle - Merit", requiresAuth: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
const DEFAULT_TITLE = "Qbicle";
router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta?.title || DEFAULT_TITLE;
  });
});
router.beforeEach((to, from, next) => {
  // console.log("Router Before", to);

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // console.log(store.getters.isAuthenticated);

    if (store.getters.isAuthenticated) {
      next();
      return;
    }

    next("/login");
  } else {
    next();
  }
});

// router.beforeEach((to, from, next) => {
//   if (to.matched.some((record) => record.meta.guest)) {
//     if (store.getters.isAuthenticated) {
//       next("/posts");
//       return;
//     }
//     next();
//   } else {
//     next();
//   }
// });

export default router;
